import { ExclamationCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal,
  notification,
  Progress,
  Select,
  Spin,
  Statistic,
} from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useStepsForm } from "sunflower-antd";
import { Percent } from "../../context/PercentProvider";
import {
  deleteEvent,
  deleteEventRecord,
  getBrandPublishedEvents,
  getBrandRecordedEvents,
  getEvents,
  publishEvent,
  getShowRecordedEvents,
  getShowPublishedEvents,
  publishShow,
  deleteShow,
} from "../../redux/actions/event.action";

import {
  faAngleLeft,
  faAngleRight,
  faCalendarDays,
  faClock,
  faFlask,
  faPlay,
  faShareNodes,
  faTrash,
  faUser,
  faUserTie,
  faVideo,
  faXmark,
  faCartFlatbed,
  faEye,
  faThumbsUp
} from "@fortawesome/free-solid-svg-icons";
import { FacebookOutlined } from "@mui/icons-material";
import moment from "moment";
import ReactPaginate from "react-paginate";
import defaultImage from "../../images/user.png";
import { directPublish, fbPublish } from "../../redux/actions/schedulePost";
import { ShareUrl } from "../../redux/actions/shareUrl.action";
import LoadingSpin from "../../reUsable/loadingSpin";
import ViewData from '../../components/saleDetailView/view'
import { searchShowID } from "../../redux/actions/eventID.action";
import numeral from "numeral";
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { BASEURL, visitorURL } from "../../config";
import { getFeeStructure } from "../../redux/actions/feestructure.action";
const { Option } = Select;

const { Countdown } = Statistic;

const { confirm } = Modal;
const isoStr = new Date().toISOString();
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const dateFormat = "MM-DD-YYYY";
const socketUrl = process.env.REACT_APP_SOCKET_URL
export default ({ className, title, eventStatus, roleKey }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenSocial, setIsModalOpenSocial] = useState(false);
  const [sortBy, setSortBy] = useState("date");
  const [caption, setCaption] = useState("");
  const [mediaurl, setMediaurl] = useState("");
  const [eventNum, setEventNum] = useState("");
  const [socialName, setSocialName] = useState("");
  const [loading, setLoading] = useState(false);
  const [kBSLYlinkFB, setKBSLYlinkFB] = useState("");
  const [kBSLYlinkIG, setKBSLYlinkIG] = useState("");
  // const fromDate = moment().startOf('month').format('YYYY-MM-DD');
  // const toDate = moment(new Date()).format('YYYY-MM-DD');
  // const toDate = moment(new Date()).format("YYYY-MM-DD");
  const toDate = moment().endOf("year").format("YYYY-MM-DD");
  const fromDate = moment().subtract(1, "year").startOf("year").format("YYYY-MM-DD");
  const [endDate, setEndDate] = useState(toDate);
  const [startDate, setStartDate] = useState(fromDate);
  const [parent, setParent] = useState("");
  const [shareLoad, setShareLoad] = useState(false);
  const [order, setOrder] = useState("");
  const [showChild, setshowChild] = useState(false);
  const [showID, setShowID] = useState();
  const [showIDLoad, setShowIDLoad] = useState("");
  const [searchShowIDs, setSearchShowIDs] = useState([]);
  const [formStore] = Form.useForm();

  const dateRangePickerChanger = (value, dataString) => {
    const startDate = dataString[0];
    const endDate = dataString[1];
    // console.log(startDate, 'startDate');
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const didUnmount = useRef(false);
  const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(socketUrl, {
    shouldReconnect: (closeEvent) => {
      return didUnmount.current === false;
    },
    reconnectAttempts: 2,
    reconnectInterval: 3000,
  });
  useEffect(() => {
    return () => {
      didUnmount.current = true;
    };
  }, []);

  useEffect(() => {
    if (lastMessage && lastMessage.data) {
      let newdata = JSON.parse(lastMessage.data)
      if (newdata.type === "eventmessage") {
        if (newdata.kind === "show") {
          setLoading(true)
          dispatch(
            getShowRecordedEvents(
              limit,
              1,
              title,
              sortBy,
              startDate,
              endDate,
              showID
            )
          ).then((res) => {
            setLoading(false);
          });
        }
      }
    }
  }, [lastMessage]);

  const {
    form,
    current,
    gotoStep,
    stepsProps,
    formProps,
    submit,
    formLoading,
  } = useStepsForm({});

  const showModal = () => {
    setIsModalOpen(true);
  };
  const showModalSocial = () => {
    setIsModalOpenSocial(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setIsModalOpenSocial(false);
  };

  const handleCancel = () => {
    setSocialName("");
    setIsModalOpen(false);
    setIsModalOpenSocial(false);
  };

  const onFinish = () => {
  };

  const dispatch = useDispatch();
  const history = useNavigate();
  const percent = useContext(Percent)
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  useEffect(() => {
  }, [percent])

  const {
    getEvent,
    shareUrls,
    userInfo,
    validate,
    getShowRecordEvents,
    reactS3BucketData,
    uploadDetail,
    getStructureFees
  } = useSelector((state) => {
    return state;
  });

  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const limit = 8;
  const firstRender = useRef(true);
  // useEffect(() => {
  //   console.log('title changed');
  //   setShowID(null);
  //   setSortBy("date");
  //   console.log('title changed', showID);
  // }, [title])
  useEffect(() => {
    console.log('api hit');
    if (title) {

    }
    setStartDate(moment().subtract(1, "year").startOf("year").format("YYYY-MM-DD"))
    setEndDate(moment().format("YYYY-MM-DD"))
    dispatch(getFeeStructure()).then((res) => {
    })

    setShowIDLoad(true);
    dispatch(searchShowID("", title?.toLowerCase(), title == "Recorded" ? moment().subtract(1, "year").startOf("year").format("YYYY-MM-DD") : "", title == "Recorded" ? moment().format("YYYY-MM-DD") : "")).then((res) => {
      setShowIDLoad(false)
      if (res?.success) {
        setSearchShowIDs(res?.message)
        setShowID(null);
      }
    })
    setCurrentPage(0);
    if (
      validate?.payload?.message?.account_type === "brand" &&
      title === "Recorded"
    ) {
      dispatch(
        getShowRecordedEvents(
          limit,
          1,
          title,
          "date",
          moment().subtract(1, "year").startOf("year").format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
          ""
        )
      ).then((res) => {
        firstRender.current = false;
        setLoading(false);
      });
    } else {
      dispatch(getShowRecordedEvents(limit, 1, title, "date", moment().subtract(1, "year").startOf("year").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"), "")).then(
        (res) => {
          setLoading(false);
        }
      );
    }

    setShowID(null);
    setSortBy("date");
  }, [title, eventStatus]);


  useEffect(() => {
    setShowID(null)
    setCurrentPage(0);
    if (
      validate?.payload?.message?.account_type === "brand" &&
      title === "Recorded" &&
      !firstRender.current
    ) {
      setShowIDLoad(true)
      dispatch(searchShowID("", title?.toLowerCase(), startDate, endDate)).then((res) => {
        setShowIDLoad(false)
        if (res?.success) {
          setSearchShowIDs(res?.message)
        }
      })
      setTimeout(() => {
        dispatch(
          getShowRecordedEvents(
            limit,
            1,
            title,
            sortBy,
            startDate,
            endDate,
            showID,
            true
          )
        ).then((res) => {
          setLoading(false);
        });
      }, 3000);
    }
  }, [uploadDetail]);

  const controlRoom = (val, play) => {
    if (play) {
      history({
        pathname: `/control-room/${val?.event?._id}/play`,
      });
    } else {
      history({
        pathname: `/control-room/${val?.event?._id}`,
      });
    }
  };

  const copyUrl = (url) => {
    navigator.clipboard.writeText(url);
    notification.success({
      message: "Url Copied!",
      className: "toast-success",
    });
    setIsModalOpen(false);
  };
  const shareKey = (id, title, tog) => {
    if (!(tog === "stop")) {
      setIsModalOpen(true);
      setShareLoad(true);
    }
    let UrlKey = "";
    if (title === "Live Events") {
      UrlKey = "live-event";
    } else if (title === "Recorded" || title === "Published") {
      UrlKey = "published-events";
    } else {
      UrlKey = "upcoming-event";
    }
    // let url = `https://www.vcom.fascomconnect.com/${UrlKey}/${id}/${
    //   validate?.payload?.message?.username ? validate?.payload?.message?.username : validate?.payload?.message?.pixel_id
    // }`;
    let url = `${visitorURL}/${UrlKey}/${id}?page=1&origin=marketplace/recorded&brand=&department=null&category=null&username`;
    let data = { url: url };
    dispatch(ShareUrl(data)).then((res) => {
      if (res.payload.success) {
        setKBSLYlinkFB(res?.payload?.message);
        // if (!(tog === "stop")) {
        //   setIsModalOpen(true);
        // }
        setShareLoad(false);
      } else {
        console.log("err");
      }
    });
    let instaURL = `${visitorURL}/${validate?.payload?.message?.username
      ? validate?.payload?.message?.username
      : validate?.payload?.message?.pixel_id
      }`;
    if (tog === "stop") {
      dispatch(ShareUrl({ url: instaURL })).then((res) => {
        if (res.payload.success) {
          setKBSLYlinkIG(res?.payload?.message);
        }
      });
    }
  };

  const recordedEvents = (id, pageName) => {
    history(`/recorded-shows/${id}`, {
      state: {
        pageName,
      },
    });
  };

  const manageInventory = (id) => {
    history(`/inventory/manage/${id}`, {
      state: {
        inventory: "shows",
        title
      },
    });
  };

  const handleButtonClick = (e, item) => {
    if (e.key === "cancel" || e === "cancel") {
      confirm({
        wrapClassName: "modal-generic cancel-event-modal min-modal-600",
        title: "Warning",
        centered: true,
        closable: true,
        icon: <ExclamationCircleOutlined />,
        closeIcon: <FontAwesomeIcon icon={faXmark} />,
        content: "Are you sure you want to delete this event ?",
        okText: " Yes, delete",
        okType: "danger",
        cancelText: " No, I don't want to delete ",

        onOk() {
          return new Promise((resolve, reject) => {
            if (title === "Recorded" || title === "Published") {
              dispatch(deleteShow(item?._id)).then((res) => {
                if (res.payload.success) {
                  notification.success({
                    message: res.payload?.message,
                    className: "toast-success",
                  });

                  if (
                    validate?.payload?.message?.account_type === "brand" &&
                    title === "Recorded"
                  ) {
                    if (
                      getShowRecordEvents?.payload?.message?.data?.length === 1
                    ) {
                      dispatch(
                        getShowRecordedEvents(
                          limit,
                          currentPage,
                          title,
                          sortBy,
                          startDate,
                          endDate,
                          parent
                        )
                      );
                      setCurrentPage(currentPage - 1);
                      setTimeout(resolve, 100);
                    } else {
                      dispatch(
                        getShowRecordedEvents(
                          limit,
                          currentPage + 1,
                          title,
                          sortBy,
                          startDate,
                          endDate,
                          parent
                        )
                      );
                      setTimeout(resolve, 100);
                    }
                  } else if (
                    validate?.payload?.message?.account_type === "brand" &&
                    title === "Published"
                  ) {
                    if (
                      getShowRecordEvents?.payload?.message?.data?.length === 1
                    ) {
                      dispatch(
                        getShowRecordedEvents(
                          limit,
                          currentPage,
                          title,
                          sortBy,
                          startDate,
                          endDate,
                          parent
                        )
                      );
                      setCurrentPage(currentPage - 1);
                      setTimeout(resolve, 100);
                    } else {
                      dispatch(
                        getShowRecordedEvents(
                          limit,
                          currentPage + 1,
                          title,
                          sortBy,
                          startDate,
                          endDate,
                          parent
                        )
                      );
                      setTimeout(resolve, 100);
                    }
                  } else {
                    if (getEvent?.payload?.message?.data?.length === 1) {
                      dispatch(getEvents(limit, currentPage, title, sortBy));

                      setCurrentPage(currentPage - 1);
                      setTimeout(resolve, 100);
                    } else {
                      dispatch(
                        getEvents(limit, currentPage + 1, title, sortBy)
                      );
                      setTimeout(resolve, 100);
                    }
                  }
                } else {
                  notification.error({
                    message: res.payload.data.message,
                    className: "toast-error",
                  });
                }
              });
            }
          });
        },

        onCancel() {
          console.log("Cancel");
        },
      });
    }
    if (e.key === "edit") {
      history({
        pathname: `/schedule-show-id/${validate?.payload?.message?.account_type === "brand" &&
          (title === "Recorded" || title === "Published")
          ? item?._id + "?page=null"
          : item?.event?._id + "?page=null"
          }`,
      });
    }
    if (e.key === "editdetail") {
      history({
        pathname: `/schedule-show-id/${validate?.payload?.message?.account_type === "brand" &&
          (title === "Recorded" || title === "Published")
          ? item?._id + "?page=edit"
          : item?.event?._id + "?page=edit"
          }`,
      });
    }
    if (e.key === "editproduct") {
      history({
        pathname: `/schedule-show-id/${validate?.payload?.message?.account_type === "brand" &&
          (title === "Recorded" || title === "Published")
          ? item?._id + "?page=product"
          : item?.event?._id + "?page=product"
          }`,
      });
    }
    if (e.key === "publish") {
      if (
        validate?.payload?.message?.account_type === "brand" &&
          (title === "Recorded" || title === "Published")
          ? item?.is_published
          : item?.event?.is_published
      ) {
        confirm({
          wrapClassName: "modal-generic cancel-event-modal min-modal-600",
          // title: 'Warning',
          centered: true,
          closable: true,
          icon: <ExclamationCircleOutlined />,
          closeIcon: <FontAwesomeIcon icon={faXmark} />,
          content: "Are you sure you want to unpublish this event ?",
          okText: " Yes, Unpublish",
          okType: "success",
          cancelText: " No, I don't want to unpublish",

          onOk() {
            return new Promise((resolve, reject) => {
              dispatch(
                publishShow(
                  validate?.payload?.message?.account_type === "brand" &&
                    (title === "Recorded" || title === "Published")
                    ? item?._id
                    : item?.event?._id,
                  "unpublish"
                )
              ).then((res) => {
                if (res.payload.success) {
                  notification.success({
                    message: res.payload?.message,
                    className: "toast-success",
                  });
                  if (
                    validate?.payload?.message?.account_type === "brand" &&
                    title === "Recorded"
                  ) {
                    dispatch(
                      getShowRecordedEvents(
                        limit,
                        currentPage + 1,
                        title,
                        sortBy,
                        startDate,
                        endDate,
                        parent
                      )
                    );
                  } else if (
                    validate?.payload?.message?.account_type === "brand" &&
                    title === "Published"
                  ) {
                    dispatch(
                      getShowRecordedEvents(
                        limit,
                        currentPage + 1,
                        title,
                        sortBy,
                        startDate,
                        endDate,
                        parent
                      )
                    );
                  } else {
                    dispatch(getEvents(limit, currentPage + 1, title, sortBy));
                  }
                  setTimeout(resolve, 100);
                } else {
                  notification.error({
                    message: res.payload.data.message,
                    className: "toast-error",
                  });
                }
              });
            });
          },

          onCancel() {
            console.log("cancel UnPublish");
          },
        });
      } else {
        if (item.products.length === 0) {
          Modal.info({
            centered: true,
            wrapClassName: "noproduct-modal",
            title: 'Please add atleast 1 product to publish.',
            onOk() { },
          });
        }
        else {
          confirm({
            wrapClassName: "modal-generic cancel-event-modal min-modal-600",
            // title: 'Warning',
            centered: true,
            closable: true,
            icon: <ExclamationCircleOutlined />,
            closeIcon: <FontAwesomeIcon icon={faXmark} />,
            content: "Are you sure you want to publish this event ?",
            okText: " Yes, publish",
            okType: "success",
            cancelText: " No, I don't want to publish",

            onOk() {
              return new Promise((resolve, reject) => {
                dispatch(
                  publishShow(
                    validate?.payload?.message?.account_type === "brand" &&
                      title === ("Recorded" || "Published")
                      ? item?._id
                      : item?.event?._id,
                    "publish"
                  )
                ).then((res) => {
                  if (res.payload.success) {
                    notification.success({
                      message: res.payload?.message,
                      className: "toast-success",
                    });
                    if (
                      validate?.payload?.message?.account_type === "brand" &&
                      title === "Recorded"
                    ) {
                      dispatch(
                        getShowRecordedEvents(
                          limit,
                          currentPage + 1,
                          title,
                          sortBy,
                          startDate,
                          endDate,
                          parent
                        )
                      );
                    } else if (
                      validate?.payload?.message?.account_type === "brand" &&
                      title === "Published"
                    ) {
                      dispatch(
                        getShowRecordedEvents(
                          limit,
                          currentPage + 1,
                          title,
                          sortBy,
                          startDate,
                          endDate,
                          parent
                        )
                      );
                    } else {
                      dispatch(getEvents(limit, currentPage + 1, title, sortBy));
                    }
                    setTimeout(resolve, 100);
                  } else {
                    notification.error({
                      message: res.payload.data.message,
                      className: "toast-error",
                    });
                  }
                });
              });
            },
            onCancel() {
              console.log("cancel Publish");
            },
          });
        }
      }
    }
    if (e.key === "download") {
      // const a = document.createElement('a')
      // // a.href = item?.recording_url
      // a.href = "https://d1lgl9tsums24s.cloudfront.net/assets/b9e0a529-21ad-41f7-b048-ab3948a7d052/MP4/master.mp4"
      // a.download = "https://d1lgl9tsums24s.cloudfront.net/assets/b9e0a529-21ad-41f7-b048-ab3948a7d052/MP4/master.mp4".split('/').pop()
      // document.body.appendChild(a)
      // a.click()
      // document.body.removeChild(a)
      // window.location.assign("https://d1lgl9tsums24s.cloudfront.net/assets/b9e0a529-21ad-41f7-b048-ab3948a7d052/MP4/master.mp4", '_blank');

      // try {
      //   const videoUrl = "https://d1lgl9tsums24s.cloudfront.net/assets/b9e0a529-21ad-41f7-b048-ab3948a7d052/MP4/master.mp4";
      //   const videoRequest = new Request(videoUrl);
      //   fetch(videoRequest)
      //     .then(() => {
      //       const link = document.createElement('a');
      //       link.href = videoUrl;
      //       link.setAttribute('download', 'waterfall.mp4');
      //       document.body.appendChild(link);
      //       link.click();
      //       document.body.removeChild(link);
      //     });
      // } catch (error) {
      //   console.error(error);
      // }
      const videoUrl = item?.download_url;
      const videoRequest = new Request(videoUrl);
      fetch(videoRequest)
        .then((response) => response.blob())
        .then((blob) => {
          // Create blob link to download
          const url = window.URL.createObjectURL(
            new Blob([blob]),
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            videoUrl.split('/').pop(),
          );

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          // Clean up and remove the link
          link.parentNode.removeChild(link);
        });
    }
  };
  const load =
    validate?.payload?.message?.account_type === "brand" &&
    (title === "Recorded" || title === "Published") &&
    getShowRecordEvents.loading;

  const handlePageClick = (e) => {
    const page = e.selected;
    setCurrentPage(page);
    if (
      validate?.payload?.message?.account_type === "brand" &&
      title === "Recorded"
    ) {
      dispatch(
        getShowRecordedEvents(
          limit,
          page + 1,
          title,
          sortBy,
          moment(startDate).format("YYYY-MM-DD"),
          moment(endDate).format("YYYY-MM-DD"),
          parent
        )
      ).then((res) => {
        if (res.payload.success) {
          // setPageCount(res.payload.message?.next?.page);
          setLoading(false);
        } else {
          console.log("err");
          setLoading(false);
        }
      });
    } else if (
      validate?.payload?.message?.account_type === "brand" &&
      title === "Published"
    ) {
      dispatch(
        getShowRecordedEvents(limit, page + 1, title, sortBy, startDate,
          endDate, parent)
      ).then((res) => {
        if (res.payload.success) {
          // setPageCount(res.payload.message?.next?.page);
          setLoading(false);
        } else {
          console.log("err");
          setLoading(false);
        }
      });
    } else {
      dispatch(getEvents(limit, page + 1, title, sortBy)).then((res) => {
        if (res.payload.success) {
          setLoading(false);
          // setPageCount(res.payload.message?.next?.page);
        } else {
          console.log("err");
          setLoading(false);
        }
      });
    }
  };

  function roomDisable(item) {
    const dateIsAfter = moment(item.start_date).isAfter(
      moment(moment.utc().format())
    );
    if (dateIsAfter) {
      return false;
    } else {
      return true;
    }
  }

  const handleChange = (value) => {
    setSortBy(value);
  };

  const postSocialDetails = () => {
    setLoading(true);
    switch (socialName) {
      case "Instagram":
        return dispatch(
          directPublish({
            media_url:
              getEvent?.payload?.message?.data?.[eventNum]?.event?.banner,
            caption: form
              .getFieldValue()
              .notes.replace(/\r?\n|\r/g, "")
              .trim(),
          })
        )
          .then((res) => {
            if (res?.data?.success) {
              setIsModalOpenSocial(false);
              setLoading(false);
              setSocialName("");
              notification.success({
                message: "Post Has Been Published",
                className: "toast-success",
              });
            } else {
              setLoading(false);
              notification.error({
                message: "An Error Occurred While Publishing",
                className: "toast-error",
              });
            }
          })
          .catch((err) => {
            setLoading(false);
            notification.error({
              message: "An Error Occurred While Publishing",
              className: "toast-error",
            });
          });

      case "Facebook":
        return dispatch(
          fbPublish({
            media_url:
              getEvent?.payload?.message?.data?.[eventNum]?.event?.banner,
            caption: form
              .getFieldValue()
              .notes.replace(/\r?\n|\r/g, "")
              .replace(kBSLYlinkFB, "")
              .trim(),
            link: kBSLYlinkFB,
          })
        )
          .then((res) => {
            if (res?.data?.success) {
              setIsModalOpenSocial(false);
              setLoading(false);
              setSocialName("");
              notification.success({
                message: "Post Has Been Published",
                className: "toast-success",
              });
            } else {
              setLoading(false);
              notification.error({
                message: "An Error Occurred While Publishing",
                className: "toast-error",
              });
            }
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            notification.error({
              message: "An Error Occurred While Publishing",
              className: "toast-error",
            });
          });

      case "Pinterest":
        setLoading(false);
        return console.log("Pinterest");
      case "LinkedIn":
        setLoading(false);
        return console.log("LinkedIn");
      default:
        return null;
    }
  };

  const handleSocial = (e) => {
    setSocialName(e);
    switch (e) {
      case "Instagram":
        form.setFieldsValue({
          // notes: getEvent?.payload?.message?.data?.[eventNum]?.event?.title + `\n \n${kBSLYlinkIG}`,
          notes:
            getEvent?.payload?.message?.data?.[eventNum]?.event?.title +
            "\n \n" +
            `Check My Bio Link! ` +
            `\n${kBSLYlinkIG}`,
        });
        break;
      case "Facebook":
        form.setFieldsValue({
          notes:
            getEvent?.payload?.message?.data?.[eventNum]?.event?.title +
            "\n \n" +
            kBSLYlinkFB,
        });
        break;
      default:
        return null;
    }
  };

  const handleCategory = (e) => {
    setCurrentPage(0);
    setParent(e);
  };

  const trigger = (item) => {
    setOrder(item)
    setshowChild(true)
  }

  const flag = (data) => {
    setshowChild(data);
  };

  const nFormatter = (num) => {
    if (num >= 1000000000) {
      return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
    }
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    if (num < 1000) {
      return numeral(num).format("0.00")
    }
    return num;
  }

  const handleSubmit = (e) => {
    setShowIDLoad(true)
    dispatch(searchShowID("", title?.toLowerCase(), title == "Recorded" ? moment(startDate).format("YYYY-MM-DD") : "", title == "Recorded" ? moment(endDate).format("YYYY-MM-DD") : "")).then((res) => {
      setShowIDLoad(false)
      if (res?.success) {
        setSearchShowIDs(res?.message)
      }
    })
    setLoading(true);
    e.preventDefault();
    setCurrentPage(0);
    if (
      validate?.payload?.message?.account_type === "brand" &&
      title === "Recorded"
    ) {
      dispatch(
        getShowRecordedEvents(
          limit,
          1,
          title,
          sortBy,
          // startDate,
          // endDate,
          moment(startDate).format("YYYY-MM-DD"),
          moment(endDate).format("YYYY-MM-DD"),
          showID
        )
      ).then((res) => {
        setLoading(false);
      });
    } else if (
      validate?.payload?.message?.account_type === "brand" &&
      title === "Published"
    ) {
      dispatch(getShowRecordedEvents(
        limit,
        1,
        title,
        sortBy,
        moment(startDate).format("YYYY-MM-DD"),
        moment(endDate).format("YYYY-MM-DD"),
        showID)).then(
          (res) => {
            setLoading(false);
          }
        );
    } else {
      dispatch(getEvents(limit, 1, title, sortBy, showID)).then((res) => {
        setLoading(false);
      });
    }
  };

  const handleShowID = (e) => {
    setShowIDLoad(true)
    dispatch(searchShowID(e.replaceAll("-", ''), title?.toLowerCase(), title == "Recorded" ? startDate : "", title == "Recorded" ? endDate : "")).then((res) => {
      setShowIDLoad(false)
      if (res?.success) {
        setSearchShowIDs(res?.message)
      }
    })
  }

  const handleReset = () => {
    setShowIDLoad(true)
    setLoading(true);
    setParent("");
    setSortBy("date");
    setShowID(null)
    setCurrentPage(0);
    setStartDate(fromDate);
    setEndDate(toDate);
    dispatch(searchShowID("", title?.toLowerCase(), title == "Recorded" ? fromDate : "", title == "Recorded" ? toDate : "")).then((res) => {
      setShowIDLoad(false)
      if (res?.success) {
        setSearchShowIDs(res?.message)
      }
    })

    if (
      validate?.payload?.message?.account_type === "brand" &&
      title === "Recorded"
    ) {
      dispatch(
        getShowRecordedEvents(limit, 1, title, "date", fromDate, toDate, "")
      ).then((res) => {
        setLoading(false);
      });
    } else if (
      validate?.payload?.message?.account_type === "brand" &&
      title === "Published"
    ) {
      dispatch(getShowRecordedEvents(limit, 1, title, "date", fromDate, toDate, "")).then(
        (res) => {
          setLoading(false);
        }
      );
    }
  };

  return (
    <>
      {(title === "Recorded" || title === "Published") && (
        <>
          <div className="row w-20-desktop-main">
            {validate?.payload?.message?.account_type === "brand" &&
              (title === "Recorded" || title === "Published") && (
                <>
                  {validate?.payload?.message?.account_type === "brand" &&

                    <div className="col-xl-3 col-md-6 col-12 mb-15">
                      <p className="mb-5rem">Select Start Date / End Date</p>
                      <RangePicker
                        size="large"
                        className="w-100"
                        key={4}
                        value={
                          startDate && endDate
                            ? [moment(startDate), moment(endDate)]
                            : []
                        }
                        defaultValue={
                          startDate && endDate
                            ? [moment(startDate), moment(endDate)]
                            : []
                        }
                        allowClear={false}
                        ranges={{
                          "All Time": [
                            moment().subtract(1, "year").startOf("year"),
                            moment(),
                          ],
                          "YTD": [moment().startOf("year"), moment()],
                          "Last 30 Days": [moment().subtract(30, "days"), moment()],
                          "Last 7 Days": [moment().subtract(7, "days"), moment()],
                          "Today": [moment(), moment()],
                          // Today: [moment(), moment()],
                          // Tomorrow: [
                          //   moment().add(1, 'days'),
                          //   moment().add(1, 'days'),
                          // ],
                          // Yesterday: [
                          //   moment().subtract(1, 'days'),
                          //   moment().subtract(1, 'days'),
                          // ],
                          // "Last Year": [
                          //   moment().subtract(1, "year").startOf("year"),
                          //   moment().subtract(1, "year").endOf("year"),
                          // ],
                          // "This Year": [
                          //   moment().startOf("year"),
                          //   moment().endOf("year"),
                          // ],
                          // "Last Month": [
                          //   moment().subtract(1, "month").startOf("month"),
                          //   moment().subtract(1, "month").endOf("month"),
                          // ],
                          // "This Month": [
                          //   moment().startOf("month"),
                          //   moment().endOf("month"),
                          // ],
                        }}
                        format={dateFormat}
                        onChange={dateRangePickerChanger}
                      />
                    </div>
                  }
                </>
              )}
            <div className="col-xl-3 col-md-6 col-12 mb-15">
              <p className="mb-5rem">Show ID</p>
              <Select
                showSearch
                size="large"
                className="w-100"
                key={4}
                notFoundContent={showIDLoad && <Spin size="small" />}
                loading={showIDLoad}
                optionFilterProp="children"
                placeholder="Enter Show ID"
                onChange={(e) => setShowID(e?.[0] + "-" + e?.split('S')[1].split('-').join('').match(/.{1,3}/g).join('-'))}
                optionLabelProp="label"
                onSearch={handleShowID}
                filterOption={(input, options) =>
                  options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                focus={false}
                value={showID}
                onBlur={false}
              >
                {searchShowIDs && !showIDLoad &&
                  searchShowIDs?.map((item, i) => {
                    return (
                      <Option value={item?.show_counter}>
                        {item?.show_counter?.[0] + "-" + item?.show_counter?.split('S')[1].split('-').join('').match(/.{1,3}/g).join('-')}
                      </Option>
                    );
                  })}
              </Select>
            </div>
            <div className="col-xl-3 col-md-6 col-12 mb-15">
              <p className="mb-5rem">Sort By</p>
              <Select
                className="w-100 select-style dark-field"
                placeholder="Sort By"
                size="large"
                focus={false}
                onBlur={false}
                value={sortBy}
                onChange={handleChange}
                defaultValue="date"
              >
                <Option value="date">Date</Option>
                <Option value="sales">
                  Sales
                </Option>
                <Option value="views">
                  Views
                </Option>
                <Option value="likes">
                  Likes
                </Option>
              </Select>
            </div>
            <div className="col-xl-3 col-md-6 col-12 d-flex align-items-end  mb-15">
              <Button
                onClick={handleSubmit}
                // className="default-btn fltr-hpr m-width-100 ml-0 fw-normal"
                className="default-btn fltr-hpr  medium-btn m-width-100 ml-0 fw-normal"
                htmlType="submit"
                loading={loading}
              >
                Search
              </Button>

              <Button
                onClick={handleReset}
                // className="default-btn fltr-hpr m-width-100 ml-2 fw-normal"
                className="default-btn outline fw-normal fltr-hpr medium-btn  res-btn2 min-w-100"
                htmlType="submit"
                loading={loading}
              >
                Reset
              </Button>
            </div>
          </div>
          <hr className="separator-line mt-0" />
        </>
      )}

      {!load ? (
        validate?.payload?.message?.account_type === "brand" && (
          <div
            className={`upcoming-events-wrapper mb-30 ${className ? "grid-block" : "list-block"
              }`}
          >
            {!getShowRecordEvents?.loading ? (
              <>
                {getShowRecordEvents?.payload?.message?.data?.length > 0 ? (
                  <div className="upcoming-events-grid manage-style">
                    {getShowRecordEvents?.payload?.message?.data?.map(
                      (item, ind) => {
                        return (
                          <>
                            <div className="upcoming-events-box">
                              {/* <div className="event-banner" style={{ backgroundImage: `url(${item.banner})` }} > */}
                              <div className="event-banner">
                                <div className="event-box w-100 h-100 overflow-hidden">
                                  <div className="event-banner-img">
                                    <img src={item?.banner} alt="banner" />
                                  </div>

                                  <div className="d-flex justify-content-between align-items-center flex-wrap">
                                    {/* {roomDisable(item) ? (
                                   <div className="schedule-badge expired">
                                     Expired
                                   </div>
                                 ) : (
                                   <> */}
                                    {item?.event_status === "live" ? (
                                      <div className="schedule-badge live">
                                        Live Now
                                      </div>
                                    ) : null}

                                    {moment(item?.start_date).format(
                                      "MM-DD-YYYY hh:mm A"
                                    ) <=
                                      moment(isoStr).format(
                                        "MM-DD-YYYY hh:mm A"
                                      ) &&
                                      item?.event_status === "scheduled" ? (
                                      <div className="schedule-badge past">
                                        Past
                                      </div>
                                    ) : item?.event_status === "scheduled" ? (
                                      <div className="schedule-badge schedule ">
                                        Schedule
                                      </div>
                                    ) : null}
                                    {item?.event_status === "recorded" ? (
                                      <div className="d-flex justify-content-between w-100">
                                        <div className="schedule-badge Recorded ">
                                          Recorded
                                        </div>
                                        {item?.gen_category?.name &&
                                          <div className="schedule-badge schedule mr-2 banner-category ">
                                            {item?.gen_category?.name}
                                          </div>
                                        }
                                      </div>
                                    ) : null}
                                    {/* </>
                                 )} */}

                                    {item?.event_status === "scheduled" ? (
                                      <div className="schedule-badge schedule countdown-timer">
                                        <Countdown
                                          value={item?.start_date}
                                          format="DD : HH : mm : ss"
                                        />
                                      </div>
                                    ) : null}
                                  </div>

                                  <div
                                    className={`d-flex ${item?.is_facebook_live
                                      ? "justify-content-between"
                                      : "justify-content-end"
                                      }`}
                                  >
                                    {item?.is_facebook_live && (
                                      <div className="ribbon-small fb-style mr-2">
                                        <FacebookOutlined className="mr-2 mb-1" />
                                        <span>Live on Facebook</span>
                                      </div>
                                    )}
                                    <div className="ribbon-small">
                                      <FontAwesomeIcon icon={faFlask} />
                                      <span>Event </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="event-card-content w-100 col d-flex flex-column justify-content-between">
                                <div>

                                  <div className="event-date-main top-date">
                                    <div className="fee-box">
                                      <div className="event-date">
                                        <FontAwesomeIcon
                                          className="event-icon"
                                          icon={faEye}
                                        />

                                        <div className="event-txt">
                                          {item?.views ? item?.views : '0'}
                                        </div>

                                        <div className="ml-2 event-date">
                                          <FontAwesomeIcon
                                            className="event-icon"
                                            icon={faThumbsUp}
                                          />

                                          <div className="event-txt">
                                            {item?.likes ? item?.likes : "0"}
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    {item?.trans[0] ?

                                      <div className="sales-amount">
                                        <div className="sales-hd1">
                                          Sales:
                                        </div>

                                        <a
                                          className="open-modal blue-link"
                                          onClick={() => trigger(item)}
                                        >
                                          ${nFormatter(item?.trans[0]?.total_amount)}
                                          {/* {numeral(item?.trans[0]?.total_amount).format("$0,0.0'")} */}
                                        </a>
                                      </div>
                                      :
                                      <>
                                      </>
                                    }
                                  </div>


                                  <div className="event-date-main top-date">
                                    {item?.event_status === "recorded" ? (
                                      <div className="event-date-recorded">
                                        Scheduled:
                                      </div>
                                    ) : null}

                                    <div className="event-date">
                                      {item?.event_status != "recorded" ? (
                                        <FontAwesomeIcon
                                          className="event-icon"
                                          icon={faCalendarDays}
                                        />
                                      ) : null}
                                      <div
                                        // className="event-txt"
                                        className={`event-txt ${item?.event_status != "recorded"
                                          ? ""
                                          : "recorded-gap"
                                          }`}
                                      >
                                        {moment(item?.start_date)
                                          .utc()
                                          .format("MM-DD-YYYY")}
                                      </div>
                                    </div>

                                    <div className="event-date">
                                      {item?.event_status != "recorded" ? (
                                        <FontAwesomeIcon
                                          className="event-icon"
                                          icon={faClock}
                                        />
                                      ) : null}

                                      <div className="event-txt">
                                        {moment(item?.start_date).format(
                                          "hh:mm A"
                                        )}
                                      </div>
                                    </div>


                                  </div>

                                  {/* <h3 className="event-hd">{item?.title}</h3> */}
                                  {
                                    item?.title ? <h3 className="event-hd">{item?.title}</h3> :
                                      <h3 className="event-hd">{item?.file_name} <span style={{ color: 'gray', fontSize: "1rem", fontWeight: "400" }}>( file name )</span></h3>
                                  }
                                </div>

                                {/* <div className="event-users-listing">
                                  <div className="fee-box-main">
                                    {item?.referral_percent === "0" ||
                                      item?.referral_percent === "" ||
                                      item?.referral_percent === "null" ||
                                      item?.referral_percent === "undefined" ||
                                      (!item?.referral_percent ? null : (
                                        <div className={`fee-box fee-height`}>
                                          <div className="fee-state red-state">
                                            {item?.referral_percent +
                                              "%" +
                                              " Referral Fee"}
                                          </div>
                                        </div>
                                      ))}
                                    <div className={`fee-box fee-height`}>
                                      <div className="fee-state red-state">
                                        {getStructureFees?.payload?.message?.fee_structure?.referral_fee + "%" + " Referral Fee"}
                                      </div>
                                    </div>

                                    {item?.influencer_percent === "0" ||
                                      item?.influencer_percent === "" ||
                                      item?.influencer_percent === "null" ||
                                      item?.influencer_percent === "undefined" ||
                                      !item?.influencer_percent ? null : (
                                      <div className="fee-box fee-height">
                                        <div className="fee-state orange-state">
                                          {item?.influencer_percent +
                                            "%" +
                                            " Influencer Fee"}
                                        </div>
                                      </div>
                                    )}
                                    <div className="fee-box fee-height">
                                      <div className="fee-state orange-state">
                                        {getStructureFees?.payload?.message?.fee_structure?.content_creator_fee + "%" + " Creator Fee"}

                                      </div>
                                    </div>
                                    {item?.discount === "0%" ||
                                      item?.discount === "" ||
                                      item?.discount === "undefined" ||
                                      !item?.discount ? null : (
                                      <div className={`fee-box fee-height`}>
                                        <div className="fee-state green-state">
                                          {item?.discount + " Discount"}
                                        </div>
                                      </div>
                                    )}

                                  </div>
                                </div> */}
                                {item?.show_counter && <p className="mt-2 mb-0"><span className="fw-bold">Show ID:</span> {item?.show_counter?.[0] + "-" + item?.show_counter?.split('S')[1].split('-').join('').match(/.{1,3}/g).join('-')} </p>}
                              </div>
                              <div className="event-card-footer">
                                {item?.recording_url &&
                                  item?.event_status != "live" ? (
                                  <>
                                    {
                                      item.error_reason === "Uploading" &&
                                        !item?.is_convert ?
                                        // <div className="showConverting ">
                                        //   <span className="mt-2">Media is Converting</span>
                                        //   <Progress className="convertProgresss" strokeLinecap="butt" strokeColor={"#52c41a"} showInfo={false} percent={100} size="default" status="active" />
                                        // </div>
                                        <Button
                                          loading
                                          className="default-btn d-flex align-items-center justify-content-center"
                                          type="primary"
                                          size="large"
                                          // onClick={() =>
                                          //   recordedEvents(item?._id, title)
                                          // }
                                          icon={
                                            <FontAwesomeIcon
                                              size="sm"
                                              className="event-icon"
                                              // icon={faVideo}
                                              icon={faPlay}
                                            />
                                          }
                                        >
                                          Media Processing
                                        </Button>
                                        :
                                        item.error_reason === "Failed on media conversion" ?
                                          <div
                                            key={item.show_s3_id}
                                            className={`d-flex 
                                        justify-content-between
                                        align-items-center w-100 media-processing`}
                                          >
                                            <div className="process-hd ff-nunito" style={{ color: "#CD1818", }}>File is corrupted</div>
                                          </div> :
                                          <Button
                                            className="default-btn d-flex align-items-center justify-content-center"
                                            type="primary"
                                            size="large"
                                            onClick={() =>
                                              recordedEvents(item?._id, title)
                                            }
                                            icon={
                                              <FontAwesomeIcon
                                                size="sm"
                                                className="event-icon"
                                                // icon={faVideo}
                                                icon={faPlay}
                                              />
                                            }
                                          >
                                            Play
                                          </Button>
                                    }


                                    {roleKey === "inventoryManager" && item?.is_convert && (
                                      <Button
                                        className="default-btn d-flex  align-items-center justify-content-center"
                                        type="primary"
                                        size=""
                                        onClick={() =>
                                          manageInventory(item?._id)
                                        }
                                        icon={
                                          <FontAwesomeIcon
                                            size="sm"
                                            className="event-icon"
                                            icon={faCartFlatbed}
                                          />
                                        }
                                      >
                                        Manage Inventory
                                      </Button>
                                    )}
                                    {roleKey !== "inventoryManager" && item?.is_convert && (
                                      <div>
                                        <Button
                                          className="default-btn share-btn d-flex align-items-center justify-content-center"
                                          type="primary"
                                          size=""
                                          onClick={() =>
                                            shareKey(item?._id, title)
                                          }
                                          icon={
                                            // <ShareAltOutlined />
                                            <FontAwesomeIcon
                                              size="sm"
                                              className="event-icon"
                                              icon={faShareNodes}
                                            />
                                          }
                                        ></Button>
                                      </div>
                                    )}
                                    {item.event_status !== "live" && item?.is_convert &&
                                      roleKey === "admin" && (
                                        <Dropdown.Button
                                          trigger="click"
                                          overlayClassName="control-room-drowpdown"
                                          size="large"
                                          disabled={
                                            item.event_status !== "scheduled" ||
                                              item.event_status !== "recorded"
                                              ? false
                                              : false
                                          }
                                          overlay={

                                            <Menu
                                              onClick={(e) =>
                                                handleButtonClick(e, item)
                                              }
                                            >
                                              <Menu.Item
                                                key="download"
                                                disabled={item?.download_url ? false : true}
                                              >
                                                <span>Download</span>
                                              </Menu.Item>
                                              {!item?.title && <Menu.Item
                                                key="edit"
                                                disabled={
                                                  roomDisable(item) ||
                                                    item.event_status ==
                                                    "scheduled"
                                                    ? false
                                                    : true
                                                }
                                              >
                                                <span>Edit</span>
                                              </Menu.Item>}
                                              {item?.title && <Menu.Item
                                                key="editdetail"
                                              // disabled={
                                              //   roomDisable(item) ||
                                              //     item.event_status ==
                                              //     "scheduled"
                                              //     ? false
                                              //     : true
                                              // }
                                              >
                                                <span>Edit Title</span>
                                              </Menu.Item>}
                                              {item?.title && <Menu.Item
                                                key="editproduct"
                                              // disabled={
                                              //   roomDisable(item) ||
                                              //     item.event_status ==
                                              //     "scheduled"
                                              //     ? false
                                              //     : true
                                              // }
                                              >
                                                <span>Edit Products</span>
                                              </Menu.Item>}
                                              <Menu.Item
                                                key="cancel"
                                                disabled={
                                                  roomDisable(item) ||
                                                    (item.event_status ==
                                                      "live" &&
                                                      item?.recording_url &&
                                                      item.event_status !=
                                                      "scheduled")
                                                    ? false
                                                    : null
                                                }
                                              >
                                                <span>Delete</span>
                                              </Menu.Item>

                                              {item.event_status ===
                                                "recorded" && (
                                                  <Menu.Item
                                                    key="publish"
                                                    // title={item?.title && !item?.is_published && item?.products?.length === 0 ? "Add alteast 1 product" : ""}
                                                    disabled={!item?.title}
                                                  // disabled={!item?.title || !item?.is_published && item?.products?.length === 0}
                                                  // disabled={
                                                  //   item?.event?.is_published
                                                  //     ? true
                                                  //     : false
                                                  // }
                                                  >
                                                    <span>
                                                      {item?.is_published
                                                        ? "UnPublish"
                                                        : "Publish"}
                                                    </span>
                                                  </Menu.Item>
                                                )}
                                            </Menu>
                                          }
                                        ></Dropdown.Button>
                                      )}
                                  </>
                                ) :
                                  <>
                                    <div
                                      key={item.show_s3_id}
                                      className={`d-flex 
                                      justify-content-between
                                      align-items-center w-100 media-processing`}
                                    >
                                      {
                                        (percent[item.show_s3_id] === 0 || percent[item.show_s3_id]) ?
                                          <Progress className="test111" key={item.show_s3_id} percent={percent[item.show_s3_id]} status="active" size="100" strokeLinecap="butt" strokeColor={"#52c41a"} /> :
                                          <>
                                            {
                                              item?.error_reason === "Uploading" ?
                                                // <div className="process-hd ff-nunito">{item?.error_reason + "..."}</div> 
                                                <Button
                                                  loading
                                                  className="default-btn d-flex align-items-center justify-content-center"
                                                  type="primary"
                                                  size="large"
                                                  // onClick={() =>
                                                  //   recordedEvents(item?._id, title)
                                                  // }
                                                  icon={
                                                    <FontAwesomeIcon
                                                      size="sm"
                                                      className="event-icon"
                                                      // icon={faVideo}
                                                      icon={faPlay}
                                                    />
                                                  }
                                                >
                                                  Media Processing
                                                </Button>
                                                :
                                                <div className="process-hd ff-nunito" style={{ color: "#CD1818", }}>{item?.error_reason}</div>
                                            }
                                            {
                                              item?.is_convert &&
                                              <div>
                                                <Button
                                                  className="default-btn share-btn d-flex align-items-center justify-content-center"
                                                  type="primary"
                                                  size="large"
                                                  key="cancel"
                                                  onClick={() =>
                                                    handleButtonClick(
                                                      "cancel",
                                                      item
                                                    )
                                                  }
                                                  icon={
                                                    <FontAwesomeIcon
                                                      size="sm"
                                                      className="event-icon"
                                                      icon={faTrash}
                                                    />
                                                  }
                                                ></Button>
                                              </div>
                                            }
                                          </>
                                      }

                                    </div>
                                  </>
                                }
                              </div>
                            </div>
                          </>
                        );
                      }
                    )}
                  </div>
                ) : (
                  <div className="no-result-found mar-top-10">
                    <div className="no-result-img">
                      <img
                        src={
                          userInfo?.message?.data?.profile_image_url
                            ? userInfo?.message?.data?.profile_image_url
                            : validate?.payload?.message?.profile_image_url
                              ? validate?.payload?.message?.profile_image_url
                              : defaultImage
                        }
                        alt="searchEventImg"
                      />
                    </div>
                    <h3 className="no-event-hd"> No Shows At This Time.</h3>
                    <p className="event-para">Please check back later.</p>
                    {/* <p>No Event At This Time. Please check back later.</p> */}
                  </div>
                )}
              </>
            ) : (
              <>{null}</>
            )}

            {getShowRecordEvents?.payload?.message?.data.length > 0 && (
              <ReactPaginate
                nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                onPageChange={handlePageClick}
                pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
                // marginPagesDisplayed={2}

                pageCount={
                  getShowRecordEvents?.payload?.message?.next
                    ? getShowRecordEvents?.payload?.message?.next?.page
                    : getShowRecordEvents?.payload?.message?.previous
                      ? getShowRecordEvents?.payload?.message?.previous?.page + 1
                      : 1
                }
                forcePage={currentPage}
                previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                pageClassName="page-item d-none"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item d-none"
                breakLinkClassName="page-link"
                containerClassName={"pagination custom-paginate"}
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            )}
          </div>
        )
      ) : (
        <LoadingSpin />
      )}
      {showChild && <ViewData review_id={order} flag={flag} />}
      <Modal
        className="modal-generic modal-500"
        centered
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        footer={false}
        maskClosable={false}
      >
        <h2 className="modal-hd1">Share</h2>
        {shareLoad ? (
          <span className="spinclass loader-center position-relative">
            <Spin size="medium" />
          </span>
        ) : (
          <div class="your-copy-link">
            <div class="item-a">
              <a
                target="_blank"
                rel="noreferrer"
                href={shareUrls?.payload?.message}
              >
                {shareUrls?.payload?.message}
              </a>
            </div>
            <div class="item-b">
              {/* <div class="item-b copied"> */}
              <Button onClick={() => copyUrl(shareUrls?.payload?.message)}>
                Copy
              </Button>
            </div>
          </div>
        )}

        <div className="text-center mt-4">
          <Button onClick={handleCancel} className="default-btn outline">
            Exit
          </Button>
        </div>
      </Modal>

      <Modal
        className="modal-generic modal-700"
        centered
        visible={isModalOpenSocial}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        footer={false}
        maskClosable={false}
      >
        <h2 className="modal-hd1">Enter Details</h2>
        <div className="form-styling ">
          <div className="row ">
            <div className="col-12 mb-20">
              <p className="mbpx-5">Select Social Platform</p>
              <Select
                className="select-style dark-field w-100 "
                placeholder="Social Platform"
                size="large"
                value={socialName}
                onChange={(e) => handleSocial(e)}
              >
                <Option value="Facebook">Facebook</Option>
                <Option value="Instagram">Instagram</Option>
                <Option value="Pinterest">Pinterest</Option>
                <Option value="LinkedIn">LinkedIn</Option>
              </Select>
            </div>
          </div>
          <div className="row">
            <div className="col-6 mb-20">
              <div className="social-modal-banner-main">
                <img
                  src={
                    getEvent?.payload?.message?.data?.[eventNum]?.event?.banner
                  }
                  className="social-modal-banner"
                  alt="banner"
                />
              </div>
            </div>
            <div className="col-6 mb-20">
              <Form
                fields={[
                  {
                    name: ["notes"],
                    value: form.getFieldValue().notes,
                  },
                ]}
              >
                <Form.Item
                  labelCol={{ span: 24 }}
                  label="Caption"
                  // initialValue={
                  //   formState === 'add' ? null : form.getFieldValue().notes
                  // }
                  name="notes"
                >
                  <TextArea
                    showCount
                    maxLength={200}
                    // onChange={onChange}
                    className="dark-field"
                    // placeholder="Get rid of 2021 inventory. Use Promo Code : WINTER2022 - Promo Code description : 20% OFF of the entire collection"
                    placeholder="Enter your details"
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    initialValues={form.getFieldValue().notes}
                    onChange={(e) =>
                      form.setFieldsValue({ notes: e.target.value })
                    }
                  />
                </Form.Item>
              </Form>
            </div>
          </div>

          <div className="d-flex justify-content-end">
            <Button
              disabled={socialName == "" ? true : false}
              loading={loading}
              onClick={postSocialDetails}
              className={`default-btn padb-3 d-inline-flex align-items-center justify-content-center 
              ${socialName == "" ? "color-secondary" : ""}`}
            >
              <span className="nav-text">Post</span>
            </Button>
            <Button
              className="default-btn padb-3 outline d-inline-flex align-items-center justify-content-center"
              key="back"
              onClick={handleCancel}
            >
              Exit
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
