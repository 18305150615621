import React, { useState, useEffect, useRef } from "react";
import Table from "react-bootstrap/Table";
import { Modal } from "react-bootstrap";
import { Button, Form, Input, InputNumber, Modal as Modald, notification, Image } from "antd";
import { DatePicker, Spin, Select, Empty } from "antd";
import {
  customerorderHistory,
  details,
  getPurchaser,
  getReferrer,
  getFilterUsers
} from "../../redux/actions/purchaseHistory.action";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import AsyncSelect from "react-select/async";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight, faXmark } from "@fortawesome/free-solid-svg-icons";
import "../../css/sales.scss";
import moment from "moment";
import numeral from "numeral";
import { getSavedCategories, getSavedGeneralCategories, getSavedSubCategories } from "../../redux/actions/newCategory.action";
import { searchEventID, searchReviewID, searchShowID } from "../../redux/actions/eventID.action";
import { unFollow } from "../../redux/actions/unFollowBrand";
import { brandFollowers } from "../../redux/actions/brandFollowers";
import defaultImage from '../../images/user.png';
import AsyncInfluencer from "../influencerReview/asyncInfluencer";
import AsyncInfluencerFollow from "./asyncInfluencerFollow";
import { getInfluencers } from "../../redux/actions/influencerNew.action";
const { Option } = Select;
let arrHost = [];
const { RangePicker } = DatePicker;

export default () => {
  const [form] = Form.useForm();
  const dateFormat = "MM-DD-YYYY";
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const min_date = moment().startOf("year").format("YYYY-MM-DD");
  const max_date = moment(new Date()).format("YYYY-MM-DD");

  const [startDate, setStartDate] = useState(min_date);
  const [endDate, setEndDate] = useState(max_date);
  const [totalEvent, setTotalEvent] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [source, setSource] = useState("all");
  const [allCustomer, setAll] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [influencerId, setInfluencerId] = useState();
  const [modal, setModal] = useState(false);
  const [flag, setFlag] = useState(false);
  const [status, setStatus] = useState(false);
  const [submit, setSubmit] = useState("none");
  const [customerId, setCustomerId] = useState();
  const [gender, setGender] = useState();
  const [category, setCategory] = useState();
  const [subCategory, setSubCategory] = useState();
  const [load, setLoad] = useState(false);
  const [generalLoad, setGeneralLoad] = useState(false);
  const [catLoad, setCatLoad] = useState(false);
  const [loadd, setLoadd] = useState(false);
  const [subCatLoad, setSubCatLoad] = useState(false);
  const [order, setOrder] = useState();
  const [purchaseBy, setPurchaseBy] = useState();
  const [referredBy, setReferredBy] = useState();
  const [searchPurchaseBy, setSearchPurchaseBy] = useState([]);
  const [searchReferredBy, setsearchReferredBy] = useState([]);
  const [banType, setBanType] = useState("chatban");
  const [groupByTable, setGroupByTable] = useState("chatban");
  const [purchaseLoad, setPurchaseLoad] = useState(false);
  const [referrerLoad, setReferrerLoad] = useState(false);
  const [searchID, setSearchID] = useState();
  const [searchIDLoad, setSearchIDLoad] = useState("");
  const [searchDataIDs, setSearchDataIDs] = useState([]);
  const [idType, setIdType] = useState("event");
  const [followID, setFollowID] = useState();
  const [banModal, setBanModal] = useState(false);
  const [influencer, setInfluencer] = useState("");

  const { shopifyCustomerorder, detailsOrder, savedGeneralCategories, savedCategory, savedSubCategory, brandFollows, getAllInfluencers } = useSelector(
    (state) => {
      return state;
    }
  );
  const childRef = useRef();
  const limit = 15;
  const upperLimit = (currentPage + 1) * limit;
  const lowerLimit = upperLimit - limit + 1;
  const itemsPerPage = 15;
  const pageCount = Math.ceil(totalEvent / itemsPerPage);
  const getSum = (a1, a2) => parseFloat(a1) * parseFloat(a2);

  useEffect(() => {
    // setSearchIDLoad(true)
    // dispatch(searchEventID("", "event", startDate, endDate)).then((res) => {
    //   setSearchIDLoad(false)
    //   if (res?.success) {
    //     setSearchDataIDs(res?.message)
    //   }
    // })
    // setLoading(true);
    // setGeneralLoad(true)
    // dispatch(getSavedGeneralCategories()).then((res) => {
    //   if (res.success) {
    //     setGeneralLoad(false)
    //   } else {
    //     setGeneralLoad(false)
    //   }
    // })

    dispatch(brandFollowers(1, limit)).then((res) => {
      setLoading(false)
    })
    dispatch(getFilterUsers("")).then((res) => {
      if (res?.success) {
        setSearchPurchaseBy(res?.message)
      }
    })

    // dispatch(
    //   customerorderHistory(1, limit, startDate, endDate, gender, category, subCategory, source, "", "", "", "", "Order")
    // ).then((res) => {
    //   setTotalEvent(res?.message?.total_records);
    //   setLoading(false);
    // });
    // dispatch(getInfluencers()).then((res) => {
    //   
    // })
  }, []);

  const dateRangePickerChanger = (value, dataString) => {
    const startDate = dataString[0];
    const endDate = dataString[1];
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handlePageClick = (e) => {
    const page = e.selected;
    setCurrentPage(page);
    setLoading(true);
    dispatch(brandFollowers(page + 1, limit)).then((res) => {
      setLoading(false)
    })
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setGroupByTable(banType)
    setStatus(true);
    dispatch(brandFollowers(1, limit, purchaseBy)).then((res) => {
      setLoading(false);
      setInfluencer("");
      setTotalEvent(res?.message?.total_records);
      setCurrentPage(0);
      dispatch(getFilterUsers("")).then((res) => {
        if (res?.success) {
          setSearchPurchaseBy(res?.message)
        }
      })
    });
    // setSubmit(groupBy);
  };

  const detailView = (orderDetail) => {
    setModal(true);
    setLoad(true);
    dispatch(details(orderDetail?.sales_tracker_id, orderDetail?._id)).then(
      (res) => {
        setLoad(false);
      }
    );
  };

  const handleReset = (res) => {
    setLoading(true);
    setInfluencerId();
    setPurchaseBy();
    childRef.current?.clear();
    // setBanType("chatban")
    // setGroupByTable("chatban")
    const min_date = moment().startOf("year").format("YYYY-MM-DD");
    const max_date = moment(new Date()).format("YYYY-MM-DD");
    setStartDate(min_date);
    setEndDate(max_date);
    dispatch(brandFollowers(1, limit)).then((res) => {
      setLoading(false);
      setInfluencer("");
      setTotalEvent(res?.message?.total_records);
    });
    dispatch(getFilterUsers("")).then((res) => {
      if (res?.success) {
        setSearchPurchaseBy(res?.message)
      }
    })
  };

  const loadOptions = async (input, callback) => {
    await smartSearchFilter(input);

    const result = arrHost.filter((item) => {
      return {
        value: `${item.value}`,
        label: `${item.label}`,
      };
    });

    callback(result);
  };

  const smartSearchFilter = async (value) => {
    if (value.length > 0 && value.trim()) {
      await axios
        .post(`brand/reports/getCustomers`, {
          name: value.trim(),
        })
        .then((response) => {
          const loadHost = [];
          const host = response.data.data;
          if (host.length === 0) {
            // notification.error({
            //   message: "No Host Found",
            //   className: "toast-error",
            // });
          }
          setAll(host);
          host.map((item) => {
            return loadHost.push({
              value: item?._id,
              label: item?.first_name + " " + item?.last_name,
            });
          });
          arrHost = loadHost;
        })
        .catch(function (error) {
          // console.log(error);
        });
    }
  };

  useEffect(() => {
    document.body.classList.add("bioshop-body", "shopper-bioshop-body");
  }, []);

  const handleGender = (e) => {
    setCurrentPage(0);
    setGender(e);
    setCategory(null)
    setSubCategory(null)
    setCatLoad(true)
    dispatch(getSavedCategories(e)).then((res) => {
      if (res.success) {
        setCatLoad(false)
      } else {
        setCatLoad(false)
      }
    })

  };

  const handleCategory = (e) => {
    setCurrentPage(0);
    setCategory(e);
    setSubCategory(null)
    setSubCatLoad(true)
    dispatch(getSavedSubCategories(gender, e)).then((res) => {
      if (res.success) {
        setSubCatLoad(false)
      } else {
        setSubCatLoad(false)
      }
    })
  };

  const handleSubCategory = (e) => {
    setCurrentPage(0);
    setSubCategory(e);
  };

  const handleSource = (e) => {
    setSource(e);
  };

  const handleFilterPurchaseBy = (e) => {
    setPurchaseLoad(true)
    dispatch(getFilterUsers(e)).then((res) => {
      setPurchaseLoad(false)
      if (res?.success) {
        setSearchPurchaseBy(res?.message)
      }
    })

  }

  const unfollow = () => {
    setLoadd(true)

    dispatch(unFollow(followID)).then((res) => {
      if (res.success) {
        notification.success({
          message: res?.message,
          className: "toast-success",
        });

        setLoadd(false)
        setBanModal(false);
        dispatch(brandFollowers(1, limit, "")).then((res) => {
          setLoadd(false)

        })
      }
      else {
        setLoadd(false)
        notification.error({
          message: "User not found in ban list",
          className: "toast-error",
        });
      }
    })
  }

  const handleIDType = (e) => {
    setIdType(e)
    setSearchDataIDs([])
    setSearchID(null)

    switch (e) {
      case "event":
        return dispatch(searchEventID("", "event", startDate, endDate)).then((res) => {
          setSearchIDLoad(false)
          if (res?.success) {

            setSearchDataIDs(res?.message)
          }
        })
      case "show":
        return dispatch(searchShowID("", "show", startDate, endDate)).then((res) => {
          setSearchIDLoad(false)
          if (res?.success) {

            setSearchDataIDs(res?.message)
          }
        })
      case "review":
        return dispatch(searchReviewID("", "review", startDate, endDate)).then((res) => {
          setSearchIDLoad(false)
          if (res?.success) {

            setSearchDataIDs(res?.message)
          }
        })
    }
  }

  const handleReviewID = (e) => {
    setSearchIDLoad(true)
    switch (idType) {
      case "event":
        return dispatch(searchEventID(e.replaceAll("-", ''), "event", startDate, endDate)).then((res) => {
          setSearchIDLoad(false)
          if (res?.success) {

            setSearchDataIDs(res?.message)
          }
        })
      case "show":
        return dispatch(searchShowID(e.replaceAll("-", ''), "show", startDate, endDate)).then((res) => {
          setSearchIDLoad(false)
          if (res?.success) {

            setSearchDataIDs(res?.message)
          }
        })
      case "review":
        return dispatch(searchReviewID(e.replaceAll("-", ''), "review", startDate, endDate)).then((res) => {
          setSearchIDLoad(false)
          if (res?.success) {

            setSearchDataIDs(res?.message)
          }
        })
    }
  }
  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  const selectInfluencer = (value) => {
    setInfluencerId(value);

  }

  const getInfluencer = (influencerData) => {
    setInfluencerId(influencerData);
  };

  const handleFilterReferredBy = (e) => {
    setReferrerLoad(true)
    dispatch(getReferrer(e)).then((res) => {
      setReferrerLoad(false)
      if (res?.success) {
        setsearchReferredBy(res?.message)
      }
    })

  }
  // This is componentWillUnmount
  useEffect(() => {
    return () => {
      //  componentWillUnmount.current = true;
      document.body.classList.remove("bioshop-body", "shopper-bioshop-body");
    };
  }, []);

  function DefaultTable() {
    // let data = shopifyCustomerorder?.payload?.message?.data;

    return (<>
      <div className="table-responsive scrollbar-style">
        <table className="transactions-box table">
          <thead className="table_heading">
            <tr>
              <th className="td-width-60">S.#</th>
              <th className="td-width-200">Pixel ID</th>
              <th className="td-width-100">Profile Image</th>
              <th className="td-width-120">User Name</th>
              <th className="td-width-120">Account Type</th>
              <th className="td-width-100">Action</th>
            </tr>
          </thead>
          <tbody>
            {brandFollows?.payload?.followerData?.length === 0 ? (
              <tr>
                <td colspan="12">
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </td>
              </tr>
            ) : (
              brandFollows?.payload?.followerData?.map((item, i) => {
                return (
                  <tr>
                    <td>{i + 1 + currentPage * 15}</td>
                    <td>
                      {item?.userData?.[0]?.pixel_id}
                    </td>
                    <td>
                      <div className="listing-image inventory-image">
                        <Image src={`${item?.userData?.[0]?.profile_image_url ? item?.userData?.[0]?.profile_image_url : defaultImage}`} />
                      </div>
                    </td>
                    <td>
                      {item?.userData?.[0]?.name}
                    </td>
                    <td>
                      {item?.userData?.[0]?.account_type}
                    </td>
                    <td className="">
                      <button type="button" onClick={() => { setBanModal(true); setFollowID(item?.follower_id) }} class="ant-btn ant-btn-primary default-btn padb-3 small-btn m-width-100 ml-0 mt-1 mb-1 fw-normal h-30"><span>Remove</span></button>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </>
    );



  }
  return (
    <>
      <div className="sales-page-main">
        {/* <div class="title-area mb-10">
          <h1>Orders</h1>
        </div> */}
        <div className="brand_container_main aff-payment">
          <Form form={form}>
            <div className="row w-20-desktop-main">
              <div className="col-xl-3 col-md-6 col-12 mb-15">
                <p className="mb-5rem">Select User</p>
                <div className="select-inf-field d-flex flow-row">
                  <Select
                    showSearch
                    size="large"
                    className="w-100"
                    key={4}
                    notFoundContent={purchaseLoad ? <Spin size="small" /> : "Not Data Found"}
                    loading={purchaseLoad}
                    optionFilterProp="children"
                    placeholder="Enter User Name"
                    onChange={(e) => setPurchaseBy(e)}
                    onSearch={handleFilterPurchaseBy}
                    filterOption={(input, options) =>
                      options?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    focus={false}
                    // onChange={handleFilterSKU}
                    value={purchaseBy}
                    onBlur={false}
                  >
                    {searchPurchaseBy && !purchaseLoad &&
                      searchPurchaseBy?.map((item, i) => {
                        return (
                          <Option value={item?.user_info?._id}>
                            {item?.user_info?.email ? item?.user_info?.name + " (" + item?.user_info?.email + ")" : item?.user_info?.name}
                          </Option>
                        );
                      })}
                  </Select>
                  {/* <AsyncInfluencerFollow
                    ref={childRef}
                    getInfluencer={getInfluencer}
                    influencer={influencer}
                  /> */}
                  {/* <Select
                  showSearch
                  name="sort"
                  size="large"
                  placeholder="Select Creator"
                  className="w-100 select-style dark-field"
                  optionFilterProp="children"
                  value={influencerId}
                  onChange={(value) => selectInfluencer(value)}
                  // onSearch={onSearch}
                  filterOption={filterOption}
                  options={getAllInfluencers?.message && [
                    ...getAllInfluencers?.message].map((influencer) => {
                      return ({
                        label: influencer?.name + " - " + influencer?.pixel_id + " - " + influencer?.email,
                        value: influencer?.user_id
                      }
                      )
                    })
                  }
                /> */}

                </div>
              </div>
              {/* <div className="col-xl-3 col-md-6 col-12 mb-15">
                <p className="mb-5rem">Select Start Date / End Date</p>
                <RangePicker
                  size="large"
                  className="w-100"
                  key={4}
                  value={
                    startDate && endDate
                      ? [moment(startDate), moment(endDate)]
                      : []
                  }
                  allowClear={false}
                  ranges={{
                    "Last Year": [
                      moment().subtract(1, "year").startOf("year"),
                      moment().subtract(1, "year").endOf("year"),
                    ],
                    "This Year": [moment().startOf("year"), moment()],
                    "Last Month": [
                      moment().subtract(1, "month").startOf("month"),
                      moment().subtract(1, "month").endOf("month"),
                    ],
                    "This Month": [
                      moment().startOf("month"),
                      moment().endOf("month"),
                    ],
                    Today: [moment(), moment()],
                  }}
                  format={dateFormat}
                  onChange={dateRangePickerChanger}
                />
              </div> */}

              {/* <div className="col-xl-3 col-md-6 col-12 mb-15">
                <p className="mb-5rem">Ban Type</p>
                <Select
                  className="w-100 select-style dark-field"
                  placeholder="Select Group By"
                  size="large"
                  focus={false}
                  loading={loading}
                  disabled={loading}
                  onBlur={false}
                  value={banType}
                  onChange={(e) => setBanType(e)}
                // defaultValue="date"
                >
                  <Option value="chatban">Events/Reviews Ban</Option>
                  <Option value="permaban">Global Ban</Option>
                </Select>
              </div> */}




              <div className="col-xl-3 col-md-6 col-12 d-flex align-items-end mb-15">
                <Button
                  onClick={handleSubmit}
                  className="default-btn fltr-hpr  medium-btn m-width-100 ml-0 fw-normal"
                  type="primary"
                  htmlType="submit"
                >
                  Search
                </Button>
                <Button
                  onClick={handleReset}
                  className="default-btn outline fw-normal fltr-hpr medium-btn  res-btn2 min-w-100"
                  type="primary"
                  htmlType="submit"
                >
                  Reset
                </Button>
              </div>
            </div>



          </Form>
          <hr className="separator-line mt-0" />
          <div className="purchase-data referral-data">
            {loading ? (
              // <div className="antd-loading-ift">
              // 	<Spin size="large" />
              // </div>
              <div className="loading-wrap">
                <span className="spinclass loader-center position-relative">
                  <Spin size="large" />
                </span>
              </div>
            ) : (
              <>
                {<DefaultTable />}
                {/* {submit === "date" && <DateGroupTable />} */}
                {/* {submit === "category" && <CategoryGroupTable />}
                {submit === "brand" && <BrandGroupTable />} */}
                <div className="purchase-data referral-data">
                  {brandFollows?.payload?.followerData?.length ? (
                    <ReactPaginate
                      nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
                      pageCount={Math.ceil(brandFollows?.payload?.totalItems / 15)}
                      forcePage={currentPage}
                      previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                      pageClassName="page-item d-none"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      breakClassName="page-item d-none"
                      breakLinkClassName="page-link"
                      containerClassName={"pagination custom-paginate"}
                      activeClassName="active"
                      renderOnZeroPageCount={null}
                    />
                  ) : (
                    ""
                  )}
                </div>

              </>
            )}
          </div>
        </div>
      </div>


      <Modald
        maskClosable={false}
        className="modal-generic modal-500"
        centered
        visible={banModal}
        onCancel={() => setBanModal(false)}
        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        footer={[
          <Button type="primary" onClick={() => setBanModal(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            // onClick={handleOk}
            loading={loadd}
            onClick={() => {
              unfollow();
            }}
          >
            Remove
          </Button>,
        ]}
      >
        <h2 className="modal-hd1">Removing Confirmation</h2>
        <div className="my-4">
          <h5>Are you sure you want to remove user?</h5>
        </div>
      </Modald>
    </>
  );
};
