import React, { useEffect, useState, useRef, useContext } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import * as influencerReviewAction from "../../redux/actions/influencerReview";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faXmark,
  faCalendarDays,
  faClock,
  faFilePdf,
  faLink,
  faTrash,
  faPlay,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import NoDataFound from "../../reUsable/NoDataFound";
import { Col, Row } from "react-bootstrap";
import {
  Spin,
  Modal,
  Button,
  Form,
  Image as ImageA,
  Input,
  Upload,
  message,
  Select,
  notification,
  Avatar,
  Table,
  DatePicker,
  InputNumber,
  Progress,
  Checkbox
} from "antd";
import "video-react/dist/video-react.css";
import AsyncProduct from "../create-event/scheduleEvent/asyncProduct";
import GroupIcon from "@mui/icons-material/Group";
import Typography from "@mui/material/Typography";
import Swal from "sweetalert2";
import { getAllCreator } from "../../redux/actions/influencerReview";
import ReactPlayer from "react-player";
import LoadingSpin from "../../reUsable/loadingSpin";
import {
  getSavedGeneralCategories,
  getSavedCategories,
  getSavedSubCategories,
} from "../../redux/actions/newCategory.action";
import AsyncInfluencer from "./asyncInfluencer";
import { Percent } from "../../context/PercentProvider";
import { UPLOAD_DONE_REVIEW } from "../../redux/types/types";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { getFeeStructure } from "../../redux/actions/feestructure.action";
import { getShopifyDetail } from "../../redux/actions/shopify.action";
import { getInfluencers } from "../../redux/actions/influencerNew.action";
import { getPricePoint } from "../../redux/actions/receivePrice.action";
import { Link, useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Image } from "@react-pdf/renderer";
import { ErrorBoundary } from "react-error-boundary";
import ReactRouterPrompt from "react-router-prompt";
import "jspdf-autotable";
import { searchBySkuAndTitle } from "../../redux/actions/inventoryActive.action";
// import { getPromoRequest } from "../../redux/actions/promoRequest";
const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = "MM-DD-YYYY";

function LinkProducts({
  getInfluencerReview,
  getInfluencerVideo,
  linkInfluencerProduct,
  reviewDeleted,
}) {
  const dispatch = useDispatch();
  const {
    parentCategory,
    getAllCreatorData,
    promoRequest,
    reactS3BucketData,
    savedGeneralCategories,
    uploadDetailReview,
    getStructureFees, getAllInfluencers,
    validate, getInventoryProductsBySkuAndTitle
  } = useSelector((state) => {
    return state;
  });
  const socketUrl = process.env.REACT_APP_SOCKET_URL;
  const percent = useContext(Percent);
  const childRef = useRef();
  const didUnmount = useRef(false);
  const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(
    socketUrl,
    {
      shouldReconnect: (closeEvent) => {
        return didUnmount.current === false;
      },
      reconnectAttempts: 2,
      reconnectInterval: 3000,
    }
  );
  useEffect(() => {
    // dispatch(getShopifyDetail()).then((res) => {
    //   if (res?.payload?.connection) {
    //     setShopify(false)
    //   } else {
    //     setShopify(true)
    //   }

    // })
    dispatch(getInfluencers()).then((res) => {
      setUser(res?.message[0]?.user_id);
    })
    dispatch(getFeeStructure()).then((res) => { });
    return () => {
      didUnmount.current = true;
    };
  }, []);
  const [loading, setLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showPlayer, setShowPlayer] = useState(false);
  const [shopify, setShopify] = useState(false);
  const [videoData, setVideoData] = useState("");
  const [load, setLoad] = useState(false);
  const [banner, setBanner] = useState([]);
  const [sku, setSku] = useState([]);
  const [skuError, setSkuError] = useState(false);
  const [parent, setParent] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);
  const [dataDetails, setDataDetail] = useState([]);
  const [isModalOpenDetail, setIsModalOpenDetail] = useState(false);
  const [flag, setFlag] = useState(false);
  const [productPromoCodeDscs, setProductPromoCodeDscs] = useState("0%");
  const [productPromoCodePromo, setproductPromoCodePromo] = useState("KB0");
  const [referralPercent, setReferralPercent] = useState("0");
  const [creatorFee, setCreator_fee] = useState("0");
  const [influencer_percent, setInfluencer_percent] = useState("0");
  // const fromDate = moment().startOf('year').format('YYYY-MM-DD');
  // const toDate = moment(new Date()).format("YYYY-MM-DD");
  const toDate = moment(new Date()).add(1, 'days').format("YYYY-MM-DD");
  const fromDate = moment().subtract(1, "year").startOf("year").format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(fromDate);
  const [endDate, setEndDate] = useState(toDate);
  const [influencerId, setInfluencerId] = useState();
  const [influencer, setInfluencer] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [sortBy, setSortBy] = useState("date");
  const limit = 8;
  const firstRender = useRef(true);
  const [gender, setGender] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [genLoading, setGenLoading] = useState(true);
  const [user, setUser] = useState("");
  const [email, setEmail] = useState("");
  const [userExist, setUserExist] = useState(false);
  const [msg, setMsg] = useState(false);
  const [dateTime, setDate] = useState(moment().format());
  const [videoTitle, setVideoTitle] = useState("");
  const [promptModal, setPromptModal] = useState(false);
  const [defaultFile, setDefaultFile] = useState("");
  const [defaulted, setDefaulted] = useState(true);
  const [prices, setPrices] = useState([]);
  const [matchingPrice, setMatchingPrice] = useState([]);
  const [form] = Form.useForm();
  const history = useNavigate();
  useEffect(() => {
    if (lastMessage && lastMessage.data) {
      let newdata = JSON.parse(lastMessage.data);
      if (newdata.type === "eventmessage") {
        if (newdata.kind === "review") {
          // setLoading(true);
          setCurrentPage(0);
          getInfluencerReview(
            "active",
            1,
            limit,
            startDate,
            endDate,
            influencerId,
            sortBy
          );
          // .then(() => setLoading(false));
        }
      }
    }
  }, [lastMessage]);
  useEffect(() => {
    getInfluencerReview(
      "active",
      1,
      limit,
      startDate,
      endDate,
      influencerId,
      sortBy
    ).then((res) => {
      firstRender.current = false;
      setLoading(false);
    });
    // dispatch(getParentCategories()).then((res) => {
    //   if (res.payload.success) {
    //     // setLoading(false);
    //   } else {
    //     // setLoading(false);
    //   }
    // });
    dispatch(getSavedGeneralCategories()).then((res) => {
      if (res.success) {
        setGenLoading(false);
      } else {
        setGenLoading(false);
      }
    });
    dispatch(getAllCreator()).then((res) => {
      if (res.payload.success) {
        // setLoading(false);
      } else {
        // setLoading(false);
      }
    });
    // dispatch(getPromoRequest()).then((res) => { });
    dispatch(getPricePoint()).then((res) => {
      // console.log(res.data, '12323');
      setPrices(res.data)
      // console.log(prices, 'prices');
    })
    dispatch(searchBySkuAndTitle({ sku: "", sort_on: "stock", sort: "asc", is_orme_product: true }, 1, 1)).then((res) => {
      if (res?.success) {
      } else {
        console.log(res, "error");
      }
    });
  }, []);
  // console.log(sku, 'sku');
  let matchingRange;
  useEffect(() => {
    if (sku.length > 0) {
      // console.log('change');

      let totalPrice = sku.reduce((sum, item) => sum + Number(item.price), 0);

      let maxValue = sku.reduce((max, item) => Math.max(max, item.price), -Infinity);
      // console.log(maxValue, 'maxValue');
      // console.log(totalPrice, 'total');

      matchingRange = prices.find((price) => maxValue >= price.min && maxValue <= price.max);
      setMatchingPrice([matchingRange])
      // console.log(matchingPrice, 'matchingPrice');

      form.setFieldsValue({
        pricepoint: matchingRange?.name,
        pricepointId: matchingRange?._id,
      })


    }
    else {
      form.setFieldsValue({
        pricepoint: '',
        pricepointId: ''
      })
    }
  }, [sku])

  useEffect(() => {
    if (user !== "") {
      setMsg(false);
    }
  }, [user]);
  useEffect(() => {
    if (!firstRender.current) {
      setTimeout(() => {
        setLoading(true);
        getInfluencerReview(
          "active",
          1,
          limit,
          startDate,
          endDate,
          influencerId,
          sortBy
        ).then(() => setLoading(false));
      }, 3000);
    }
  }, [uploadDetailReview]);
  const onLinkProduct = async (item) => {
    form.setFieldsValue({ banner: item?.banner });

    setDefaultFile(item?.banner)
    setDefaulted(true);

    setShowPlayer(true);
    setVideoData(item);
  };

  function intlFormat(num) {
    return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
  }
  const generatePDF = (item) => {
    // initialize jsPDF
    // console.log(item);
    const doc = new jsPDF({
      orientation: "landscape"
    });

    // define the columns we want and their titles
    doc.text("WorkSheet " + "'" + item?.file_name + "'", 20, 10);
    doc.addImage("https://source.unsplash.com/random/?Placeholder", "PNG", 10, 20, 120, 170)
    doc.text(`Creator: ${!item?.influencer?.email ? "___________________________" : item?.influencer?.email}`, 160, 30);
    doc.text("Title:___________________________", 160, 40);
    doc.text("Department: ___________________________ ", 160, 50);
    doc.text("Category: ___________________________ ", 160, 60);
    doc.text("Sub Category: ___________________________ ", 160, 70);
    doc.text("Date & Time: ___________________________ ", 160, 80);
    // doc.text("Promo Code: KB0", 160, 90);
    // doc.text("Discount: 0%", 160, 100);
    // doc.text("Referral Fee:" + " " + getStructureFees?.payload?.message?.fee_structure?.shopper_cashback + "%", 160, 110);
    // doc.text("Influencer Fee:" + " " + getStructureFees?.payload?.message?.fee_structure?.referral_fee + "%", 160, 120);
    // doc.text("Content Fee:" + " " + getStructureFees?.payload?.message?.fee_structure?.content_creator_fee + "%", 160, 130);
    doc.text("Product SKU: ___________________________ ", 160, 140);
    doc.text("Product SKU: ___________________________ ", 160, 150);
    doc.text("Product SKU: ___________________________ ", 160, 160);
    doc.text("Product SKU: ___________________________ ", 160, 170);
    doc.text("Product SKU: ___________________________ ", 160, 180);

    doc.save(`worksheet_${item?.file_name}.pdf`);
  };
  function numberFormat(num) {
    if (num >= 1000000) return intlFormat(num / 1000000) + "M";
    if (num >= 1000) return intlFormat(num / 1000) + "k";
    return intlFormat(num);
  }

  const changePromoCode = (e, options, name, index) => {
    if (e === undefined) {
    } else {
      var values = e.value.split(" ");
      var discount = values[0];

      setProductPromoCodeDscs(discount);
      setproductPromoCodePromo(e.children);
    }
  };
  const onDateTimeChange = (e) => {
    if (e !== null) {
      setDate(e._d);
    } else {
      setDate("");
    }
  };
  function onDelete(seletedItem) {
    // setCurrentData(seletedItem);
    Swal.fire({
      title: "Are You Sure?",
      text: "You Want To Archive This Video?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#052977",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {
        setDeleteLoading(true);
        reviewDeleted({
          review_id: seletedItem._id,
          status: "delete",
        })
          .then(() => {
            if (
              uploadDetailReview?.find(
                (item) => item?.id === seletedItem.review_s3_id
              )
            ) {
              dispatch({
                type: UPLOAD_DONE_REVIEW,
                payload: { id: seletedItem.review_s3_id },
              });
            }
            setDeleteLoading(false);
            notification.success({
              message: "Successfully Archive Video",
              className: "toast-success",
            });
            setLoading(true);
            getInfluencerReview(
              "active",
              1,
              limit,
              startDate,
              endDate,
              influencerId,
              sortBy
            ).then(() => setLoading(false));
          })
          .catch((err) => {
            setDeleteLoading(false);
            notification.error({
              message: err.response.message,
              className: "toast-error",
            });
          });
      }
    });
  }

  const PDF = ({ item }) => {
    const styles = StyleSheet.create({
      page: {
        padding: 30,
      },
      container: {
        flex: 1,
        flexDirection: 'row',
      },
      image: {
        marginBottom: 10,
      },
      leftColumn: {
        flexDirection: 'column',
        width: 170,
        paddingTop: 30,
        paddingRight: 15,
      },
      footer: {
        fontSize: 12,
        textAlign: 'center',
        marginTop: 15,
        paddingTop: 5,
        borderWidth: 3,
        borderColor: 'gray',
        borderStyle: 'dashed',
        '@media orientation: landscape': {
          marginTop: 10,
        },
      },
      headerarea: {
        flexDirection: 'row',
        borderBottomWidth: 2,
        borderBottomColor: '#112131',
        borderBottomStyle: 'solid',
        alignItems: 'stretch',
      },
      detailColumn: {
        flexDirection: 'column',
        flexGrow: 9,
        textTransform: 'uppercase',
      },
      linkColumn: {
        flexDirection: 'column',
        flexGrow: 2,
        alignSelf: 'flex-end',
        justifySelf: 'flex-end',
      },
      hname: {
        fontSize: 16,
      },
      rightContainer: {
        // flex: 1,
        flexDirection: 'column',
        paddingLeft: 15,
        marginBottom: 10,
        paddingTop: 10,
      },
      text: {
        fontSize: 15,
        color: 'black',
      },
      headerContainer: {
        marginTop: 30,
      },
      date: {
        fontSize: 10,
        color: 'black',
        textDecoration: 'none',
        alignSelf: 'flex-end',
        justifySelf: 'flex-end',
      },
    });

    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.headerarea}>
            <View style={styles.detailColumn}>
              <Text style={styles.hname}>Worksheet - {item.file_name}</Text>
            </View>
            <View style={styles.linkColumn}>
              <Text style={styles.date}>{moment(item?.created_at).utc().format("MM-DD-YYYY")}</Text>
            </View>
          </View>

          <View style={styles.container}>
            <View style={styles.leftColumn}>
              <Image
                src={item?.banner}
                style={styles.image}
              />
            </View>

            <View style={styles.headerContainer}>
              <View style={styles.rightContainer}>
                <Text style={styles.text}>
                  Creator : {!item?.influencer?.email ? "____________________" : item?.influencer?.name + " - " + item?.influencer?.email}
                </Text>
              </View>
              <View style={styles.rightContainer}>
                <Text style={styles.text}>Title : _________________________</Text>
              </View>
              <View style={styles.rightContainer}>
                <Text style={styles.text}>Department : _________________________</Text>
              </View>
              <View style={styles.rightContainer}>
                <Text style={styles.text}>Category : _________________________</Text>
              </View>
              <View style={styles.rightContainer}>
                <Text style={styles.text}>Sub Category : _________________________</Text>
              </View>
              {/* <View style={styles.rightContainer}>
                <Text style={styles.text}>Date and Time : _________________________</Text>
              </View> */}
              {/* <View style={styles.rightContainer}>
                <Text style={styles.text}>Promo Code : KB0</Text>
              </View>
              <View style={styles.rightContainer}>
                <Text style={styles.text}>Discount : 0%</Text>
              </View>
              <View style={styles.rightContainer}>
                <Text style={styles.text}>Referral Fee : {getStructureFees?.payload?.message?.fee_structure?.shopper_cashback + "%" + " - unchangeable"}</Text>
              </View>
              <View style={styles.rightContainer}>
                <Text style={styles.text}>Influencer Fee : {getStructureFees?.payload?.message?.fee_structure?.referral_fee + "%" + " - unchangeable"}</Text>
              </View>
              <View style={styles.rightContainer}>
                <Text style={styles.text}>Creator Fee : {getStructureFees?.payload?.message?.fee_structure?.content_creator_fee + "%" + " - unchangeable"}</Text>
              </View> */}
              <View style={styles.rightContainer}>
                <Text style={styles.text}>Product SKU : _________________________</Text>
              </View>
              <View style={styles.rightContainer}>
                <Text style={styles.text}>Product SKU : _________________________</Text>
              </View>
              <View style={styles.rightContainer}>
                <Text style={styles.text}>Product SKU : _________________________</Text>
              </View>
              <View style={styles.rightContainer}>
                <Text style={styles.text}>Product SKU : _________________________</Text>
              </View>
              <View style={styles.rightContainer}>
                <Text style={styles.text}>Product SKU : _________________________</Text>
              </View>

            </View>
          </View>
        </Page>
      </Document>
    );
  };

  const reUse = () => {

    setDefaulted(!defaulted)
    setBanner([])
    form.setFieldsValue({
      banner: defaultFile
    })

  };

  function renderContent() {
    if (!loading) {
      return (
        <Row className="post-analytics-tab-boxes-ift influencer-reviews-main brand-videos-boxes">
          {getInfluencerVideo?.data?.length > 0 ? (
            getInfluencerVideo?.data?.map((item, i) => (
              <Col xs={12} xl={3} md={6}>
                <div
                  key={i}
                  className={`card any_bx analytic-box campaign-box pb-0`}
                >
                  <div className="camp-row row">
                    <div className="campaign-header col-12">
                      <div className="inf-header">
                        {/* <span className="review-box-header mr-2">
                          {item?.influencer?.profile_image_url ? (
                            <Avatar
                              icon={<UserOutlined />}
                              src={item?.influencer?.profile_image_url}
                            />
                          ) : (
                            <Avatar icon={<UserOutlined />} />
                          )}
                        </span> */}
                        <div className="inf-username-box">
                          {item.file_name && (
                            <span className="inf-username filename">
                              Filename:{" "}
                              <span className="file-color">
                                {item?.file_name}{" "}
                              </span>
                            </span>
                          )}
                          {item?.influencer?.name ? (
                            <a
                              title={item?.influencer?.name}
                              className="inf-username usernameReview"
                              target="_blank"
                              href={`https://www.instagram.com/${item?.influencer?.name}`}
                            >
                              {item?.influencer?.name.length > 20
                                ? item?.influencer?.name.slice(0, 20) + "..."
                                : item?.influencer?.name}
                            </a>
                          ) : item?.influencer?.insta_handle ? (
                            <a
                              title={item?.influencer?.insta_handle}
                              className="inf-username"
                              target="_blank"
                              href={`https://www.instagram.com/${item?.influencer?.insta_handle}`}
                            >
                              @
                              {item?.influencer?.insta_handle?.length > 20
                                ? item?.influencer?.insta_handle.slice(0, 20) +
                                "..."
                                : item?.influencer?.insta_handle}
                            </a>
                          ) : (
                            ".."
                            // <span
                            //   className="inf-username"
                            //   title={item?.influencer?.name}
                            // >
                            //   {item?.influencer?.name}
                            // </span>
                          )}
                          {/* <span className="inf-email">{item?.influencer?.email}</span> */}
                          {/* <span className="inf-email">Followers: 10</span> */}
                        </div>

                        {/* <div className="inf-head-followers">
                          <GroupIcon className="fol-icon" />
                          <h5>
                            {item?.influencer?.followers
                              ? numberFormat(item?.influencer?.followers)
                              : 0}
                          </h5>
                        </div> */}
                      </div>
                    </div>
                    <div className="any-post-img-col col-12">
                      <div className="any-post-image media-post-new">
                        {/* {item?.media_url ? (
                          <div className="any-image-box">
                            <div className="any-image-box-iner review-video">
                              <div className="review-video">
                                <ReactPlayer
                                  className={`review_react_player ${item?.media_url?.split(".")[
                                    item?.media_url?.split(".")?.length - 1
                                    ] === "m3u8" && "objectFit"
                                    }`}
                                  width={"100%"}
                                  height={"250px"}
                                  url={item?.media_url}
                                  controls
                                  light={item?.banner}
                                  playsinline
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="d-flex justify-content-center align-items-center w-100 h-100 media-processing">
                              {reactS3BucketData?.payload?.length === 0 ||
                                reactS3BucketData?.length === 0 ? (
                                <>
                                  <div className="d-flex">
                                    <span className="spinclass spin-color position-relative mr-3">
                                      <Spin className="" />
                                    </span>
                                    <div className="process-hd ff-nunito">
                                      Media Processing
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <div className="process-hd ff-nunito">
                                  Media Processed!
                                </div>
                              )}
                            </div>
                          </>
                        )} */}
                        <div className="review-video">
                          {item?.media_url ? (
                            item?.error_reason === "Uploading" &&
                              !item.is_convert ? (
                              <div className="reviewConverting">
                                <Progress
                                  className="convertProgress"
                                  strokeLinecap="butt"
                                  strokeColor={"#52c41a"}
                                  showInfo={false}
                                  percent={100}
                                  size="default"
                                  status="active"
                                />
                                <span className="mt-2">
                                  Media is Converting
                                </span>
                              </div>
                            ) : item?.error_reason ===
                              "Failed on media conversion" ? (
                              <div
                                className="media-processing process-height"
                                style={{ padding: "2rem" }}
                              >
                                <div
                                  className="process-hd ff-nunito"
                                  style={{ color: "rgb(205, 24, 24)" }}
                                >
                                  File is corrupted
                                </div>
                              </div>
                            ) : (
                              <ReactPlayer
                                // className={`review_react_player ${item?.media_url
                                //   ? item?.media_url?.split(".")[
                                //   item?.media_url?.split(".")?.length - 1
                                //   ] === "m3u8" && "objectFit"
                                //   : "objectFit"
                                //   }`}
                                // className="review_react_player objectFit"
                                className={`review_react_player ${item?.orientation === "portrait" ? "" : "objectFit bg-cover"}`}
                                width={"100%"}
                                height={"250px"}
                                url={item?.media_url}
                                controls
                                light={item?.banner}
                                playsinline
                              />
                            )
                          ) : (
                            <div
                              className="media-processing process-height"
                              style={{ padding: "2rem" }}
                            >
                              {percent[item.review_s3_id] ||
                                percent[item.review_s3_id] === 0 ? (
                                <Progress
                                  key={item.review_s3_id}
                                  percent={percent[item.review_s3_id]}
                                  status="active"
                                  type="circle"
                                  strokeLinecap="butt"
                                  strokeColor={"#52c41a"}
                                />
                              ) : item?.error_reason === "Uploading" ? (
                                <div className="process-hd ff-nunito">
                                  Please wait while video in processing
                                </div>
                              ) : (
                                <div
                                  className="process-hd ff-nunito"
                                  style={{ color: "rgb(205, 24, 24)" }}
                                >
                                  {item?.error_reason}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="inf-content-area">
                      <div className="event-date-main top-date">
                        <div className="event-date">
                          <FontAwesomeIcon
                            className="event-icon"
                            icon={faCalendarDays}
                          />
                          <div className="event-txt">
                            {moment(item?.created_at)
                              .utc()
                              .format("MM-DD-YYYY")}
                          </div>
                        </div>

                        <div className="event-date">
                          <FontAwesomeIcon
                            className="event-icon"
                            icon={faClock}
                          />

                          <div className="event-txt">
                            {moment(item?.created_at).format("hh:mm A")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    // className="cam-buttons col-12"
                    className="review-card-footer"
                  >
                    {/* {deleteLoading ? (
                      <button className="btn">
                        <span className="spinclass loader-center position-relative">
                          <Spin size="small" />
                        </span>
                      </button>
                    ) : (
                      <button
                        className="default-btn medium-btn padb-3 m-0 w-inherit"
                        onClick={() => {
                          onLinkProduct(item);
                        }}
                      >
                        <i className="fa fa-link mr-2" />
                        Link Product
                      </button>
                    )}{" "} */}
                    {/* {item?.media_url && ( */}
                    {
                      item?.is_convert ?
                        <>
                          <Button
                            className={`default-btn d-flex align-items-center justify-content-center ${!item?.media_url && "disable-grey2"}`}
                            type="primary"
                            size="large"
                            disabled={!item?.media_url}
                            onClick={() => {
                              if (item?.influencer?.email) {
                                setUser(item?.influencer);
                              } else {
                                setUser(null);
                              }
                              onLinkProduct(item);
                            }}
                            icon={
                              <FontAwesomeIcon
                                size="sm"
                                className="event-icon"
                                icon={faLink}
                              />
                            }
                          >

                            Link Product
                          </Button>

                          {/* <button
                    //   className="default-btn medium-btn padb-3 mr-0 w-inherit"
                    //   onClick={() => onDelete(item)}
                    //   disabled={deleteLoading}
                    // >
                    //   <i class="fa fa-trash" /> Delete
                    // </button> */}

                          <div>
                            <Button
                              className={`default-btn share-btn d-flex align-items-center justify-content-center ${!item?.media_url && "disable-grey2"}`}
                              type="primary"
                              size="large"
                              onClick={() => onDelete(item)}
                              disabled={deleteLoading}
                              title="Delete"
                              icon={
                                <FontAwesomeIcon
                                  size="sm"
                                  className="event-icon"
                                  icon={faTrash}
                                />
                              }
                            ></Button>
                          </div>

                          <div>
                            <PDFDownloadLink document={<PDF item={item} />} fileName={`worksheet ${item.file_name}.pdf`}>
                              <Button
                                className={`default-btn share-btn d-flex align-items-center justify-content-center ${!item?.media_url && "disable-grey2"}`}
                                disabled={!item?.media_url}
                                type="primary"
                                size="large"
                                // onClick={() => generatePDF(item)}
                                title="Download Worksheet"
                                icon={
                                  <FontAwesomeIcon
                                    size="sm"
                                    className="event-icon"
                                    icon={faFilePdf}
                                  />
                                }
                              ></Button>
                            </PDFDownloadLink>
                          </div>

                          {/* <button
                      className="default-btn medium-btn padb-3 mr-0 w-inherit"
                      onClick={() => generatePDF(item)}
                    // disabled={deleteLoading}
                    >

                      <FontAwesomeIcon className="mr-2" icon={faFilePdf} />
                      Download
                    </button> */}
                        </>
                        :
                        <Button
                          loading
                          className="default-btn d-flex align-items-center justify-content-center cursor-not-allowed"
                          type="primary"
                          size="large"
                          // onClick={() =>
                          //   recordedEvents(item?._id, title)
                          // }
                          icon={
                            <FontAwesomeIcon
                              size="sm"
                              className="event-icon"
                              // icon={faVideo}
                              icon={faPlay}
                            />
                          }
                        >
                          Processing
                        </Button>
                    }
                  </div>
                </div>
              </Col>
            ))
          ) : (
            <div className="col-md-12">
              <NoDataFound />
            </div>
          )}
        </Row>
      );
    } else {
      return <LoadingSpin />;
    }
  }

  const handlePageClick = (e) => {
    setLoading(true);
    const page = e.selected;
    setCurrentPage(page);
    getInfluencerReview(
      "active",
      page + 1,
      limit,
      moment(startDate).format("YYYY-MM-DD"),
      moment(endDate).format("YYYY-MM-DD"),
      influencerId,
      sortBy
    ).then(() => setLoading(false));
  };

  const handleCancel = (status) => {
    if (status === "done") {
      setPromptModal(false)
      setShowPlayer(false);
      setBanner([]);
      setSku([]);
      setSubCategoryOptions([]);
      setCategoryOptions([]);
      setCategory("");
      setSubCategory("");
      setGender("");
      setVideoTitle("");
      form.resetFields();
    }
    else {
      if (form.getFieldValue().title ||
        form.getFieldValue().gen_category_id ||
        // form.getFieldValue().datetime ||
        sku.length !== 0 ||
        form.getFieldValue().banner?.length === 0
      ) {
        setPromptModal(true)
      } else {
        setShowPlayer(false);
        setBanner([]);
        setSku([]);
        setSubCategoryOptions([]);
        setCategoryOptions([]);
        setCategory("");
        setSubCategory("");
        setGender("");
        setVideoTitle("");
        form.resetFields();
      }

    };
  }

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const uploadProps = {
    accept: "image/jpeg, image/png, image/jpg, image/svg+xml, image/svg",
    name: "file",
    multiple: false,
    listType: "picture",
    // fileList={fileList}
    maxCount: 1,
    action: "https://httpbin.org/post",

    beforeUpload: (file) => {
      const isPNG =
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/jpeg" ||
        file.type === "image/svg+xml" ||
        file.type === "image/svg";

      if (!isPNG) {
        message.error(`${file?.name} is not a valid format`);
      }

      return isPNG || Upload.LIST_IGNORE;
    },

    onChange(info) {
      const { status } = info?.file;
      setBanner(info?.file?.originFileObj);
      setDefaulted(false)

      if (status === "done") {
        message.success(`${info?.file.name} Image uploaded successfully.`);
      } else if (status === "error") {
        // message.error(`${info.file.name} file upload failed.`);
      }
    },

    onDrop(e) {
    },

    onRemove(e) {
      // console.log(e, "onRemove");
      // return Upload.LIST_IGNORE;
      // setPreviewImage('');
    },
  };
  const onFinish = (val) => {
    const { title, gen_category_id, category_id, sub_category_id } = val;
    // setUser(getAllInfluencers?.message[0]?.user_id);
    // console.log({user});
    if (sku.length === 0) {
      setSkuError(true);
    } else {
      if (user === "") {
        setMsg(true);
      } else {
        setMsg(false);

        let newSku = sku.map((skuItem) => {
          return {
            ...skuItem,
            // referral_percent: referralPercent ? referralPercent : "0",
            referral_percent:
              getStructureFees?.payload?.message?.fee_structure
                ?.referral_fee > 0
                ? getStructureFees?.payload?.message?.fee_structure
                  ?.referral_fee
                : "0",
            influencer_percent:
              getStructureFees?.payload?.message?.fee_structure
                ?.influencer_fee > 0
                ? getStructureFees?.payload?.message?.fee_structure
                  ?.influencer_fee
                : "0",
            content_creator_fee:
              getStructureFees?.payload?.message?.fee_structure
                ?.content_creator_fee > 0
                ? getStructureFees?.payload?.message?.fee_structure
                  ?.content_creator_fee
                : "0",
            cashback_percent:
              getStructureFees?.payload?.message?.fee_structure
                ?.shopper_cashback > 0
                ? getStructureFees?.payload?.message?.fee_structure
                  ?.shopper_cashback
                : "0",
          };
        });
        let formData = new FormData();
        // console.log(dateTime, 'dateTime.toISOString()');
        formData.append("user_id", user?._id ? user?._id : user);
        formData.append("created_date", dateTime);
        formData.append("image", banner?.length === 0 ? defaultFile : banner
        );
        formData.append("media_url", videoData?.media_url);
        formData.append("media_library_id", videoData?._id);
        formData.append("title", title);
        formData.append("gen_category_id", gen_category_id);

        // if (category_id !== "all") {
        formData.append("category_id", category_id ? category_id : "all");
        // }
        // if (sub_category_id !== "all") {
        formData.append(
          "sub_category_id",
          sub_category_id ? sub_category_id : "all"
        );
        // }
        formData.append("products", JSON.stringify(newSku));
        formData.append("discount", productPromoCodeDscs);
        formData.append("promo", productPromoCodePromo);
        // formData.append(
        //   "referral_percent",
        //   referralPercent > 0 ? referralPercent : 0
        // );
        formData.append(
          "referral_percent",
          getStructureFees?.payload?.message?.fee_structure?.referral_fee > 0
            ? getStructureFees?.payload?.message?.fee_structure?.referral_fee
            : 0
        );
        formData.append(
          "influencer_percent",
          getStructureFees?.payload?.message?.fee_structure?.influencer_fee > 0
            ? getStructureFees?.payload?.message?.fee_structure?.influencer_fee
            : 0
        );
        formData.append(
          "content_creator_fee",
          getStructureFees?.payload?.message?.fee_structure?.content_creator_fee > 0
            ? getStructureFees?.payload?.message?.fee_structure?.content_creator_fee
            : 0
        );
        formData.append(
          "cashback_percent",
          getStructureFees?.payload?.message?.fee_structure?.shopper_cashback > 0
            ? getStructureFees?.payload?.message?.fee_structure?.shopper_cashback
            : 0
        );
        formData.append("pricepoint_id", form.getFieldValue().pricepointId);
        formData.append("pricepoint_name", form.getFieldValue().pricepoint);
        setSaveLoading(true);
        linkInfluencerProduct(formData).then((res) => {
          if (res.payload.success) {
            notification.success({
              message: res.payload?.message,
              className: "toast-success",
            });

            setShowPlayer(false);
            setBanner([]);
            setSku([]);
            setVideoTitle("");
            form.resetFields();
            setSaveLoading(false);
            setLoading(true);
            setGender("");
            setSubCategoryOptions([]);
            setCategoryOptions([]);
            setCategory("");
            setSubCategory("");
            getInfluencerReview(
              "active",
              1,
              limit,
              startDate,
              endDate,
              "",
              sortBy
            ).then(() => setLoading(false));
          } else {
            setSaveLoading(false);
            notification.error({
              message: res.payload?.data?.message,
              className: "toast-error",
            });
          }
        });
      }
    }
  };

  const getSku = (skuData) => {
    setSkuError(false);
    setSku(skuData);
  };

  const handleCategory = (e) => {
    let parent = parentCategory?.payload?.message.filter(
      (item) => item.category_id == e
    );
    setParent(parent[0]?.category_name);
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      width: 60,
      render: (text, record, index) => {
        return <p>{index + 1}</p>;
      },
    },
    {
      title: "SKU",
      dataIndex: "ProductSku",
      key: "ProductSku",
      width: 60,
    },
    {
      title: "IMAGE",
      dataIndex: "mediaUrl",
      key: "mediaUrl",
      width: 70,
      render: (text, record) => {
        return (
          <div className="listing-image inventory-image">
            <ImageA src={`${record.mediaUrl}`} />
          </div>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "productDesc",
      key: "productDesc",
      width: 250,
      render: (text, record) => {
        return (
          <div className="desc-limit">
            {record.productDesc.length > 70
              ? record.productDesc.slice(0, 70) + "..."
              : record.productDesc}
          </div>
        );
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      width: 80,
      render: (text, record) => {
        return <div className="desc-limit">${record.price}</div>;
      },
    },
    // {
    //   title: 'Stock',
    //   dataIndex: 'stock',
    //   key: 'stock',
    //   width: 80,
    //   render: (text, record) => {
    //     return (

    //       <p>21</p>

    //     );
    //   },
    // },
    // {
    //   title: 'Discount',
    //   dataIndex: 'discount',
    //   key: 'discount',
    //   width: 80,
    //   // render: (text, record) => {
    //   //   return (

    //   //     <p>0</p>

    //   //   );
    //   // },
    // },

    // {
    //     title: 'Balance',
    //     dataIndex: 'balance',
    //     key: 'balance',
    //     width: 80
    // },
    {
      title: "Balance",
      dataIndex: "event",
      key: "event",
      width: 140,
      render: (text, record) => (
        <>
          <div className="balance-area">
            <div className="balance-count">
              {record?.variants?.reduce((acc, item) => {
                return (acc = acc + item.inventory_quantity);
              }, 0)}
            </div>
            <div className="balance-area">
              <a
                className="balance-btn default-btn outline small-btn"
                onClick={() => {
                  showModalDetail(record);
                }}
              >
                View
                {/* <FontAwesomeIcon icon={faEye} /> */}
              </a>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "event",
      key: "event",
      width: 100,
      render: (text, record) => {
        let status = record?.variants?.reduce((acc, item) => {
          return (acc = acc + item.inventory_quantity);
        }, 0);
        if (status >= 70) {
          return (
            <div className="status-info">
              <div className="status-flag green"></div>
              {/* <p>green</p> */}
            </div>
          );
        } else if (status >= 50 && status <= 70) {
          return (
            <div className="status-info">
              <div className="status-flag yellow"></div>
              {/* <p>yellow</p> */}
            </div>
          );
        } else if (status <= 50) {
          return (
            <div className="status-info">
              <div className="status-flag red"></div>
              {/* <p>red</p> */}
            </div>
          );
        } else {
          return null;
        }
      },
    },
    // {
    //   title: 'Action',
    //   key: 'action',
    //   fixed: 'right',
    //   width: 100,
    //   className: 'text-left',
    //   // sorter: true,
    //   render: (text, record, index) => {

    //     return (
    //       <Space size="middle" className="actions-list">
    //         {/* <DeleteFilled onClick={() => alert("Sure!")} /> */}
    //         <Switch
    //           disabled={isModalOpen}
    //           size="small"
    //           checked={record?.is_active}
    //           onChange={() => onToggle(record, index)}
    //         // checkedChildren="Fixed Top"
    //         // unCheckedChildren="Fixed Top"

    //         // onChange={() => {
    //         //   setFixedTop(!fixedTop);
    //         // }}
    //         />
    //       </Space>
    //     )
    //   },
    // }
  ];

  const onToggle = (record, recInd) => {
    if (record?.is_active == true) {
      record.is_active = false;
      let obj = record;
      const key = { ...obj };
      key.is_active = false;

      setSku((current) =>
        current.map((obj, index) => {
          if (index == recInd) {
            return { ...obj, is_active: false };
          }
          return obj;
        })
      );
    } else {
      record.is_active = true;
      let obj = record;
      const key = { ...obj };
      key.is_active = true;
      setSku((current) =>
        current.map((obj, index) => {
          if (index == recInd) {
            return { ...obj, is_active: true };
          }
          return obj;
        })
      );
    }

    // record.is_active = checked;
  };
  const handlePage = (link, menu) => {
    history(link, {
      state: { key: link.split("/").length > 2 ? link.split("/")[2] : link.split("/")[1], openKey: menu }
    }
    );
  }

  const showModalDetail = (s) => {
    setDataDetail(s);
    setIsModalOpenDetail(true);
  };

  const handleCancelDetail = () => {
    setIsModalOpenDetail(false);
  };

  const handleOkDetail = () => {
    setIsModalOpenDetail(false);
  };

  const columnDetails = [
    {
      title: "Size",
      key: "option1",
      dataIndex: ["option1"],
      width: 40,
      render: (text, record, index) => {
        return (
          <>
            <div className=""> {record?.option1 ? record?.option1 : "-"} </div>
          </>
        );
      },
    },

    {
      title: "Wash",
      key: "option1",
      dataIndex: ["option1"],
      width: 100,
      render: (text, record, index) => {
        return (
          <>
            <div className=""> {record?.option2 ? record?.option2 : "-"} </div>
          </>
        );
      },
    },

    {
      title: "Inseam",
      key: "option1",
      dataIndex: ["option1"],
      width: 70,
      render: (text, record, index) => {
        return (
          <>
            <div className="">{record?.option3 ? record?.option3 : "-"} </div>
          </>
        );
      },
    },

    {
      title: "Stock",
      dataIndex: "inventory_quantity",
      key: "inventory_quantity",
      width: 60,
    },
  ];
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const page = e.selected;
    setCurrentPage(0);
    setLoading(true);
    getInfluencerReview(
      "active",
      1,
      limit,
      moment(startDate).format("YYYY-MM-DD"),
      moment(endDate).format("YYYY-MM-DD"),
      influencerId,
      sortBy
    ).then(() => {
      setLoading(false);
      setInfluencer("");
    });
  };

  const handleReset = () => {
    setInfluencerId();
    // moment().startOf('year').format('YYYY-MM-DD');
    // setStartDate(moment().startOf("month").format("YYYY-MM-DD"));
    setStartDate(fromDate);
    // setEndDate(moment(new Date()).format("YYYY-MM-DD"));
    setEndDate(toDate);
    setLoading(true);
    setSortBy("date");
    childRef.current?.clear();
    getInfluencerReview(
      "active",
      1,
      limit,
      // moment().startOf('year').format('YYYY-MM-DD'),
      fromDate,
      // moment(new Date()).format("YYYY-MM-DD"),
      toDate,
      "",
      "date"
    ).then(() => {
      setLoading(false);
      setInfluencer("");
    });
  };

  const handleSortBy = (value) => {
    setSortBy(value);
  };

  const dateRangePickerChanger = (value, dataString) => {
    const startDate = dataString[0];
    const endDate = dataString[1];
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleCreator = (value, option) => {
    setInfluencerId(value);
  };
  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  const selectInfluencer = (value) => {
    setInfluencerId(value);
  }
  const getInfluencer = (influencerData, influencerUsername, insta_handle) => {
    setInfluencerId(influencerData);
    setUser(influencerData);
  };

  const onGenderChange = (value) => {
    setGender(value);
    setSubCategoryOptions([]);
    setCategoryOptions([]);
    setCategory("");
    setSubCategory("");
    form.setFieldsValue({ category_id: undefined, sub_category_id: undefined });
    const data = value === "all" ? "" : value;
    setGenLoading(true);
    dispatch(getSavedCategories(data)).then((res) => {
      if (res.success) {
        setCategoryOptions(res?.message);
        setGenLoading(false);
      } else {
        setGenLoading(false);
      }
    });
  };

  const onCategoryChange = (value) => {
    setSubCategory([]);
    setSubCategoryOptions([]);
    setSubCategory("");
    form.setFieldsValue({ sub_category_id: undefined });
    const data = value === "all" ? "" : value;
    dispatch(getSavedSubCategories(gender === "all" ? "" : gender, data)).then(
      (res) => {
        if (res.success) {
          setSubCategoryOptions(res?.message);
          // setCatLoad(false)
        } else {
          // setCatLoad(true)
        }
      }
    );
  };

  const onSubCategoryChange = (value) => {
    setSubCategory(value);
  };

  function ErrorFallback({ error, resetErrorBoundary }) {
    return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre>{error.message}</pre>
        <button onClick={resetErrorBoundary}>Try again</button>
      </div>
    );
  }

  return (
    <div className="container-fluid p-0">
      <div className="row w-20-desktop-main w-25-desktop-main">
        <div className="col-xl-3 col-md-6 col-12 mb-15">
          <p className="mb-5rem">Select Start Date / End Date</p>
          <RangePicker
            size="large"
            className="w-100"
            key={4}
            value={
              startDate && endDate ? [moment(startDate), moment(endDate)] : []
            }
            allowClear={false}
            ranges={{
              "All Time": [
                moment().subtract(1, "year").startOf("year"),
                moment().add(1, 'days'),
              ],
              "YTD": [moment().startOf("year"), moment().add(1, 'days')],
              "Last 30 Days": [moment().subtract(30, "days"), moment().add(1, 'days')],
              "Last 7 Days": [moment().subtract(7, "days"), moment().add(1, 'days')],
              "Today": [moment(), moment().add(1, 'days')],
              // Today: [moment(), moment()],
              // Tomorrow: [
              //   moment().add(1, 'days'),
              //   moment().add(1, 'days'),
              // ],
              // Yesterday: [
              //   moment().subtract(1, 'days'),
              //   moment().subtract(1, 'days'),
              // ],
              // "Last Year": [
              //   moment().subtract(1, "year").startOf("year"),
              //   moment().subtract(1, "year").endOf("year"),
              // ],
              // "This Year": [moment().startOf("year"), moment().endOf("year")],
              // "Last Month": [
              //   moment().subtract(1, "month").startOf("month"),
              //   moment().subtract(1, "month").endOf("month"),
              // ],
              // "This Month": [
              //   moment().startOf("month"),
              //   moment().endOf("month"),
              // ],
            }}
            format={dateFormat}
            onChange={dateRangePickerChanger}
          />
        </div>
        <div className="col-xl-3 col-md-6 col-12 mb-15">
          <p className="mb-5rem">Select Creator</p>
          {/* <AsyncInfluencer
            ref={childRef}
            getInfluencer={getInfluencer}
            influencer={influencer}
          /> */}
          {/* <div className="select-inf-field d-flex flow-row">
            <AsyncInfluencer
              ref={childRef}
              getInfluencer={getInfluencer}
              influencer={influencer}
            />
          </div> */}
          <Select
            showSearch
            name="sort"
            size="large"
            placeholder="Select Creator"
            className="w-100 select-style dark-field"
            optionFilterProp="children"
            value={influencerId}
            onChange={(value) => selectInfluencer(value)}
            // onSearch={onSearch}
            filterOption={filterOption}
            options={getAllInfluencers?.message && [
              ...getAllInfluencers?.message].map((influencer) => {
                return ({
                  label: influencer?.name + " - " + influencer?.pixel_id + " - " + influencer?.email,
                  value: influencer?.user_id
                }
                )
              })
            }
          />
        </div>
        {/* <div className="col-xl-3 col-md-6 col-12 mb-15">
          <p className="mb-5rem">Sort By</p>
          <Select
            className="w-100 select-style dark-field"
            placeholder="Select Sort By"
            size="large"
            // focus={false}
            // onBlur={false}
            onChange={handleSortBy}
            value={sortBy}
          >
            <Option value="date">Date</Option>
            <Option value="follower">Followers</Option>
            <Option value="views">Views</Option>
            <Option value="sales">Sales</Option>
            <Option value="likes">Likes</Option>
          </Select>
        </div> */}
        <div className="col-xl-3 col-md-6 col-12 d-flex align-items-end mb-15">
          <Button
            onClick={handleSubmit}
            className="default-btn fltr-hpr  medium-btn m-width-100 ml-0 fw-normal"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Search
          </Button>

          <Button
            onClick={handleReset}
            className="default-btn outline fw-normal fltr-hpr medium-btn  res-btn2 min-w-100"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Reset
          </Button>
        </div>
      </div>
      <hr className="separator-line mt-0" />
      {renderContent()}
      {getInfluencerVideo?.data?.length > 0 && !loading && (
        <ReactPaginate
          previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
          nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
          pageClassName="page-item "
          pageLinkClassName="page-link custom-paginate-link btn btn-primary"
          previousClassName="page-item"
          previousLinkClassName="page-link custom-paginate-prev btn btn-primary"
          nextClassName="page-item"
          nextLinkClassName="page-link custom-paginate-next btn btn-primary"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          forcePage={currentPage}
          pageCount={Math.ceil(getInfluencerVideo.total_count / limit)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
          onPageChange={handlePageClick}
          containerClassName={
            "pagination justify-content-center mt-2 custom-paginate review-paginate"
          }
          activeClassName={"active"}
        />
      )}
      {showPlayer && (
        <Modal
          className="modal-generic modal-1000"
          centered
          visible={showPlayer}
          // onOk={handleOk}
          onCancel={handleCancel}
          closeIcon={<FontAwesomeIcon icon={faXmark} />}
          footer={false}
          maskClosable={false}
          keyboard={false}
        >
          <h2 className="modal-hd1">Link Product</h2>
          {!savedGeneralCategories?.message?.length ? (
            <div class="no-cat-review">
              <div>
                <h4 className="mb-4">Setup Category To Link Product</h4>
                <div className="buttons-area">
                  <Button
                    onClick={() => handlePage("/account/categories", "settings")}
                    className="default-btn medium-btn fltr-hpr fw-normal"
                  >
                    Setup Category
                  </Button>
                </div>
              </div>
            </div>
          ) :
            getInventoryProductsBySkuAndTitle?.message?.length === 0 ?
              <div class="no-cat-review">
                <div>
                  <h4 className="mb-4">Activate Inventory To Link Product</h4>
                  <div className="buttons-area">
                    <Button
                      onClick={() => handlePage("/inventoryy/active", "inventory")}
                      className="default-btn medium-btn fltr-hpr fw-normal"
                    >
                      Manage Inventory
                    </Button>
                  </div>
                </div>
              </div>
              :
              !validate?.payload?.message?.salesforce && !validate?.payload?.message?.shopify && !validate?.payload?.message?.magento && !validate?.payload?.message?.scoc ?
                (
                  <div class="no-cat-review">
                    <div>
                      <h4 className="mb-4">Setup Ecommerce</h4>
                      <div className="buttons-area">
                        <Button
                          onClick={() => handlePage("/account/ecommerce", "settings")}
                          className="default-btn medium-btn fltr-hpr fw-normal"
                        >
                          Ecommerce Setup
                        </Button>
                      </div>
                    </div>
                  </div>
                )
                :
                (
                  <>
                    <div className="modal-scroll-data scrollbar-style">
                      <Form
                        // layout="vertical"
                        form={form}
                        onFinish={onFinish}
                        // onFinishFailed={onFinishFailed}
                        autoComplete="off"
                      >
                        <div class="row">
                          <div className="col-md-7 modal-review-right">
                            {/* <div className="form-styling">
                          <Form.Item
                            name="User"
                            labelCol={{ span: 24 }}
                            label="Creator"
                          >
                            <div className="select-inf-field d-flex flow-row">
                              <Select
                                showSearch
                                name="sort"
                                size="large"
                                placeholder="Select Creator"
                                className="w-100 select-style dark-field"
                                optionFilterProp="children"
                                value={user?._id ? user?._id : user}
                                onChange={(value) => setUser(value)}
                                // onSearch={onSearch}
                                filterOption={filterOption}
                                options={getAllInfluencers?.message && [
                                  ...getAllInfluencers?.message].map((influencer) => {
                                    return ({
                                      label: influencer?.name + " - " + influencer?.pixel_id + " - " + influencer?.email,
                                      value: influencer?.user_id
                                    }
                                    )
                                  })
                                }
                              />
                            </div>
                            {msg && (
                              <div
                                role="alert"
                                className="ant-form-item-explain-error"
                              >
                                Please Select Creator
                              </div>
                            )}
                          </Form.Item>
                        </div> */}
                            <div className="form-styling new-style">
                              <Form.Item
                                name="title"
                                labelCol={{ span: 24 }}
                                label="Video Title"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter video title",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Enter Video Title"
                                  // value={"test.myshopify.com"}
                                  // value="dl1961denim"
                                  size="large"
                                  // onChange={onChange}
                                  onChange={(e) => {
                                    setVideoTitle(e.target.value);
                                  }}
                                />
                              </Form.Item>
                            </div>
                            <div className="form-styling new-style">
                              {/* <Form.Item
                    labelCol={{ span: 24 }}
                    // initialValue={
                    //   formState === "add" ? undefined : form.getFieldValue().category_id
                    // }
                    // initialValue={form.getFieldValue().category_id}
                    label="Category"
                    name="category_id"
                    rules={[
                      {
                        required: true,
                        message: "Please select a category",
                      },
                    ]}
                  >
                    <Select
                      className="select-style dark-field"
                      placeholder="Select Category"
                      size="large"
                      focus={false}
                      onChange={handleCategory}
                      onBlur={false}
                      loading={parentCategory?.loading ? true : false}
                      disabled={parentCategory?.loading ? true : false}
                    >
                      {parentCategory?.payload?.message.map((item, i) => {
                        return (
                          <Option value={item.category_id}>
                            {item.category_name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item> */}
                              <Form.Item
                                labelCol={{ span: 24 }}
                                // initialValue={form.getFieldValue().gen_category_id}
                                label="Department"
                                name="gen_category_id"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select a department",
                                  },
                                ]}
                              >
                                <Select
                                  className="select-style dark-field"
                                  placeholder="Select Department"
                                  size="large"
                                  focus={false}
                                  onBlur={false}
                                  onChange={onGenderChange}
                                  value={gender}
                                  loading={genLoading}
                                  disabled={genLoading}
                                >
                                  {savedGeneralCategories?.message?.map((option) => {
                                    return (
                                      <Option value={option?.category_id}>
                                        {option?.name}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>

                              {categoryOptions?.length ? (
                                <Form.Item
                                  labelCol={{ span: 24 }}
                                  initialValue={"all"}
                                  // initialValue={form.getFieldValue().category_id}
                                  label="Category"
                                  name="category_id"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please select a category",
                                    },
                                  ]}
                                >
                                  <Select
                                    placeholder="Select Category"
                                    size="large"
                                    className="select-style dark-field"
                                    onChange={onCategoryChange}
                                    value={category ? category : undefined}
                                    defaultValue={"all"}
                                  >
                                    <Option value="all">All</Option>
                                    {categoryOptions?.map((option) => {
                                      return (
                                        <Option value={option?.category_id}>
                                          {option?.gen_category?.name} {" > "}
                                          {option?.category_name}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              ) : null}

                              {subCategoryOptions?.length ? (
                                <Form.Item
                                  labelCol={{ span: 24 }}
                                  initialValue={"all"}
                                  // initialValue={form.getFieldValue().sub_category_id}
                                  label="Sub Category"
                                  name="sub_category_id"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please select a sub category",
                                    },
                                  ]}
                                >
                                  <Select
                                    placeholder="Sub Category"
                                    size="large"
                                    className="select-style dark-field"
                                    onChange={onSubCategoryChange}
                                    value={subCategory ? subCategory : undefined}
                                    defaultValue={"all"}
                                  >
                                    <Option value="all">All</Option>
                                    {subCategoryOptions?.map((option) => {
                                      return (
                                        <Option value={option?.sub_category_id}>
                                          {option?.gen_category?.name} {" > "}
                                          {option?.category?.category_name} {" > "}
                                          {option?.sub_category_name}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              ) : null}
                            </div>
                            {/* <div className="form-styling new-style">
                            <Form.Item
                              name="datetime"
                              // initialValue={
                              //   formState === "add" ? null : form.getFieldValue().datetime
                              // }
                              rules={[
                                {
                                  required: true,
                                  message: "Please select date & time",
                                },
                              ]}
                              labelCol={{ span: 24 }}
                              label="Date and Time"
                            >
                              <DatePicker
                                style={{
                                  width: "100%",
                                }}
                                size="large"
                                // showTime={{ use12Hours: true, format: "HH:mm" }}
                                onChange={(e) => {
                                  onDateTimeChange(e);
                                  setFlag(true);
                                }}
                                //disabledDate={startDateLimit}
                                // format="MM/DD/YYYY h:mm A"
                                format="MM/DD/YYYY"
                              />
                            </Form.Item>
                          </div> */}
                            <div className="form-styling mb-2">
                              {!(defaultFile && defaulted) &&
                                < Form.Item
                                  name="banner"
                                  labelCol={{ span: 24 }}
                                  label="Upload Banner"
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                >
                                  <Form.Item
                                    name="banner1"
                                    labelCol={{ span: 24 }}
                                    label="Upload Banner"
                                    // valuePropName={formState !== 'edit' && 'fileList'}
                                    //initialValue={formState === "add" ? null : form.getFieldValue().banner}
                                    //isImageUrl={formState === "add" ? null : "https://cdn.pixabay.com/photo/2017/02/09/21/08/wings-2053515__340.png"}
                                    getValueFromEvent={normFile}
                                    noStyle
                                    multiple={false}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please upload banner image",
                                      },
                                    ]}
                                  >
                                    <Upload.Dragger
                                      customRequest={dummyRequest}
                                      className="upload-image-review"
                                      {...uploadProps}
                                      name="banner"
                                    // defaultFileList={
                                    //   formState === "edit" &&
                                    //   form.getFieldValue()?.banner === null
                                    //     ? null
                                    //     : [
                                    //         {
                                    //           url: form.getFieldValue()?.banner,
                                    //         },
                                    //       ]
                                    // }
                                    // defaultFileList={form.getFieldValue().banner ?
                                    //   [{ url: form.getFieldValue().banner }]
                                    //   :
                                    //   null
                                    // }
                                    // defaultFileList={
                                    //   defaultFile && defaulted ?
                                    //   [{ url: defaultFile }]
                                    //   :
                                    //   banner && banner?.length > 0 ? banner :  (form.getFieldValue().banner && form.getFieldValue().banner.length === 0 ? null : [{ url: form.getFieldValue().banner }])
                                    // }
                                    // fileList={
                                    //   defaultFile && defaulted
                                    //     ?
                                    //     [defaultFile]
                                    //     : banner && banner?.length > 0
                                    //       ? banner
                                    //       :
                                    //       (form.getFieldValue().banner && form.getFieldValue().banner.length === 0
                                    //         ? null
                                    //         : [{ url: form.getFieldValue().banner }])

                                    // }
                                    // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    >
                                      <p className="ant-upload-text">
                                        drag files here to upload
                                      </p>
                                    </Upload.Dragger>
                                  </Form.Item>
                                </Form.Item>}
                              {
                                (defaultFile && defaulted) &&
                                <Form.Item className="preview-image-main-link-product" name="banner" labelCol={{ span: 24 }}
                                  label="Upload Banner">
                                  <ImageA
                                    // width={100}
                                    // src="https://static.konnect.bio/eventdefaultbanner/banner.jpg"
                                    // src="https://static.konnect.bio/900000005/event/dddc020e-215a-410c-9fda-7d3ab8d61b54.png"
                                    src={defaultFile}
                                  />
                                </Form.Item>
                              }
                              <Checkbox
                                checked={defaulted}
                                onChange={reUse}>
                                Use default banner
                              </Checkbox>
                            </div>
                          </div>
                          <div className="col-md-5">
                            <div className="modal-video-review-box review-video mb-20">
                              <ReactPlayer
                                // className={`review_react_player ${videoData?.media_url?.split(".")[
                                //   videoData?.media_url?.split(".")?.length - 1
                                // ] === "m3u8" && "objectFit"
                                //   }`}
                                // className="review_react_player objectFit"
                                className={`review_react_player ${videoData?.orientation === "portrait" ? "" : "objectFit bg-cover"}`}
                                width={"100%"}
                                height={"100%"}
                                url={videoData?.media_url}
                                light={videoData?.banner}
                                controls
                                playsinline
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row mr-0">
                          {/* <div className="col-md-4 col-lg">
                        <label className="pb-0">Promo Code</label>
                        <Select
                          size="small"
                          filterOption={(input, options) =>
                            options.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          value={
                            productPromoCodePromo
                              ? productPromoCodePromo
                              : "Select PromoCode"
                          }
                          //disabled={!(formState === "add" || formState === "edit")}
                          placeholder="Select PromoCode"
                          //loading={this.state.promoCond}
                          optionFilterProp="children"
                          className="w-100 select-modal mb-20"
                          // onSearch={onSearch}
                          onChange={(options, e) => {
                            changePromoCode(e, options);
                            setFlag(true);
                          }}
                          showSearch
                          allowClear={false}
                          loading={promoRequest?.loading ? true : false}
                          disabled={promoRequest?.loading ? true : false}
                        >
                          {promoRequest?.message?.map((customer, key) => {
                            return (
                              <Option key={customer.promo_percent + " " + key}>
                                {customer.promo}
                              </Option>
                            );
                          })}
                        </Select>
                      </div>
                      <div className="col-md-4 col-lg">
                        <label>Discount</label>
                        <div className="promo_discount form-control mb-20">
                          {productPromoCodeDscs}
                        </div>
                      </div> */}

                          {/* <div className="col-md-4 col-lg">
                        <label>Referral Fee</label>
                        <Form.Item
                          labelCol={{ span: 24 }}
                          // label="Referral Fee"
                          className="mb-20 label-padb-0 w-100"
                          name="referral_percent"
                          initialValue={
                            getStructureFees?.payload?.message?.fee_structure
                              ?.referral_fee
                              ? getStructureFees?.payload?.message?.fee_structure
                                ?.referral_fee
                              : 0
                          }
                        >
                          <InputNumber
                            disabled
                            className="w-100 select-modal"
                            defaultValue={0}
                            size="large"
                            min={0}
                            max={100}
                            formatter={(value) => `${value}%`}
                            parser={(value) => value.replace("%", "")}
                            onChange={(e) => {
                              {
                                setReferralPercent(e);
                                setFlag(true);
                              }
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div className="col-md-4 col-lg">
                        <label>Cashback</label>
                        <Form.Item
                          labelCol={{ span: 24 }}
                          // label="Influencer Fee"
                          className="mb-20 label-padb-0"
                          // initialValue={
                          //   formState === "add"
                          //     ? null
                          //     : form.getFieldValue().influencer_percent
                          // }
                          disabled={true}
                          initialValue={
                            getStructureFees?.payload?.message?.fee_structure
                              ?.shopper_cashback
                              ? getStructureFees?.payload?.message?.fee_structure
                                ?.shopper_cashback
                              : 0
                          }
                          name="influencer_percent"
                        >
                          <InputNumber
                            disabled={true}
                            className="w-100 select-modal"
                            defaultValue={0}
                            size="large"
                            min={0}
                            max={100}
                            formatter={(value) => `${value}%`}
                            parser={(value) => value.replace("%", "")}
                            onChange={(e) => {
                              {
                                setInfluencer_percent(e);
                                setFlag(true);
                              }
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div className="col-md-4 col-lg">
                        <label>Creator Fee</label>
                        <Form.Item
                          labelCol={{ span: 24 }}
                          // label="Influencer Fee"
                          className="mb-20 label-padb-0"
                          // initialValue={
                          //   formState === "add"
                          //     ? null
                          //     : form.getFieldValue().influencer_percent
                          // }
                          disabled={true}
                          initialValue={
                            getStructureFees?.payload?.message?.fee_structure
                              ?.content_creator_fee
                              ? getStructureFees?.payload?.message?.fee_structure
                                ?.content_creator_fee
                              : 0
                          }
                          name="Creator_fee"
                        >
                          <InputNumber
                            disabled={true}
                            className="w-100 select-modal"
                            defaultValue={0}
                            size="large"
                            min={0}
                            max={100}
                            formatter={(value) => `${value}%`}
                            parser={(value) => value.replace("%", "")}
                            onChange={(e) => {
                              {
                                setCreator_fee(e);
                                setFlag(true);
                              }
                            }}
                          />
                        </Form.Item>
                      </div> */}
                        </div>
                        {
                          sku?.length > 0 &&
                          <div class="row">
                            <div className="col-md-7 modal-review-right">
                              <div className="form-styling disable-style new-style">
                                <Form.Item
                                  name="pricepoint"
                                  labelCol={{ span: 24 }}
                                  label="Price Point"
                                >
                                  <Input
                                    value={matchingPrice[0]?.name || ''}
                                    disabled={true}
                                    size="large"
                                    onChange={(e) => {
                                      // Handle input changes if needed
                                      // e.target.value is the new input value
                                    }}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          </div>
                        }
                        <div class="row">
                          <div className="col-12">
                            <div className="form-styling">
                              <Form.Item name="productsku">
                                <Form.Item
                                  name="productsku"
                                  label="Product SKU"
                                  labelCol={{ span: 24 }}
                                  className="d-flex flex-column sku-field pos-index mb-0"
                                // rules={[
                                //   {
                                //     required: true,
                                //     // message: 'Please Select Product Sku',
                                //   },
                                //   {
                                //     validator: async (_) => {
                                //       if (sku.length === 0) {
                                //         return Promise.reject(
                                //           new Error('Please Select Product SKUcccccccc')
                                //         );
                                //       } else {
                                //         return Promise.resolve();
                                //       }
                                //     },
                                //   },
                                // ]}
                                >
                                  <AsyncProduct
                                    getSku={getSku}
                                    sku={sku}
                                  // referralPercent={referralPercent}
                                  // influencer_percent={influencer_percent}
                                  />
                                  {skuError && (
                                    <div
                                      role="alert"
                                      className="ant-form-item-explain-error"
                                    >
                                      Please Select Product
                                    </div>
                                  )}
                                </Form.Item>
                              </Form.Item>
                              <div className="row d-flex mb-20 product-listing-table-main">
                                <div className="col">
                                  {sku?.length > 0 && (
                                    <Table
                                      pagination={false}
                                      columns={columns}
                                      size="small"
                                      className="product-listing-table"
                                      //  rowSelection={{ ...rowSelection }}
                                      dataSource={[...sku]}
                                      scroll={
                                        sku.length > 4
                                          ? { y: 290, x: 800 }
                                          : { x: 800 }
                                      }
                                    // scroll={
                                    //   sku.length > 4
                                    //     ? { y: 270, x: window.innerWidth <= 1440 ? 900 : 0 }
                                    //     : false
                                    // }
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="d-flex justify-content-md-end">
                              <Button
                                className="default-btn fw-normal d-flex align-items-center justify-content-center ml-0"
                                type="primary"
                                size="large"
                                htmlType="submit"
                                loading={saveLoading}
                              >
                                Save
                              </Button>
                              <Button
                                key="back"
                                className="default-btn  fw-normal outline d-flex align-items-center justify-content-center mr-0"
                                onClick={handleCancel}
                              >
                                Exit
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>

                  </>
                )}
        </Modal>
      )
      }
      <Modal
        maskClosable={false}
        className="modal-generic modal-600"
        centered
        visible={isModalOpenDetail}
        onOk={handleOkDetail}
        onCancel={handleCancelDetail}
        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        // footer={false}
        footer={[
          <Button
            key="back"
            onClick={handleCancelDetail}
            className="d-table m-auto"
          >
            Exit
          </Button>,
          // <Button key="submit" type="primary"
          //   className="d-table m-auto"
          //   // onClick={handleOk}
          //   // onClick={handleCancel}
          // >
          //   Submit
          // </Button>,
        ]}
      >
        <h2 className="modal-hd1">Details</h2>
        <div className="co">
          {/* <Table
            pagination={false}
            columns={columnDetails}
            size="small"
            className="product-listing-tabl inventory-box-main details-table"
            //  rowSelection={{ ...rowSelection }}
            dataSource={dataDetails}
            // scroll={{
            //     x: 500,
            //     y: 500,
            // }}
            scroll={dataDetails?.length > 10 ? { y: 400, x: 500 } : { x: 500 }}
          /> */}
          <div className="table-container inventory-table">
            <table
              class={`table m-0 ${dataDetails?.productOpt?.length &&
                dataDetails?.productOpt?.length + 2 == 5
                ? "width-item-5"
                : ""
                } ${dataDetails?.productOpt?.length &&
                  dataDetails?.productOpt?.length + 2 == 6
                  ? "width-item-6"
                  : ""
                }`}
            >
              <thead>
                <tr>
                  {dataDetails?.productOpt?.map((item, i) => {
                    return (
                      <th className="width-25 border-bottom-0">{item.name}</th>
                    );
                  })}
                  <th className="width-25 border-bottom-0">Stock</th>
                  <th className="width-25 border-bottom-0">Price</th>
                </tr>
              </thead>
            </table>
            <div className="table-body scrollbar-style">
              <table
                class={`table ${dataDetails?.productOpt?.length &&
                  dataDetails?.productOpt?.length + 2 == 5
                  ? "width-item-5"
                  : ""
                  } ${dataDetails?.productOpt?.length &&
                    dataDetails?.productOpt?.length + 2 == 6
                    ? "width-item-6"
                    : ""
                  }`}
              >
                <tbody>
                  {dataDetails?.variants?.map((item, i) => {
                    return (
                      <tr>
                        <td className="width-25">{item.option1}</td>
                        {item.option2 && (
                          <td className="width-25">{item.option2}</td>
                        )}
                        {item.option3 && (
                          <td className="width-25">{item.option3}</td>
                        )}
                        <td className="width-25">{item.inventory_quantity}</td>
                        <td className="width-25">${item.price}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Modal>

      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ReactRouterPrompt
          // BlockerFunction={(currentLocation, nextLocation, historyAction)}
          when={
            // showPlayer && (form.getFieldValue().title ||
            // form.getFieldValue().notes ||
            // form.getFieldValue().datetime) && promptModal
            (videoTitle ||
              form.getFieldValue().gen_category_id ||
              // form.getFieldValue().datetime ||
              sku.length !== 0 ||
              form.getFieldValue().banner?.length === 0) && true
          }
        >
          {({ isActive, onConfirm, onCancel }) =>
            isActive && (
              <Modal
                zIndex={999999999}
                maskClosable={false}
                className="modal-generic modal-500"
                centered
                visible={isActive}
                onOk={() => {
                  onConfirm();
                }}
                onCancel={onCancel}
                closeIcon={<FontAwesomeIcon icon={faXmark} />}
                footer={[
                  <Button type="primary" onClick={onCancel}>
                    Cancel
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    // onClick={handleOk}
                    onClick={() => {
                      onConfirm();
                    }}
                  >
                    Ok
                  </Button>,
                ]}
              >
                <h2 className="modal-hd1">Warning</h2>
                <div className="my-4">
                  <h5>By leaving this page you will lose all your changes.</h5>
                  <h5>Would you like to continue?</h5>
                </div>
              </Modal>
            )
          }
        </ReactRouterPrompt>
      </ErrorBoundary>

      <Modal
        zIndex={999999999}
        maskClosable={false}
        className="modal-generic modal-500"
        centered
        visible={promptModal}
        onOk={() => {
          handleCancel("done");
        }}
        onCancel={() => setPromptModal(false)}
        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        footer={[
          <Button type="primary" onClick={() => setPromptModal(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            // onClick={handleOk}
            onClick={() => {
              handleCancel("done");
            }}
          >
            Ok
          </Button>,
        ]}
      >
        <h2 className="modal-hd1">Warning</h2>
        <div className="my-4">
          <h5>By leaving this page you will lose all your changes.</h5>
          <h5>Would you like to continue?</h5>
        </div>
      </Modal>

    </div >
  );
}
function mapStateProps({ getInfluencerVideo }) {
  return { getInfluencerVideo };
}
export default connect(mapStateProps, {
  ...influencerReviewAction,
})(LinkProducts);