import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { Modal } from "react-bootstrap";
import { Button, Form, Input, InputNumber, Modal as Modald, Tag, notification } from "antd";
import { DatePicker, Spin, Select, Empty } from "antd";
import {
  customerorderHistory,
  details,
  getPurchaser,
  getReferrer,
} from "../../redux/actions/purchaseHistory.action";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import AsyncSelect from "react-select/async";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight, faXmark } from "@fortawesome/free-solid-svg-icons";
import "../../css/sales.scss";
import moment from "moment";
import numeral from "numeral";
import { getSavedCategories, getSavedGeneralCategories, getSavedSubCategories } from "../../redux/actions/newCategory.action";
import { searchEventID, searchReviewID, searchShowID } from "../../redux/actions/eventID.action";
import { bannedUsers } from "../../redux/actions/banUserList";
import { unBan } from "../../redux/actions/chat";
const { Option } = Select;
let arrHost = [];
const { RangePicker } = DatePicker;

export default () => {
  const [form] = Form.useForm();
  const dateFormat = "MM-DD-YYYY";
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const min_date = moment().startOf("year").format("YYYY-MM-DD");
  const max_date = moment(new Date()).format("YYYY-MM-DD");

  const [startDate, setStartDate] = useState(min_date);
  const [endDate, setEndDate] = useState(max_date);
  const [totalEvent, setTotalEvent] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [source, setSource] = useState("all");
  const [allCustomer, setAll] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [modal, setModal] = useState(false);
  const [flag, setFlag] = useState(false);
  const [status, setStatus] = useState(false);
  const [submit, setSubmit] = useState("none");
  const [customerId, setCustomerId] = useState();
  const [gender, setGender] = useState();
  const [category, setCategory] = useState();
  const [subCategory, setSubCategory] = useState();
  const [load, setLoad] = useState(false);
  const [generalLoad, setGeneralLoad] = useState(false);
  const [catLoad, setCatLoad] = useState(false);
  const [loadd, setLoadd] = useState(true);
  const [subCatLoad, setSubCatLoad] = useState(false);
  const [order, setOrder] = useState();
  const [purchaseBy, setPurchaseBy] = useState();
  const [referredBy, setReferredBy] = useState();
  const [searchPurchaseBy, setSearchPurchaseBy] = useState([]);
  const [searchReferredBy, setsearchReferredBy] = useState([]);
  const [banType, setBanType] = useState("chatban");
  const [banUser, setBanUser] = useState();
  const [searchBanUser, setSearchBanUser] = useState();
  const [searchBanUserData, setSearchBanUserData] = useState([]);
  const [searchAllBanUserData, setSearchAllBanUserData] = useState([]);
  const [groupByTable, setGroupByTable] = useState("chatban");
  const [purchaseLoad, setPurchaseLoad] = useState(false);
  const [referrerLoad, setReferrerLoad] = useState(false);
  const [searchID, setSearchID] = useState();
  const [searchIDLoad, setSearchIDLoad] = useState("");
  const [searchDataIDs, setSearchDataIDs] = useState([]);
  const [idType, setIdType] = useState("event");
  const [banInfo, setBanInfo] = useState({});
  const [banModal, setBanModal] = useState(false);
  const { shopifyCustomerorder, detailsOrder, savedGeneralCategories, savedCategory, savedSubCategory, bannedUsersLists } = useSelector(
    (state) => {
      return state;
    }
  );
  const limit = 15;
  const upperLimit = (currentPage + 1) * limit;
  const lowerLimit = upperLimit - limit + 1;
  const itemsPerPage = 15;
  const pageCount = Math.ceil(totalEvent / itemsPerPage);
  const getSum = (a1, a2) => parseFloat(a1) * parseFloat(a2);

  useEffect(() => {
    // setSearchIDLoad(true)
    // dispatch(searchEventID("", "event", startDate, endDate)).then((res) => {
    //   setSearchIDLoad(false)
    //   if (res?.success) {
    //     setSearchDataIDs(res?.message)
    //   }
    // })
    // setLoading(true);
    // setGeneralLoad(true)
    // dispatch(getSavedGeneralCategories()).then((res) => {
    //   if (res.success) {
    //     setGeneralLoad(false)
    //   } else {
    //     setGeneralLoad(false)
    //   }
    // })

    dispatch(bannedUsers(banType)).then((res) => {
      setLoading(false);
      setSearchBanUserData(res)
      setSearchAllBanUserData(res)
      // 
    })

    // dispatch(
    //   customerorderHistory(1, limit, startDate, endDate, gender, category, subCategory, source, "", "", "", "", "Order")
    // ).then((res) => {
    //   setTotalEvent(res?.message?.total_records);
    //   setLoading(false);
    // });
  }, []);

  const dateRangePickerChanger = (value, dataString) => {
    const startDate = dataString[0];
    const endDate = dataString[1];
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handlePageClick = (e) => {
    const page = e.selected;
    setCurrentPage(page);
    setLoading(true);
    dispatch(
      customerorderHistory(
        page + 1,
        limit,
        startDate,
        endDate,
        gender,
        category,
        subCategory,
        source,
        customer[0]?.value,
        order,
        referredBy,
        purchaseBy,
        searchID,
        idType
      )
    ).then((res) => {
      setLoading(false);
      setTotalEvent(res?.payload?.message?.total_records);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setGroupByTable(banType)
    setStatus(true);
    dispatch(bannedUsers(banType, searchBanUser)).then((res) => {
      setLoading(false);
      setTotalEvent(res?.message?.total_records);
      setCurrentPage(0);
      setSearchBanUserData(res)
    });
    // setSubmit(groupBy);
  };

  const detailView = (orderDetail) => {
    setModal(true);
    setLoad(true);
    dispatch(details(orderDetail?.sales_tracker_id, orderDetail?._id)).then(
      (res) => {
        setLoad(false);
      }
    );
  };

  const handleReset = (res) => {
    setLoading(true);
    setBanType("chatban")
    setGroupByTable("chatban")
    setSearchBanUser()
    const min_date = moment().startOf("year").format("YYYY-MM-DD");
    const max_date = moment(new Date()).format("YYYY-MM-DD");
    setStartDate(min_date);
    setEndDate(max_date);
    dispatch(bannedUsers("chatban")).then((res) => {
      setLoading(false);
      setTotalEvent(res?.message?.total_records);
      setSearchBanUserData(res)
    });
  };

  const loadOptions = async (input, callback) => {
    await smartSearchFilter(input);

    const result = arrHost.filter((item) => {
      return {
        value: `${item.value}`,
        label: `${item.label}`,
      };
    });

    callback(result);
  };

  const smartSearchFilter = async (value) => {
    if (value.length > 0 && value.trim()) {
      await axios
        .post(`brand/reports/getCustomers`, {
          name: value.trim(),
        })
        .then((response) => {
          const loadHost = [];
          const host = response.data.data;
          if (host.length === 0) {
            // notification.error({
            //   message: "No Host Found",
            //   className: "toast-error",
            // });
          }
          setAll(host);
          host.map((item) => {
            return loadHost.push({
              value: item?._id,
              label: item?.first_name + " " + item?.last_name,
            });
          });
          arrHost = loadHost;
        })
        .catch(function (error) {
          // console.log(error);
        });
    }
  };

  useEffect(() => {
    document.body.classList.add("bioshop-body", "shopper-bioshop-body");
  }, []);

  const handleGender = (e) => {
    setCurrentPage(0);
    setGender(e);
    setCategory(null)
    setSubCategory(null)
    setCatLoad(true)
    dispatch(getSavedCategories(e)).then((res) => {
      if (res.success) {
        setCatLoad(false)
      } else {
        setCatLoad(false)
      }
    })

  };

  const handleCategory = (e) => {
    setCurrentPage(0);
    setCategory(e);
    setSubCategory(null)
    setSubCatLoad(true)
    dispatch(getSavedSubCategories(gender, e)).then((res) => {
      if (res.success) {
        setSubCatLoad(false)
      } else {
        setSubCatLoad(false)
      }
    })
  };

  const handleSubCategory = (e) => {
    setCurrentPage(0);
    setSubCategory(e);
  };

  const handleSource = (e) => {
    setSource(e);
  };

  const handleFilterPurchaseBy = (e) => {
    setPurchaseLoad(true)
    dispatch(getPurchaser(e)).then((res) => {
      setPurchaseLoad(false)
      if (res?.success) {
        setSearchPurchaseBy(res?.message)
      }
    })

  }

  const unban = () => {
    dispatch(unBan(banInfo)).then((res) => {
      if (res.success) {
        notification.success({
          message: res?.message,
          className: "toast-success",
        });
        setLoadd(true)
        setBanModal(false);
        dispatch(bannedUsers(banType)).then((res) => {
          setLoadd(false)
          setSearchBanUserData(res)
        })
      }
      else {
        notification.error({
          message: "User not found in ban list",
          className: "toast-error",
        });
      }
    })
  }

  const handleIDType = (e) => {
    setIdType(e)
    setSearchDataIDs([])
    setSearchID(null)

    switch (e) {
      case "event":
        return dispatch(searchEventID("", "event", startDate, endDate)).then((res) => {
          setSearchIDLoad(false)
          if (res?.success) {

            setSearchDataIDs(res?.message)
          }
        })
      case "show":
        return dispatch(searchShowID("", "show", startDate, endDate)).then((res) => {
          setSearchIDLoad(false)
          if (res?.success) {

            setSearchDataIDs(res?.message)
          }
        })
      case "review":
        return dispatch(searchReviewID("", "review", startDate, endDate)).then((res) => {
          setSearchIDLoad(false)
          if (res?.success) {

            setSearchDataIDs(res?.message)
          }
        })
    }
  }

  const handleReviewID = (e) => {
    setSearchIDLoad(true)
    switch (idType) {
      case "event":
        return dispatch(searchEventID(e.replaceAll("-", ''), "event", startDate, endDate)).then((res) => {
          setSearchIDLoad(false)
          if (res?.success) {

            setSearchDataIDs(res?.message)
          }
        })
      case "show":
        return dispatch(searchShowID(e.replaceAll("-", ''), "show", startDate, endDate)).then((res) => {
          setSearchIDLoad(false)
          if (res?.success) {

            setSearchDataIDs(res?.message)
          }
        })
      case "review":
        return dispatch(searchReviewID(e.replaceAll("-", ''), "review", startDate, endDate)).then((res) => {
          setSearchIDLoad(false)
          if (res?.success) {

            setSearchDataIDs(res?.message)
          }
        })
    }
  }

  const handleFilterReferredBy = (e) => {
    setReferrerLoad(true)
    dispatch(getReferrer(e)).then((res) => {
      setReferrerLoad(false)
      if (res?.success) {
        setsearchReferredBy(res?.message)
      }
    })

  }
  // This is componentWillUnmount
  useEffect(() => {
    return () => {
      //  componentWillUnmount.current = true;
      document.body.classList.remove("bioshop-body", "shopper-bioshop-body");
    };
  }, []);
  function DefaultTable() {
    // let data = shopifyCustomerorder?.payload?.message?.data;
    if (searchBanUserData?.data) {
      switch (groupByTable) {
        case "chatban":
          return (<>
            <div className="table-responsive scrollbar-style">
              <table className="transactions-box table">
                <thead className="table_heading">
                  <tr>
                    <th className="td-width-60">S.#</th>
                    <th className="td-width-120">User Name</th>
                    <th className="td-width-200">Event Name</th>
                    <th className="td-width-100">Ban Reason</th>
                    <th className="td-width-100">Ban Date</th>
                    <th className="td-width-100">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {searchBanUserData?.data?.length === 0 ? (
                    <tr>
                      <td colspan="12">
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </td>
                    </tr>
                  ) : (
                    searchBanUserData?.data?.map((item, i) => {
                      return (
                        <tr>
                          <td>{i + 1 + currentPage * 15}</td>
                          <td>
                            {/* {item?.user?.name} */}
                            {item?.user_name}
                          </td>
                          <td>
                            {item?.review_id && item?.review_title}
                            {item?.event_id && item?.event_title}
                            {/* {item?.review && item?.review?.title}
                            {item?.event && item?.event?.title} */}
                          </td>
                          <td>
                            {item?.reason ? item?.reason : "-"}
                          </td>
                          <td>
                            {item?.created_date ? item?.created_date : "-"}
                          </td>
                          <td className="">
                            <button type="button" onClick={() => { setBanModal(true); item?.event?._id ? setBanInfo({ event_id: item?.event_id, user_id: item?.user_id, ban_type: banType }) : setBanInfo({ review_id: item?.review_id, user_id: item?.user_id, ban_type: banType }) }} class="ant-btn ant-btn-primary default-btn padb-3 small-btn m-width-100 ml-0 mt-1 mb-1 fw-normal h-30"><span>Un Ban</span></button>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </>
          );
        case "permaban":
          return (<>
            <div className="table-responsive scrollbar-style">
              <table className="transactions-box table">
                <thead className="table_heading">
                  <tr>
                    <th className="td-width-60">S.#</th>
                    <th className="td-width-120">User Name</th>
                    <th className="td-width-100">Ban Reason</th>
                    <th className="td-width-100">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {searchBanUserData?.data?.length === 0 ? (
                    <tr>
                      <td colspan="12">
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </td>
                    </tr>
                  ) : (
                    searchBanUserData?.data?.map((item, i) => {
                      return (
                        <tr>
                          <td>{i + 1 + currentPage * 15}</td>
                          <td>
                            {item?.name ? item?.name : "-"}
                          </td>
                          <td>
                            {item?.ban_reason ? item?.ban_reason : "-"}
                          </td>
                          <td className="">
                            <button type="button" onClick={() => { setBanModal(true); setBanInfo({ user_id: item?._id, ban_type: banType }) }} class="ant-btn ant-btn-primary default-btn padb-3 small-btn m-width-100 ml-0 mt-1 mb-1 fw-normal h-30"><span>Un Ban</span></button>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </>
          );
        default:
          return (<>
            <div className="table-responsive scrollbar-style">
              <table className="transactions-box table">
                <thead className="table_heading">
                  <tr>
                    <th className="td-width-60">S.#</th>
                    <th className="td-width-120">User Name</th>
                    <th className="td-width-200">Event Name</th>
                    <th className="td-width-100">Ban Reason</th>
                    <th className="td-width-100">Ban Date</th>
                    <th className="td-width-100">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {searchBanUserData?.data?.length === 0 ? (
                    <tr>
                      <td colspan="12">
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </td>
                    </tr>
                  ) : (
                    searchBanUserData?.data?.map((item, i) => {
                      return (
                        <tr>
                          <td>{i + 1 + currentPage * 15}</td>
                          <td>
                            {item?.user?.name}
                          </td>
                          <td>
                            {item?.review && item?.review?.title}
                            {item?.event && item?.event?.title}
                          </td>
                          <td>
                            {item?.reason ? item?.reason : "-"}
                          </td>
                          <td>
                            {item?.created_date ? item?.created_date : "-"}
                          </td>
                          <td className="">
                            <button type="button" onClick={() => { setBanModal(true); item?.event?._id ? setBanInfo({ event_id: item?.event_id, user_id: item?.user_id, ban_type: banType }) : setBanInfo({ review_id: item?.review_id, user_id: item?.user_id, ban_type: banType }) }} class="ant-btn ant-btn-primary default-btn padb-3 small-btn m-width-100 ml-0 mt-1 mb-1 fw-normal h-30"><span>Un Ban</span></button>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </>
          );
      }
    }
  }

  return (
    <>
      <div className="sales-page-main">
        {/* <div class="title-area mb-10">
          <h1>Orders</h1>
        </div> */}
        <div className="brand_container_main aff-payment">
          <Form form={form}>
            <div className="row w-20-desktop-main">
              {/* <div className="col-xl-3 col-md-6 col-12 mb-15">
                <p className="mb-5rem">Select Start Date / End Date</p>
                <RangePicker
                  size="large"
                  className="w-100"
                  key={4}
                  value={
                    startDate && endDate
                      ? [moment(startDate), moment(endDate)]
                      : []
                  }
                  allowClear={false}
                  ranges={{
                    "Last Year": [
                      moment().subtract(1, "year").startOf("year"),
                      moment().subtract(1, "year").endOf("year"),
                    ],
                    "This Year": [moment().startOf("year"), moment()],
                    "Last Month": [
                      moment().subtract(1, "month").startOf("month"),
                      moment().subtract(1, "month").endOf("month"),
                    ],
                    "This Month": [
                      moment().startOf("month"),
                      moment().endOf("month"),
                    ],
                    Today: [moment(), moment()],
                  }}
                  format={dateFormat}
                  onChange={dateRangePickerChanger}
                />
              </div> */}

              <div className="col-xl-3 col-md-6 col-12 mb-15">
                <p className="mb-5rem">Select User</p>
                <Select
                  showSearch
                  className="w-100 select-style dark-field"
                  placeholder="Enter User Name"
                  key={4}
                  size="large"
                  focus={false}
                  // loading={purchaseLoad}
                  // disabled={purchaseLoad}
                  onBlur={false}
                  // notFoundContent={purchaseLoad && <Spin size="small" />}
                  value={searchBanUser}
                  onChange={(e) => setSearchBanUser(e)}
                  // onSearch={handleFilterBanUser}
                  filterOption={(input, options) =>
                    options?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                // defaultValue="date"
                >
                  {/* <Option value="chatban">Events/Reviews Ban</Option>
                  <Option value="permaban">Global Ban</Option> */}
                  {searchAllBanUserData?.data && !loading &&
                    searchAllBanUserData?.data?.map((item, i) => {
                      return (
                        <Option value={item?.user_id}>
                          {item?.user_email ? item?.user_name + " (" + item?.user_email + ")" : item?.user_name}
                        </Option>
                      );
                    })}
                </Select>
              </div>




              <div className="col-xl-3 col-md-6 col-12 d-flex align-items-end mb-15">
                <Button
                  onClick={handleSubmit}
                  className="default-btn fltr-hpr  medium-btn m-width-100 ml-0 fw-normal"
                  type="primary"
                  htmlType="submit"
                >
                  Search
                </Button>
                <Button
                  onClick={handleReset}
                  className="default-btn outline fw-normal fltr-hpr medium-btn  res-btn2 min-w-100"
                  type="primary"
                  htmlType="submit"
                >
                  Reset
                </Button>
              </div>
            </div>
          </Form>
          {/* <hr className="separator-line mt-0" /> */}

          <div className="purchase-data referral-data">
            {loading ? (
              // <div className="antd-loading-ift">
              // 	<Spin size="large" />
              // </div>
              <div className="loading-wrap">
                <span className="spinclass loader-center position-relative">
                  <Spin size="large" />
                </span>
              </div>
            ) : (
              <>
                {<DefaultTable />}
                {/* {submit === "date" && <DateGroupTable />} */}
                {/* {submit === "category" && <CategoryGroupTable />}
                {submit === "brand" && <BrandGroupTable />} */}
                <div className="purchase-data referral-data">
                  {/* {searchBanUserData?.data?.length ? (
                    <ReactPaginate
                      nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
                      pageCount={Math.ceil(searchBanUserData?.total_count / 15)}
                      forcePage={currentPage}
                      previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                      pageClassName="page-item d-none"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      breakClassName="page-item d-none"
                      breakLinkClassName="page-link"
                      containerClassName={"pagination custom-paginate"}
                      activeClassName="active"
                      renderOnZeroPageCount={null}
                    />
                  ) : (
                    ""
                  )} */}
                </div>

              </>
            )}
          </div>
        </div>
      </div>

      <Modal
        dialogClassName="code-activated-popup"
        size="lg"
        scrollable
        show={modal}
        onHide={() => setModal(false)}
        centered
        backdrop="static"
      >
        {/* <Modal.Header closeButton></Modal.Header> */}
        <Modal.Header className="modal-header-style2" closeButton>
          <Modal.Title className="mb-0">Order Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {load ? (
            <span className="mt-2 mr-2 loader-center position-relative">
              <Spin size="large" />
            </span>
            // <Spin
            //   style={{
            //     display: "flex",
            //     justifyContent: "center",
            //   }}
            // />
          ) : (
            <>
              <div className="order-detail-title-area">
                <div className="d-flex flex-column">
                  <div className="order-num">
                    Order #
                    <div className="order-name">
                      {
                        detailsOrder?.payload?.message?.order_detail
                          ?.order_number
                      }
                    </div>
                  </div>
                  <duv className="order-num order-name-main">
                    Customer Name:
                    <div className="order-name">
                      {detailsOrder?.payload?.message?.order_detail?.customer
                        ?.first_name +
                        " " +
                        detailsOrder?.payload?.message?.order_detail?.customer
                          ?.last_name}
                    </div>
                  </duv>
                </div>

                <span className="order-date">
                  {moment
                    .utc(
                      detailsOrder?.payload?.message?.order_detail?.created_at
                    )
                    .format("MMMM Do YYYY [from Online Store]")}
                </span>
              </div>
              <Table responsive size="sm" className="transactions-box">
                <thead className="table_heading">
                  <tr>
                    <th className="td-width-60">S.#</th>
                    <th className="td-width-120">Order Date</th>
                    <th className="td-width-100">SKU #</th>
                    <th className="td-width-200">Description</th>
                    <th className="td-width-80">Qty</th>
                    <th className="td-width-100 text-right">Price</th>
                    <th className="td-width-100 text-right">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {!detailsOrder?.loading &&
                    detailsOrder?.payload?.message?.order_detail?.line_items?.map(
                      (item, key) => {
                        return (
                          <tr key={key}>
                            <td>{key + 1}</td>
                            <td>
                              {moment
                                .utc(order?.created_at)
                                .format("DD-MM-YYYY")}
                            </td>
                            <td>{item?.sku}</td>
                            <td>{item?.title}</td>
                            <td>{item?.quantity}</td>
                            <td className="text-right">{numeral(item?.price).format("$0,0.0'")}</td>
                            <td className="text-right">
                              {numeral(
                                getSum(item?.quantity, item?.price)
                              ).format("$0,0.0'")}
                            </td>
                          </tr>
                        );
                      }
                    )}
                </tbody>
              </Table>
              <div className="order-sum">
                <div className="paid-mid-row">
                  <div className="left-txt multi-content">
                    <div className="left-hd">Subtotal</div>
                    <div className="left-info">
                      {
                        detailsOrder?.payload?.message?.order_detail?.line_items
                          ?.length
                      }{" "}
                      item
                    </div>
                  </div>
                  <div className="right-txt">
                    {numeral(
                      detailsOrder?.payload?.message?.order_detail
                        ?.total_line_items_price
                    ).format("$0,0.0'")}
                  </div>
                </div>

                <div className="paid-mid-row">
                  <div className="left-txt multi-content">
                    <div className="left-hd">Discount</div>
                    {detailsOrder?.payload?.message?.order_detail
                      ?.total_discounts
                      ? numeral(
                        detailsOrder?.payload?.message?.order_detail
                          ?.total_discounts /
                        detailsOrder?.payload?.message?.order_detail
                          ?.total_line_items_price
                      ).format("0%")
                      : ""}
                  </div>
                  {detailsOrder?.payload?.message?.order_detail
                    ?.total_discounts ? (
                    <>
                      <div className="right-txt">
                        -
                        {numeral(
                          detailsOrder?.payload?.message?.order_detail
                            ?.total_discounts
                        ).format("$0,0.0'")}
                      </div>
                    </>
                  ) : (
                    <div className="right-txt">
                      {numeral(0).format("$0,0.0'")}
                    </div>
                  )}
                </div>

                <div className="paid-mid-row">
                  <div className="left-txt multi-content">
                    <div className="left-hd">Shipping</div>
                  </div>
                  <div className="right-txt">
                    {numeral(
                      detailsOrder?.payload?.message?.order_detail
                        ?.total_shipping_price_set?.presentment_money?.amount
                    ).format("$0,0.0'")}
                  </div>
                </div>

                <div className="paid-mid-row">
                  <div className="left-txt multi-content">
                    <div className="left-hd">Tax</div>
                  </div>
                  <div className="right-txt">
                    {numeral(
                      detailsOrder?.payload?.message?.order_detail?.total_tax
                    ).format("$0,0.0'")}
                  </div>
                </div>
                <div className="paid-mid-row">
                  <div className="left-txt multi-content">
                    <div className="left-hd fw-bold">Total Amount</div>
                  </div>
                  <div className="right-txt fw-bold">
                    {numeral(
                      detailsOrder?.payload?.message?.order_detail?.total_price
                    ).format("$0,0.0'")}
                  </div>
                </div>
              </div>
              {!detailsOrder?.loading &&
                detailsOrder?.payload?.message?.order_return_detail && (
                  <div style={{ marginTop: "20px" }}>
                    <div className="order-detail-title-area">
                      <div className="d-flex flex-column">
                        <div className="order-num">Refund Detail</div>
                      </div>
                    </div>
                    <Table responsive size="sm" className="transactions-box">
                      <thead className="table_heading">
                        <tr>
                          <th>S#</th>
                          <th>Order Date</th>
                          <th>SKU #</th>
                          <th>Description</th>
                          <th>Qty</th>
                          <th>Price</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!detailsOrder?.loading &&
                          detailsOrder?.payload?.message?.order_return_detail?.refund_line_items?.map(
                            (item, key) => {
                              return (
                                <tr key={key}>
                                  <td>{key + 1}</td>
                                  <td>
                                    {moment
                                      .utc(order?.created_at)
                                      .format("DD-MM-YYYY")}
                                  </td>
                                  <td>{item?.line_item?.sku}</td>
                                  <td>{item?.line_item?.title}</td>
                                  {/* <td>{item?.line_item?.quantity}</td>
                              <td>
                                {numeral(
                                  getSum(item?.subtotal, item?.quantity)
                                ).format("$0,0.0'")}
                              </td> */}
                                  <td>{item?.quantity}</td>
                                  <td>
                                    {numeral(
                                      item?.line_item?.price
                                    ).format("$0,0.0'")}
                                  </td>
                                  <td>
                                    {numeral(item?.subtotal).format("$0,0.0'")}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                      </tbody>
                    </Table>
                    {/* {console.log(detailsOrder?.payload?.message?.order_return_detail?.refund_line_items, 'refunds')} */}
                    <div className="order-sum">
                      <>
                        <div className="paid-mid-row">
                          <div className="left-txt multi-content">
                            <div className="left-hd">Subtotal</div>
                            <div className="left-info">
                              {detailsOrder?.payload?.message?.order_return_detail
                                ?.refund_line_items?.length
                                ? detailsOrder?.payload?.message
                                  ?.order_return_detail?.refund_line_items?.length
                                : 0}{" "}
                              item
                            </div>
                          </div>
                          <div className="right-txt">
                            {numeral(
                              detailsOrder?.payload?.message?.order_return_detail?.refund_line_items?.reduce(
                                (acc, item) => {
                                  return (acc = acc + item.subtotal);
                                },
                                0
                              )
                            ).format("$0,0.0'")}
                          </div>
                        </div>
                        <div className="paid-mid-row">
                          <div className="left-txt multi-content">
                            <div className="left-hd">Tax</div>
                          </div>
                          <div className="right-txt">
                            {numeral(
                              detailsOrder?.payload?.message?.order_return_detail?.refund_line_items?.reduce(
                                (acc, item) => {
                                  return (acc = acc + item.total_tax);
                                },
                                0
                              )
                            ).format("$0,0.0'")}
                          </div>
                        </div>
                        <div className="paid-mid-row">
                          <div className="left-txt multi-content">
                            <div className="left-hd fw-bold">Total Amount</div>
                          </div>
                          <div className="right-txt fw-bold">
                            {numeral(
                              detailsOrder?.payload?.message?.order_return_detail?.refund_line_items?.reduce(
                                (acc, item) => {
                                  return (acc = acc + item.subtotal);
                                },
                                0
                              ) +
                              detailsOrder?.payload?.message?.order_return_detail?.refund_line_items?.reduce(
                                (acc, item) => {
                                  return (acc = acc + item.total_tax);
                                },
                                0
                              )
                            ).format("$0,0.0'")}
                          </div>
                        </div>
                      </>
                    </div>
                  </div>
                )}
            </>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={() => setModal(false)}
            className="default-btn outline padb-3"
          >
            <i className="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; Go
            Back
          </Button>
        </Modal.Footer>
      </Modal>
      <Modald
        maskClosable={false}
        className="modal-generic modal-500"
        centered
        visible={banModal}
        onCancel={() => setBanModal(false)}
        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        footer={[
          <Button type="primary" onClick={() => setBanModal(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            // onClick={handleOk}
            onClick={() => {
              unban();
            }}
          >
            Unban
          </Button>,
        ]}
      >
        <h2 className="modal-hd1">Unban Confirmation</h2>
        <div className="my-4">
          <h5>Are you sure you want to unban user?</h5>
        </div>
      </Modald>
    </>
  );
};
