import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { Select, Tabs, Radio, Button, Modal } from "antd";
import { notification } from "antd";
import '../../css/event.css';
import EventGrid from "../../components/eventsGrid/eventsGrid";
// import GridListing from "../../components/gridListingBar/gridListingBar"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGrip,
  faBars,
  faPlus,
  faXmark,
  faUserTie,
  faUserPen,
  faUserGear,
  faVideo,
  faComments,
  faBox,
  faHeadset,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";

const { Option } = Select;
const handleChange = (value) => {
};

const { TabPane } = Tabs;
export default function Events() {
  let query = new URL(window.location.href);
  let searchParams = new URLSearchParams(query.search);
  const [gridActive, setGridActive] = useState(true);
  const [eventTitle, setEventTitle] = useState(
    searchParams.get("type") === null ? "Upcoming" : searchParams.get("type")
  );
  const [eventStatus, setEventStatus] = useState(123);
  const dispatch = useDispatch();
  const { userInfo, validate } = useSelector((state) => {
    return state;
  });

  const callbackTabClicked = (key, event) => {
    const url = new URL(window.location.href);
    url.searchParams.set("type", key);
    window.history.replaceState(null, null, url);
    setEventTitle(key);
    setEventStatus(event.timeStamp);
  };

  const roleId = localStorage.getItem("Role");


  const [role, setRole] = useState(roleId);
  const [isModalOpen, setIsModalOpen] = useState(true);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleRole = (e) => {
    setRole(e.target.value);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const copyToClipboard = (value) => {
    let textField = document.createElement("textarea");
    textField.innerText = value;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    notification.success({
      message: "Copied to Clipboard!",
      className: "toast-success",
    });
  };


  return (
    <>
      <div className="top-btn-area mb-10 d-inline-flex">
        <Link
          to="/schedule-event"
          className="default-btn d-flex align-items-center justify-content-center m-0 create-event-btn"
        >
          <FontAwesomeIcon icon={faPlus} />
          <span className="nav-text ">Create Live Show</span>
        </Link>
      </div>
      <div className="grid-listing-area flex-column  flex-sm-column align-items-sm-start flex-md-column align-items-md-start flex-lg-column align-items-lg-start flex-xl-row align-items-start  mb-30 position-relative">
        <div className="grid-listing-left flex-grow-1">
          {/* <h1 className="hd2">All Events</h1> */}

          {/* <Select
            className="select-style"
            defaultValue="Events"
            size="large"
            focus="false"
            onChange={handleChange}
            onBlur={false}
          >
            { <Option value="All">All</Option> }
            <Option value="Events">Events</Option>
            <Option value="Recorded Events">Recorded Events</Option>
          </Select> */}

          <Tabs
            // defaultActiveKey="Upcoming"
            defaultActiveKey={eventTitle}
            // onChange={onChange}
            className="events-tabs-main"
            onTabClick={callbackTabClicked}
          >
            <TabPane tab="Upcoming" key="Upcoming"></TabPane>
            <TabPane tab="Live" key="Live Events"></TabPane>
            <TabPane tab="Management" key="Recorded"></TabPane>
            {validate?.payload?.message?.account_type === "brand" && (
              <TabPane tab="Published" key="Published"></TabPane>
            )}
            {/* <TabPane tab="Expired" key="Expired">
            </TabPane> */}
          </Tabs>
        </div>

        {userInfo?.message?.data?.vcom && (
          <div className="bio_shop_copy_link_events_main">
            {/* <p className="mb-0 playback-txt">Playback URL :</p> */}
            <div className="bio_shop_copy_link_events">
              <div className="link">
                <p className="mb-0 playback-txt2">Playback URL</p>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${userInfo?.message?.data?.vcom}`}
                >
                  {userInfo?.message?.data?.vcom}
                </a>
              </div>
              <div
                className="copyBtn"
                onClick={() =>
                  copyToClipboard(userInfo?.message?.data?.vcom)
                }
              >
                Copy
              </div>
            </div>
          </div>
        )}

        <div className="grid-listing-right">
          <div className={`grid-icon ${gridActive ? "active" : ""}`}>
            <FontAwesomeIcon icon={faGrip} />
            <a
              className="block-link"
              href="#"
              onClick={() => {
                setGridActive(true);
              }}
            ></a>
          </div>
          <div className={`grid-icon ${!gridActive ? "active" : ""}`}>
            <FontAwesomeIcon icon={faBars} />
            <a
              className="block-link"
              href="#"
              onClick={() => {
                setGridActive(false);
              }}
            ></a>
          </div>

          {/* <Link
            to="/schedule-event"
            className="default-btn d-flex align-items-center justify-content-center m-0 create-event-btn"
          >
            <FontAwesomeIcon icon={faPlus} />
            <span className="nav-text ">Create Event</span>
          </Link> */}

          {/* <button
            onClick={() => {
              showModal()
            }}
            // to="/schedule-event"
            className="default-btn d-flex align-items-center justify-content-center m-0 create-event-btn"
          >
            <span className="nav-text ">test</span>
          </button> */}

          {/* <Button
            className="default-btn d-flex align-items-center justify-content-center m-0"
            type="primary"
            block
            size="large"
            icon={<PlusOutlined />}
          >
            Create Event
          </Button> */}
        </div>
      </div>

      <div className="title-area">
        <h1>{eventTitle === "Recorded" ? "Management" : eventTitle}</h1>
      </div>

      <EventGrid
        className={gridActive}
        title={eventTitle}
        roleKey={role}
        eventStatus={eventStatus}
      />
    </>
  );
}
