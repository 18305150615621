import React, { useState, useEffect, useRef } from "react";
import Table from "react-bootstrap/Table";
import { Modal } from "react-bootstrap";
import { Button, Form, Input, InputNumber, Modal as Modald, notification } from "antd";
import { DatePicker, Spin, Select, Empty } from "antd";
import {
  customerorderHistory,
  details,
  getPurchaser,
  getReferrer,
} from "../../redux/actions/purchaseHistory.action";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import AsyncSelect from "react-select/async";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight, faXmark } from "@fortawesome/free-solid-svg-icons";
import "../../css/sales.scss";
import moment from "moment";
import numeral from "numeral";
import { getSavedCategories, getSavedGeneralCategories, getSavedSubCategories } from "../../redux/actions/newCategory.action";
import { searchEventID, searchReviewID, searchShowID } from "../../redux/actions/eventID.action";
import { unFollow } from "../../redux/actions/unFollowBrand";
import defaultImage from '../../images/user.png';
import { getBoostedReviews } from "../../redux/actions/boost.action";
import { deBoostReview } from "../../redux/actions/boostReview.action";
import { Link, Navigate, useNavigate } from "react-router-dom";
import AsyncInfluencer from "../influencerReview/asyncInfluencer";
import { getInfluencers } from "../../redux/actions/influencerNew.action";
const { Option } = Select;
let arrHost = [];
const { RangePicker } = DatePicker;

export default () => {
  const [form] = Form.useForm();
  const dateFormat = "MM-DD-YYYY";
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const min_date = moment().startOf("year").format("YYYY-MM-DD");
  const max_date = moment().endOf("year").format("YYYY-MM-DD");

  const [startDate, setStartDate] = useState(min_date);
  const [endDate, setEndDate] = useState(max_date);
  const [totalEvent, setTotalEvent] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [source, setSource] = useState("all");
  const [allCustomer, setAll] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [influencerId, setInfluencerId] = useState();
  const [modal, setModal] = useState(false);
  const [flag, setFlag] = useState(false);
  const [status, setStatus] = useState(false);
  const [submit, setSubmit] = useState("none");
  const [customerId, setCustomerId] = useState();
  const [gender, setGender] = useState();
  const [category, setCategory] = useState();
  const [subCategory, setSubCategory] = useState();
  const [load, setLoad] = useState(false);
  const [generalLoad, setGeneralLoad] = useState(false);
  const [catLoad, setCatLoad] = useState(false);
  const [loadd, setLoadd] = useState(true);
  const [subCatLoad, setSubCatLoad] = useState(false);
  const [order, setOrder] = useState();
  const [purchaseBy, setPurchaseBy] = useState();
  const [referredBy, setReferredBy] = useState();
  const [searchPurchaseBy, setSearchPurchaseBy] = useState([]);
  const [searchReferredBy, setsearchReferredBy] = useState([]);
  const [banType, setBanType] = useState("chatban");
  const [groupByTable, setGroupByTable] = useState("chatban");
  const [purchaseLoad, setPurchaseLoad] = useState(false);
  const [referrerLoad, setReferrerLoad] = useState(false);
  const [searchID, setSearchID] = useState();
  const [searchIDLoad, setSearchIDLoad] = useState("");
  const [searchDataIDs, setSearchDataIDs] = useState([]);
  const [idType, setIdType] = useState("event");
  const [reviewID, setReviewID] = useState();
  const [banModal, setBanModal] = useState(false);
  const [influencer, setInfluencer] = useState("");
  const { shopifyCustomerorder, detailsOrder, savedGeneralCategories, savedCategory, savedSubCategory, boostedReviews, getAllInfluencers } = useSelector(
    (state) => {
      return state;
    }
  );
  const childRef = useRef();
  const limit = 10;
  const upperLimit = (currentPage + 1) * limit;
  const lowerLimit = upperLimit - limit + 1;
  const itemsPerPage = 15;
  const pageCount = Math.ceil(totalEvent / itemsPerPage);
  const getSum = (a1, a2) => parseFloat(a1) * parseFloat(a2);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    dispatch(getInfluencers()).then((res) => {
    })
    dispatch(getBoostedReviews(1, limit, startDate, endDate, influencerId)).then((res) => {
      setLoadd(false)
      setLoading(false);
    })
  }, []);

  const dateRangePickerChanger = (value, dataString) => {
    const startDate = dataString[0];
    const endDate = dataString[1];
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handlePageClick = (e) => {
    const page = e.selected;
    setCurrentPage(page);
    setLoadd(true);
    setLoading(true);
    dispatch(getBoostedReviews(page + 1, limit, startDate, endDate, influencerId)).then((res) => {
      setLoading(false);
      setLoadd(false)
      setInfluencer("");
      setTotalEvent(res?.message?.total_records);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setGroupByTable(banType)
    setStatus(true);
    dispatch(getBoostedReviews(currentPage + 1, limit, moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD"), influencerId)).then((res) => {
      setLoading(false);
      setInfluencer("");
      setTotalEvent(res?.message?.total_records);
      setCurrentPage(0);
    });
    // setSubmit(groupBy);
  };

  const detailView = (orderDetail) => {
    setModal(true);
    setLoad(true);
    dispatch(details(orderDetail?.sales_tracker_id, orderDetail?._id)).then(
      (res) => {
        setLoad(false);
      }
    );
  };

  const handleReset = (res) => {
    setLoading(true);
    setInfluencerId();
    childRef.current?.clear();
    // setBanType("chatban")
    setLoadd(true)
    // setGroupByTable("chatban")
    const min_date = moment().startOf("year").format("YYYY-MM-DD");
    const max_date = moment().endOf("year").format("YYYY-MM-DD");
    setStartDate(min_date);
    setEndDate(max_date);
    dispatch(getBoostedReviews(1, limit, min_date, max_date, "")).then((res) => {
      setLoading(false);
      setLoadd(false)
      setInfluencer("");
      setTotalEvent(res?.message?.total_records);
    });
  };

  const smartSearchFilter = async (value) => {
    if (value.length > 0 && value.trim()) {
      await axios
        .post(`brand/reports/getCustomers`, {
          name: value.trim(),
        })
        .then((response) => {
          const loadHost = [];
          const host = response.data.data;
          if (host.length === 0) {
            // notification.error({
            //   message: "No Host Found",
            //   className: "toast-error",
            // });
          }
          setAll(host);
          host.map((item) => {
            return loadHost.push({
              value: item?._id,
              label: item?.first_name + " " + item?.last_name,
            });
          });
          arrHost = loadHost;
        })
        .catch(function (error) {
          // console.log(error);
        });
    }
  };

  useEffect(() => {
    document.body.classList.add("bioshop-body", "shopper-bioshop-body");
  }, []);

  const deActivate = () => {
    dispatch(deBoostReview(reviewID)).then((res) => {
      if (res.success) {
        notification.success({
          message: res?.message,
          className: "toast-success",
        });
        setLoadd(true)
        setBanModal(false);
        dispatch(getBoostedReviews(currentPage, limit, startDate, endDate, influencerId)).then((res) => {
          setLoadd(false)
        })
      }
      else {
        notification.error({
          message: "User not found in ban list",
          className: "toast-error",
        });
      }
    })
  }

  const changeRoute = (val) => {
    navigate({
      pathname: `/influencer-review/${val}`,
    });
  };

  const getInfluencer = (influencerData) => {
    setInfluencerId(influencerData);
  };
  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  const selectInfluencer = (value) => {
    setInfluencerId(value);
  }

  // This is componentWillUnmount
  useEffect(() => {
    return () => {
      //  componentWillUnmount.current = true;
      document.body.classList.remove("bioshop-body", "shopper-bioshop-body");
    };
  }, []);


  function DefaultTable() {
    // let data = shopifyCustomerorder?.payload?.message?.data;

    return (<>
      <div className="table-responsive scrollbar-style">
        <table className="transactions-box table">
          <thead className="table_heading">
            <tr>
              <th className="td-width-60">S.#</th>
              <th className="td-width-100">Influencer</th>
              <th className="td-width-100">Pixel ID</th>
              <th className="td-width-100">Title</th>
              <th className="td-width-120">Start Date</th>
              <th className="td-width-120">End Date</th>
              <th className="td-width-120">Total Days</th>
              <th className="td-width-120">Cost Per Day</th>
              <th className="td-width-120">Budget</th>
              <th className="td-width-100">Action</th>
            </tr>
          </thead>
          <tbody>
            {loadd ? (
              <div className="antd-loading-ift">
                <Spin size="large"></Spin>
              </div>
            ) : boostedReviews?.payload?.message?.data?.length === 0 ? (
              <tr>
                <td colspan="12">
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </td>
              </tr>
            ) : (
              boostedReviews?.payload?.message?.data?.map((item, i) => {
                return (
                  <tr>
                    <td>{i + 1 + currentPage * limit}</td>
                    <td >
                      {item?.influencer?.name ? item?.influencer?.name : "-"}
                    </td>
                    <td >
                      {item?.influencer?.pixel_id ? item?.influencer?.pixel_id : "-"}
                    </td>
                    <td className="blue-link cursor-pointer" onClick={() => changeRoute(item?.review_id)}>
                      {item?.reviews?.title ? item?.reviews?.title : "-"}
                    </td>

                    <td>
                      {moment(item?.started_date).format("MM-DD-YYYY") ? moment(item?.started_date).format("MM-DD-YYYY") : "-"}
                    </td>
                    <td>
                      {moment(item?.ended_date).format("MM-DD-YYYY") ? moment(item?.ended_date).format("MM-DD-YYYY") : "-"}
                    </td>
                    <td>
                      {item?.number_of_days}
                    </td>
                    <td>
                      {numeral(item?.budget / item?.number_of_days).format("$0,0.0'")}
                    </td>
                    <td>
                      {numeral(item?.budget).format("$0,0.0'")}
                    </td>
                    <td className="">
                      {moment().diff(item?.ended_date) >= 0 ? <span className="text-danger">Expired</span> :
                        <button type="button" onClick={() => { setBanModal(true); setReviewID(item?.review_id) }} class="ant-btn ant-btn-primary default-btn padb-3 small-btn m-width-100 ml-0 mt-1 mb-1 fw-normal h-30"><span>De-activate</span></button>
                      }

                    </td>
                  </tr>
                );
              })
            )}

            {boostedReviews?.payload?.message?.data.length > 0 && (
              <tr>
                <td></td>
                <td>
                  <b>Total</b>
                </td>

                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="">
                  <b>
                    {numeral(
                      boostedReviews?.payload?.message?.data.reduce((a, b) => {
                        return (
                          Number(a) +
                          Number(
                            b.budget ? b.budget : 0.0
                          )
                        );
                      }, 0)
                    ).format("$0,0.0'")}
                  </b>
                </td>


                <td></td>

              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
    );



  }

  return (
    <>
      <div className="sales-page-main">
        <div className="title-area mb-3 pb-0">
          <h1 className="mb-0 pb-0">Boost Reviews</h1>
        </div>
        <div className="brand_container_main aff-payment">
          <Form form={form}>
            <div className="row w-20-desktop-main">

              <div className="col-xl-3 col-md-6 col-12 mb-15">
                <p className="mb-5rem">Select Start Date / End Date</p>
                <RangePicker
                  size="large"
                  className="w-100"
                  key={4}
                  value={
                    startDate && endDate
                      ? [moment(startDate), moment(endDate)]
                      : []
                  }
                  allowClear={false}
                  ranges={{
                    "All Time": [
                      moment().subtract(1, "year").startOf("year"),
                      moment(),
                    ],
                    "YTD": [moment().startOf("year"), moment()],
                    "Last 30 Days": [moment().subtract(30, "days"), moment()],
                    "Last 7 Days": [moment().subtract(7, "days"), moment()],
                    "Today": [moment(), moment()],
                    // "Last Year": [
                    //   moment().subtract(1, "year").startOf("year"),
                    //   moment().subtract(1, "year").endOf("year"),
                    // ],
                    // "This Year": [moment().startOf("year"), moment()],
                    // "Last Month": [
                    //   moment().subtract(1, "month").startOf("month"),
                    //   moment().subtract(1, "month").endOf("month"),
                    // ],
                    // "This Month": [
                    //   moment().startOf("month"),
                    //   moment().endOf("month"),
                    // ],
                    // Today: [moment(), moment()],
                  }}
                  format={dateFormat}
                  onChange={dateRangePickerChanger}
                />
              </div>
              <div className="col-xl-3 col-md-6 col-12 mb-15">
                <p className="mb-5rem">Select Creator</p>
                {/* <div className="select-inf-field d-flex flow-row">
              <AsyncInfluencer
                ref={childRef}
                getInfluencer={getInfluencer}
                influencer={influencer}
              />
            </div> */}
                <Select
                  showSearch
                  name="sort"
                  size="large"
                  placeholder="Select Creator"
                  className="w-100 select-style dark-field"
                  optionFilterProp="children"
                  value={influencerId}
                  onChange={(value) => selectInfluencer(value)}
                  // onSearch={onSearch}
                  filterOption={filterOption}
                  options={getAllInfluencers?.message && [
                    ...getAllInfluencers?.message].map((influencer) => {
                      return ({
                        label: influencer?.name + " - " + influencer?.pixel_id + " - " + influencer?.email,
                        value: influencer?.user_id
                      }
                      )
                    })
                  }
                />
              </div>
              {/* <div className="col-xl-3 col-md-6 col-12 mb-15">
                <p className="mb-5rem">Ban Type</p>
                <Select
                  className="w-100 select-style dark-field"
                  placeholder="Select Group By"
                  size="large"
                  focus={false}
                  loading={loading}
                  disabled={loading}
                  onBlur={false}
                  value={banType}
                  onChange={(e) => setBanType(e)}
                // defaultValue="date"
                >
                  <Option value="chatban">Events/Reviews Ban</Option>
                  <Option value="permaban">Global Ban</Option>
                </Select>
              </div> */}




              <div className="col-xl-3 col-md-6 col-12 d-flex align-items-end mb-15">
                <Button
                  onClick={handleSubmit}
                  className="default-btn fltr-hpr  medium-btn m-width-100 ml-0 fw-normal"
                  type="primary"
                  htmlType="submit"
                >
                  Search
                </Button>
                <Button
                  onClick={handleReset}
                  className="default-btn outline fw-normal fltr-hpr medium-btn  res-btn2 min-w-100"
                  type="primary"
                  htmlType="submit"
                >
                  Reset
                </Button>
              </div>
            </div>



          </Form>
          <hr className="separator-line mt-0" />
          <div className="purchase-data referral-data">
            {loading ? (
              // <div className="antd-loading-ift">
              // 	<Spin size="large" />
              // </div>
              <div className="loading-wrap">
                <span className="spinclass loader-center position-relative">
                  <Spin size="large" />
                </span>
              </div>
            ) : (
              <>
                {submit === "none" && <DefaultTable />}
                {/* {submit === "date" && <DateGroupTable />} */}
                {/* {submit === "category" && <CategoryGroupTable />}
                {submit === "brand" && <BrandGroupTable />} */}
                <div className="purchase-data referral-data">
                  {boostedReviews?.payload?.message?.data?.length ? (
                    <ReactPaginate
                      nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
                      pageCount={Math.ceil(boostedReviews?.payload?.message?.total_records / limit)}
                      forcePage={currentPage}
                      previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                      pageClassName="page-item d-none"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      breakClassName="page-item d-none"
                      breakLinkClassName="page-link"
                      containerClassName={"pagination custom-paginate"}
                      activeClassName="active"
                      renderOnZeroPageCount={null}
                    />
                  ) : (
                    ""
                  )}
                </div>

              </>
            )}
          </div>
        </div>
      </div>

      <Modal
        dialogClassName="code-activated-popup"
        size="lg"
        scrollable
        show={modal}
        onHide={() => setModal(false)}
        centered
        backdrop="static"
      >
        {/* <Modal.Header closeButton></Modal.Header> */}
        <Modal.Header className="modal-header-style2" closeButton>
          <Modal.Title className="mb-0">Order Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {load ? (
            <span className="mt-2 mr-2 loader-center position-relative">
              <Spin size="large" />
            </span>
            // <Spin
            //   style={{
            //     display: "flex",
            //     justifyContent: "center",
            //   }}
            // />
          ) : (
            <>
              <div className="order-detail-title-area">
                <div className="d-flex flex-column">
                  <div className="order-num">
                    Order #
                    <div className="order-name">
                      {
                        detailsOrder?.payload?.message?.order_detail
                          ?.order_number
                      }
                    </div>
                  </div>
                  <duv className="order-num order-name-main">
                    Customer Name:
                    <div className="order-name">
                      {detailsOrder?.payload?.message?.order_detail?.customer
                        ?.first_name +
                        " " +
                        detailsOrder?.payload?.message?.order_detail?.customer
                          ?.last_name}
                    </div>
                  </duv>
                </div>

                <span className="order-date">
                  {moment
                    .utc(
                      detailsOrder?.payload?.message?.order_detail?.created_at
                    )
                    .format("MMMM Do YYYY [from Online Store]")}
                </span>
              </div>
              <Table responsive size="sm" className="transactions-box">
                <thead className="table_heading">
                  <tr>
                    <th className="td-width-60">S.#</th>
                    <th className="td-width-120">Order Date</th>
                    <th className="td-width-100">SKU #</th>
                    <th className="td-width-200">Description</th>
                    <th className="td-width-80">Qty</th>
                    <th className="td-width-100 text-right">Price</th>
                    <th className="td-width-100 text-right">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {!detailsOrder?.loading &&
                    detailsOrder?.payload?.message?.order_detail?.line_items?.map(
                      (item, key) => {
                        return (
                          <tr key={key}>
                            <td>{key + 1}</td>
                            <td>
                              {moment
                                .utc(order?.created_at)
                                .format("DD-MM-YYYY")}
                            </td>
                            <td>{item?.sku}</td>
                            <td>{item?.title}</td>
                            <td>{item?.quantity}</td>
                            <td className="text-right">{numeral(item?.price).format("$0,0.0'")}</td>
                            <td className="text-right">
                              {numeral(
                                getSum(item?.quantity, item?.price)
                              ).format("$0,0.0'")}
                            </td>
                          </tr>
                        );
                      }
                    )}
                </tbody>
              </Table>
              <div className="order-sum">
                <div className="paid-mid-row">
                  <div className="left-txt multi-content">
                    <div className="left-hd">Subtotal</div>
                    <div className="left-info">
                      {
                        detailsOrder?.payload?.message?.order_detail?.line_items
                          ?.length
                      }{" "}
                      item
                    </div>
                  </div>
                  <div className="right-txt">
                    {numeral(
                      detailsOrder?.payload?.message?.order_detail
                        ?.total_line_items_price
                    ).format("$0,0.0'")}
                  </div>
                </div>

                <div className="paid-mid-row">
                  <div className="left-txt multi-content">
                    <div className="left-hd">Discount</div>
                    {detailsOrder?.payload?.message?.order_detail
                      ?.total_discounts
                      ? numeral(
                        detailsOrder?.payload?.message?.order_detail
                          ?.total_discounts /
                        detailsOrder?.payload?.message?.order_detail
                          ?.total_line_items_price
                      ).format("0%")
                      : ""}
                  </div>
                  {detailsOrder?.payload?.message?.order_detail
                    ?.total_discounts ? (
                    <>
                      <div className="right-txt">
                        -
                        {numeral(
                          detailsOrder?.payload?.message?.order_detail
                            ?.total_discounts
                        ).format("$0,0.0'")}
                      </div>
                    </>
                  ) : (
                    <div className="right-txt">
                      {numeral(0).format("$0,0.0'")}
                    </div>
                  )}
                </div>

                <div className="paid-mid-row">
                  <div className="left-txt multi-content">
                    <div className="left-hd">Shipping</div>
                  </div>
                  <div className="right-txt">
                    {numeral(
                      detailsOrder?.payload?.message?.order_detail
                        ?.total_shipping_price_set?.presentment_money?.amount
                    ).format("$0,0.0'")}
                  </div>
                </div>

                <div className="paid-mid-row">
                  <div className="left-txt multi-content">
                    <div className="left-hd">Tax</div>
                  </div>
                  <div className="right-txt">
                    {numeral(
                      detailsOrder?.payload?.message?.order_detail?.total_tax
                    ).format("$0,0.0'")}
                  </div>
                </div>
                <div className="paid-mid-row">
                  <div className="left-txt multi-content">
                    <div className="left-hd fw-bold">Total Amount</div>
                  </div>
                  <div className="right-txt fw-bold">
                    {numeral(
                      detailsOrder?.payload?.message?.order_detail?.total_price
                    ).format("$0,0.0'")}
                  </div>
                </div>
              </div>
              {!detailsOrder?.loading &&
                detailsOrder?.payload?.message?.order_return_detail && (
                  <div style={{ marginTop: "20px" }}>
                    <div className="order-detail-title-area">
                      <div className="d-flex flex-column">
                        <div className="order-num">Refund Detail</div>
                      </div>
                    </div>
                    <Table responsive size="sm" className="transactions-box">
                      <thead className="table_heading">
                        <tr>
                          <th>S#</th>
                          <th>Order Date</th>
                          <th>SKU #</th>
                          <th>Description</th>
                          <th>Qty</th>
                          <th>Price</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!detailsOrder?.loading &&
                          detailsOrder?.payload?.message?.order_return_detail?.refund_line_items?.map(
                            (item, key) => {
                              return (
                                <tr key={key}>
                                  <td>{key + 1}</td>
                                  <td>
                                    {moment
                                      .utc(order?.created_at)
                                      .format("DD-MM-YYYY")}
                                  </td>
                                  <td>{item?.line_item?.sku}</td>
                                  <td>{item?.line_item?.title}</td>
                                  {/* <td>{item?.line_item?.quantity}</td>
                              <td>
                                {numeral(
                                  getSum(item?.subtotal, item?.quantity)
                                ).format("$0,0.0'")}
                              </td> */}
                                  <td>{item?.quantity}</td>
                                  <td>
                                    {numeral(
                                      item?.line_item?.price
                                    ).format("$0,0.0'")}
                                  </td>
                                  <td>
                                    {numeral(item?.subtotal).format("$0,0.0'")}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                      </tbody>
                    </Table>
                    <div className="order-sum">
                      <>
                        <div className="paid-mid-row">
                          <div className="left-txt multi-content">
                            <div className="left-hd">Subtotal</div>
                            <div className="left-info">
                              {detailsOrder?.payload?.message?.order_return_detail
                                ?.refund_line_items?.length
                                ? detailsOrder?.payload?.message
                                  ?.order_return_detail?.refund_line_items?.length
                                : 0}{" "}
                              item
                            </div>
                          </div>
                          <div className="right-txt">
                            {numeral(
                              detailsOrder?.payload?.message?.order_return_detail?.refund_line_items?.reduce(
                                (acc, item) => {
                                  return (acc = acc + item.subtotal);
                                },
                                0
                              )
                            ).format("$0,0.0'")}
                          </div>
                        </div>
                        <div className="paid-mid-row">
                          <div className="left-txt multi-content">
                            <div className="left-hd">Tax</div>
                          </div>
                          <div className="right-txt">
                            {numeral(
                              detailsOrder?.payload?.message?.order_return_detail?.refund_line_items?.reduce(
                                (acc, item) => {
                                  return (acc = acc + item.total_tax);
                                },
                                0
                              )
                            ).format("$0,0.0'")}
                          </div>
                        </div>
                        <div className="paid-mid-row">
                          <div className="left-txt multi-content">
                            <div className="left-hd fw-bold">Total Amount</div>
                          </div>
                          <div className="right-txt fw-bold">
                            {numeral(
                              detailsOrder?.payload?.message?.order_return_detail?.refund_line_items?.reduce(
                                (acc, item) => {
                                  return (acc = acc + item.subtotal);
                                },
                                0
                              ) +
                              detailsOrder?.payload?.message?.order_return_detail?.refund_line_items?.reduce(
                                (acc, item) => {
                                  return (acc = acc + item.total_tax);
                                },
                                0
                              )
                            ).format("$0,0.0'")}
                          </div>
                        </div>
                      </>
                    </div>
                  </div>
                )}
            </>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={() => setModal(false)}
            className="default-btn outline padb-3"
          >
            <i className="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; Go
            Back
          </Button>
        </Modal.Footer>
      </Modal>
      <Modald
        maskClosable={false}
        className="modal-generic modal-500"
        centered
        visible={banModal}
        onCancel={() => setBanModal(false)}
        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        footer={[
          <Button type="primary" onClick={() => setBanModal(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            // onClick={handleOk}
            onClick={() => {
              deActivate();
            }}
          >
            Deactive
          </Button>,
        ]}
      >
        <h2 className="modal-hd1">DeActivating Confirmation</h2>
        <div className="my-4">
          <h5>Are you sure you want to de-activate boost?</h5>
        </div>
      </Modald>
    </>
  );
};
