import React, { useState, useEffect, useRef } from "react";
import Table from "react-bootstrap/Table";
import { Modal } from "react-bootstrap";
import { Alert, Button, Form, Input, InputNumber, Modal as Modald, Tooltip, notification } from "antd";
import { DatePicker, Spin, Select, Empty, Card, Icon, Tag } from "antd";
import {
  customerorderHistory,
  details,
  getPurchaser,
  getReferrer,
} from "../../redux/actions/purchaseHistory.action";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import AsyncSelect from "react-select/async";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight, faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import "../../css/sales.scss";
import moment from "moment";
import numeral from "numeral";
import { getSavedCategories, getSavedGeneralCategories, getSavedSubCategories } from "../../redux/actions/newCategory.action";
import { searchEventID, searchReviewID, searchShowID } from "../../redux/actions/eventID.action";
import { unFollow } from "../../redux/actions/unFollowBrand";
import defaultImage from '../../images/user.png';
import { getBoostedReviews } from "../../redux/actions/boost.action";
import { deBoostReview } from "../../redux/actions/boostReview.action";
import { Link, Navigate, useNavigate } from "react-router-dom";
import AsyncInfluencer from "../influencerReview/asyncInfluencer";
import { getInfluencers } from "../../redux/actions/influencerNew.action";
import { addStripeCard } from "../../redux/actions/addStripeCard";
import { stripeCardDetail } from "../../redux/actions/stripeCardDetail";
import { EditOutlined, DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { color } from "styled-system";
import Swal from "sweetalert2";
import { makeDefaultStripeCard, makeStripePayment, updateStripeCard } from "../../redux/actions/stripeCard";
import { deleteStripeCard } from "../../redux/actions/deleteStripeCard";
const { Option } = Select;
let arrHost = [];
const { RangePicker } = DatePicker;

export default () => {
  const [form] = Form.useForm();
  const dateFormat = "MM-DD-YYYY";
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [makePaymentLoading, setMakePaymentLoading] = useState(false);

  const min_date = moment().subtract(1, "year").startOf("year").format("YYYY-MM-DD");
  const max_date = moment().endOf("year").format("YYYY-MM-DD");

  const [modal, setModal] = useState(false);
  const [load, setLoad] = useState(false);
  const [loadd, setLoadd] = useState(true);
  const [cardNo, setCardNo] = useState("**** **** **** 4242");
  const [expiryMonth, setExpiryMonth] = useState(0);
  const [expiryYear, setExpiryYear] = useState(0);
  const [stripeMessage, setStripeMessage] = useState("");
  const [stripeStatus, setStripeStatus] = useState("");
  const [stripeTimer, setStripeTimer] = useState(false);
  const [cardDetails, setCardDetails] = useState();


  const cardData = [
    {
      id: 1,
      cardType: 'visa',
      cardNumber: '7830',
      expiryDate: '06/24',
      isDefault: true
    },
    {
      id: 2,
      cardType: 'mastercard',
      cardNumber: '1075',
      expiryDate: '02/25',
      isDefault: false
    },
  ];
  const { stripeCardDetails } = useSelector(
    (state) => {
      return state;
    }
  );


  useEffect(() => {
    let query = new URL(window.location.href);
    let searchParams = new URLSearchParams(query.search);
    if (searchParams.get('campaign_status') === "success") {
      setStripeMessage("Your card has been successfully connected")
      setStripeStatus("success")
      setStripeTimer(true)
      setTimeout(() => {
        setStripeTimer(false)
      }, 3000);
      // notification.success({
      //   message: "Your card has been successfully connected",
      //   className: 'toast-success',
      // });
    }
    else if (searchParams.get('campaign_status') === "error") {
      setStripeMessage("Your card is not connected")
      setStripeStatus("error")
      setStripeTimer(true)
      setTimeout(() => {
        setStripeTimer(false)
      }, 3000);
      // notification.error({
      //   message: "Your card is not connected",
      //   className: 'toast-error',
      // });
    }
    // setLoading(true);
    // dispatch(getInfluencers()).then((res) => {
    // })
    dispatch(stripeCardDetail()).then((res) => {
      setLoadd(false)
      // setLoading(false);
    })



  }, []);



  const detailView = (cardDetail) => {
    setCardDetails(cardDetail)
    setExpiryMonth(cardDetail?.card?.exp_month);
    setExpiryYear(cardDetail?.card?.exp_year);
    setModal(true);
  };

  const addCard = () => {
    setLoading(true);
    dispatch(addStripeCard()).then((res) => {
      window.location.replace(res?.message);
    })
  }
  const removeCard = (id) => {
    Swal.fire({
      title: `Are you sure you want to delete this card?`,
      icon: "warning",
      cancelButtonText: "No",
      showCancelButton: true,
      confirmButtonColor: "#052977",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes`,
    }).then((result) => {
      if (result.isConfirmed) {
        setLoadd(true);
        dispatch(deleteStripeCard(id)).then((res) => {
          setLoadd(false);
          if (res?.type === "DELETE_STRIPE_CARD_ERROR") {

            Swal.fire({
              title: res?.payload?.data?.error,
              icon: "warning",
              cancelButtonText: "No",
              showCancelButton: true,
              confirmButtonColor: "#052977",
              cancelButtonColor: "#d33",
              confirmButtonText: `Yes`,
            }).then((result) => {
              if (result.isConfirmed) {
                setLoadd(true);
                dispatch(makeStripePayment()).then((res) => {
                  if (res?.type === "MAKE_STRIPE_PAYMENT_ERROR") {
                    setMakePaymentLoading(false)
                    notification.error({
                      message: "Error in making payment",
                      className: "toast-error",
                    });
                  } else {
                    notification.success({
                      message: res?.payload?.message,
                      className: "toast-success",
                    });
                    setMakePaymentLoading(false)
                    setLoadd(true);
                    dispatch(stripeCardDetail()).then((res) => {
                      setLoadd(false)
                      // setLoading(false);
                    })
                  }
                });
              }
            })
          } else {
            notification.success({
              message: res?.messages,
              className: "toast-success",
            });
            setLoadd(true);
            dispatch(stripeCardDetail()).then((res) => {
              setLoadd(false)
              // setLoading(false);
            })
          }
        });
      }
    });
  };
  const makeDefaultCard = (id) => {
    Swal.fire({
      title: `Are you sure you want to set this card as default?`,
      icon: "warning",
      cancelButtonText: "No",
      showCancelButton: true,
      confirmButtonColor: "#052977",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes`,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(makeDefaultStripeCard(id)).then((res) => {
          if (res?.type === "DEFAULT_STRIPE_CARD_ERROR") {
            notification.error({
              message: "Error in card",
              className: "toast-error",
            });
          } else {
            notification.success({
              message: "Card set default successfully",
              className: "toast-success",
            });
            setLoadd(true);
            dispatch(stripeCardDetail()).then((res) => {
              setLoadd(false)
              // setLoading(false);
            })
          }
        });
      }
    });
  };

  const updateCard = (id) => {
    setUpdateLoading(true)
    console.log(cardDetails?.card?.exp_month, 'cardDetails?.card?.exp_month');
    dispatch(updateStripeCard(id, expiryMonth, expiryYear)).then((res) => {
      if (res?.type === "UPDATE_STRIPE_CARD_ERROR") {
        notification.error({
          message: "Error in saving card",
          className: "toast-error",
        });
      } else {
        notification.success({
          message: "Card updated successfully",
          className: "toast-success",
        });
        setExpiryMonth(0);
        setExpiryYear(0);
        setUpdateLoading(false)
        setLoadd(true);
        setModal(false)
        dispatch(stripeCardDetail()).then((res) => {
          setLoadd(false)
          // setLoading(false);
        })
      }
    });



  };

  const makePayment = () => {
    Swal.fire({
      title: `Are you sure you want to make previous payments?`,
      icon: "warning",
      cancelButtonText: "No",
      showCancelButton: true,
      confirmButtonColor: "#052977",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes`,
    }).then((result) => {
      if (result.isConfirmed) {
        setMakePaymentLoading(true)
        // dispatch(makeStripePayment()).then((res) => {
        //   if (res?.type === "MAKE_STRIPE_PAYMENT_ERROR") {
        //     setMakePaymentLoading(false)
        //     notification.error({
        //       message: "Error in making payment",
        //       className: "toast-error",
        //     });
        //   } else {
        //     notification.success({
        //       message: "Payment charged successfully",
        //       className: "toast-success",
        //     });
        //     setMakePaymentLoading(false)

        //     setLoadd(true);
        //     dispatch(stripeCardDetail()).then((res) => {
        //       setLoadd(false)
        //       // setLoading(false);
        //     })
        //   }
        // });
      }
    });
  };



  useEffect(() => {
    document.body.classList.add("bioshop-body", "shopper-bioshop-body");
  }, []);




  return (
    <>
      <div className="sales-page-main">
        <div className="title-area mb-3 pb-0">
          <h1 className="mb-0 pb-0">Payment</h1>
          {
            stripeCardDetails?.is_previous_due &&
            <Button
              loading={makePaymentLoading}
              onClick={() => makePayment()}
              disabled={makePaymentLoading}
              className="default-btn  padb-3 btn btn-primary" size="medium" style={{ marginRight: 8, height: 35 }}>
              Make Payment
            </Button>
          }

        </div>
        <div className="row">
          <div className="col-lg-6 col-xl-6">
            <div className="stream-box-white">
              {stripeTimer && <Alert message={stripeMessage} type={stripeStatus} closable />}
              <div className="card-information-store">
                {
                  loading ?
                    <span className="spinclass loader-center position-relative">
                      <Spin size="medium" />
                    </span>
                    :
                    <div className="add-card-detail" onClick={() => addCard()}>
                      <FontAwesomeIcon className="mr-3" icon={faPlus} />
                      Add Credit Card
                    </div>}
                {
                  stripeCardDetails?.loading || loadd ?
                    <span className="spinclass loader-center position-relative">
                      <Spin size="medium" />
                    </span>
                    :
                    <div className="added-cards d-block">
                      {
                        // stripeCardDetails?.payload?.is_payment_method &&
                        // <div className="add-card-detail all-cards" onClick={() => detailView()}>
                        //   <div>
                        //     <svg xmlns="http://www.w3.org/2000/svg" className="mr-3" height="20" width="22.5" viewBox="0 0 576 512"><path fill="#74C0FC" d="M470.1 231.3s7.6 37.2 9.3 45H446c3.3-8.9 16-43.5 16-43.5-.2 .3 3.3-9.1 5.3-14.9l2.8 13.4zM576 80v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h480c26.5 0 48 21.5 48 48zM152.5 331.2L215.7 176h-42.5l-39.3 106-4.3-21.5-14-71.4c-2.3-9.9-9.4-12.7-18.2-13.1H32.7l-.7 3.1c15.8 4 29.9 9.8 42.2 17.1l35.8 135h42.5zm94.4 .2L272.1 176h-40.2l-25.1 155.4h40.1zm139.9-50.8c.2-17.7-10.6-31.2-33.7-42.3-14.1-7.1-22.7-11.9-22.7-19.2 .2-6.6 7.3-13.4 23.1-13.4 13.1-.3 22.7 2.8 29.9 5.9l3.6 1.7 5.5-33.6c-7.9-3.1-20.5-6.6-36-6.6-39.7 0-67.6 21.2-67.8 51.4-.3 22.3 20 34.7 35.2 42.2 15.5 7.6 20.8 12.6 20.8 19.3-.2 10.4-12.6 15.2-24.1 15.2-16 0-24.6-2.5-37.7-8.3l-5.3-2.5-5.6 34.9c9.4 4.3 26.8 8.1 44.8 8.3 42.2 .1 69.7-20.8 70-53zM528 331.4L495.6 176h-31.1c-9.6 0-16.9 2.8-21 12.9l-59.7 142.5H426s6.9-19.2 8.4-23.3H486c1.2 5.5 4.8 23.3 4.8 23.3H528z" /></svg>
                        //     <span> **** **** ****4242</span>

                        //   </div>
                        //   <div className="d-flex ">
                        //     <div className="cross-btn-main">
                        //       <button className="cross-btn"
                        //       // onClick={(e) => removeMethod(item._id)}
                        //       > <i class="fa fa-times"></i></button>
                        //     </div>
                        //     <div className="cross-btn-main">
                        //       <button className="edit-btn"
                        //       // onClick={(e) => editMethod(item)}
                        //       > <i class="fa fa-edit"></i></button>
                        //     </div>
                        //   </div>
                        // </div>


                      }
                      {
                        // stripeCardDetails?.payload?.is_payment_method &&
                        //stripeCardDetails?.payload?.message?.data
                        stripeCardDetails?.payload?.is_payment_method && stripeCardDetails?.payload?.message?.map(cardDetail => (
                          <Card className="" style={{ marginBottom: 16 }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img
                                  src={`${cardDetail.card?.brand === "visa" ? "https://www.freepnglogos.com/uploads/visa-card-logo-9.png" : cardDetail.card?.brand === "mastercard" ? "https://www.logo.wine/a/logo/Mastercard/Mastercard-Logo.wine.svg" : "https://cdn-icons-png.flaticon.com/512/6963/6963703.png"}`} // Use appropriate icons for Visa/Mastercard
                                  alt={`${cardDetail.card?.brand} icon`}
                                  style={{ width: 24, height: 24, marginRight: 10, objectFit: "contain" }}
                                />
                                <div>
                                  <div>
                                    {/* {cardDetail.card?.brand.charAt(0).toUpperCase() + cardDetail.card?.brand.slice(1)}  */}
                                    **** **** **** {cardDetail.card?.last4}</div>
                                  <div style={{ fontSize: 'smaller' }}>Exp. date: {cardDetail.card?.exp_month + "/" + cardDetail.card?.exp_year}</div>
                                </div>
                              </div>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                {cardDetail.isDefault ? (
                                  <Tag className="stripe_default_tag" style={{ marginRight: 8 }}>Default</Tag>
                                ) : (
                                  <Button onClick={() => makeDefaultCard(cardDetail?.id)} className="default-btn medium-btn padb-3 btn btn-primary" size="small" style={{ marginRight: 8, height: 28 }}>Set as Default</Button>
                                )}

                                <Button onClick={() => detailView(cardDetail)} icon={<EditOutlined style={{ fontSize: "14px" }} />} shape="circle" style={{ marginRight: 8, background: "#052977", color: "white" }} />

                                <Tooltip className="stripe-disable-btn" placement="top" title={stripeCardDetails?.payload?.is_previous_due ? "Your previous payment is due, please make your payment to withdraw your card" : ""}>
                                  <Button disabled={stripeCardDetails?.payload?.is_previous_due || cardDetail.isDefault} onClick={() => removeCard(cardDetail?.id)} icon={<DeleteOutlined style={{ fontSize: "14px" }} />} shape="circle" style={{ background: "#fa1313", borderColor: "#fa1313", color: "white" }} />
                                </Tooltip>
                              </div>
                            </div>
                          </Card>
                        ))}


                    </div>
                }
              </div>
            </div>
          </div>
        </div>

        {stripeCardDetails?.payload?.is_payment_method && <p class="payment-note-para note-txt fw-bold">Note:  Your credit card will be charged once your total ad spend reaches ${stripeCardDetails?.payload?.threshold}.
          <Tooltip overlayClassName="payment-note-tooltip" className="payment-note" placement="right" title={`This means that once the cumulative total of advertising expenses reaches $${stripeCardDetails?.payload?.threshold}, your credit card will be automatically charged for that amount. For any questions or assistance, contact our support team.`}>
            <InfoCircleOutlined />
          </Tooltip>
        </p>}

        {/* <div className="brand_container_main aff-payment">
          <Form form={form}>
            <div className="row w-20-desktop-main">

              <div className="col-xl-3 col-md-6 col-12 mb-15">
                <p className="mb-5rem">Select Start Date / End Date</p>
                <RangePicker
                  size="large"
                  className="w-100"
                  key={4}
                  value={
                    startDate && endDate
                      ? [moment(startDate), moment(endDate)]
                      : []
                  }
                  allowClear={false}
                  ranges={{
                    "All Time": [
                      moment().subtract(1, "year").startOf("year"),
                      moment(),
                    ],
                    "YTD": [moment().startOf("year"), moment()],
                    "Last 30 Days": [moment().subtract(30, "days"), moment()],
                    "Last 7 Days": [moment().subtract(7, "days"), moment()],
                    "Today": [moment(), moment()],

                  }}
                  format={dateFormat}
                  onChange={dateRangePickerChanger}
                />
              </div>

              <div className="col-xl-3 col-md-6 col-12 mb-15">
                <p className="mb-5rem">Status</p>

                <Select
                  showSearch
                  name="sort"
                  size="large"
                  placeholder="Select Status"
                  className="w-100 select-style dark-field"
                  optionFilterProp="children"
                  value={filterStatus}
                  defaultValue=""
                  onChange={(value) => selectStatus(value)}
                  // onSearch={onSearch}
                  filterOption={filterOption}
                  // options={getAllInfluencers?.message && [
                  //   ...getAllInfluencers?.message].map((influencer) => {
                  //     return ({
                  //       label: influencer?.name + " - " + influencer?.pixel_id + " - " + influencer?.email,
                  //       value: influencer?.user_id
                  //     }
                  //     )
                  //   })
                  // }
                  options={[
                    { value: '', label: 'All' },
                    { value: 'active', label: 'Active' },
                    { value: 'pending', label: 'Scheduled' },
                    { value: 'cancelled', label: 'De-Activated' },
                  ]}
                />
              </div>




              <div className="col-xl-3 col-md-6 col-12 d-flex align-items-end mb-15">
                <Button
                  onClick={handleSubmit}
                  className="default-btn fltr-hpr  medium-btn m-width-100 ml-0 fw-normal"
                  type="primary"
                  htmlType="submit"
                >
                  Search
                </Button>
                <Button
                  onClick={handleReset}
                  className="default-btn outline fw-normal fltr-hpr medium-btn  res-btn2 min-w-100"
                  type="primary"
                  htmlType="submit"
                >
                  Reset
                </Button>
              </div>
            </div>



          </Form>
          <hr className="separator-line mt-0" />
          <div className="purchase-data referral-data">
            {loading ? (
              // <div className="antd-loading-ift">
              // 	<Spin size="large" />
              // </div>
              <div className="loading-wrap">
                <span className="spinclass loader-center position-relative">
                  <Spin size="large" />
                </span>
              </div>
            ) : (
              <>
                {submit === "none" && <DefaultTable />}

                <div className="purchase-data referral-data">
                  {boostedReviews?.payload?.message?.data?.length ? (
                    <ReactPaginate
                      nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
                      // pageCount={Math.ceil(boostedReviews?.payload?.message?.total_records / limit)}
                      pageCount={
                        boostedReviews?.payload?.message?.total_records > 0 ? Math.ceil(boostedReviews?.payload?.message?.total_records / limit) : 0
                      }
                      forcePage={currentPage}
                      previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                      pageClassName="page-item d-none"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      breakClassName="page-item d-none"
                      breakLinkClassName="page-link"
                      containerClassName={"pagination custom-paginate"}
                      activeClassName="active"
                      renderOnZeroPageCount={null}
                    />
                  ) : (
                    ""
                  )}
                </div>

              </>
            )}
          </div>
        </div> */}
      </div>





      <Modald
        maskClosable={false}
        className="modal-generic modal-500"
        centered
        visible={modal}
        onCancel={() => setModal(false)}
        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        footer={false}
      >
        <h2 className="modal-hd1">Card Detail</h2>

        {load ?
          <span className="spinclass loader-center position-relative">
            <Spin size="medium" />
          </span>
          :
          <div className="form-styling ">

            <div className="row justify-content-center mb-20">
              <div className="col">
                <p className="mb-2">Card Number</p>
                <InputNumber
                  className="w-100"
                  size="large"
                  disabled
                  // defaultValue={cardNo}
                  value={"**** **** **** " + cardDetails?.card?.last4}
                // onChange={(e) => {
                //   setAdCost(e);
                // }}
                // formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                // parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                />
              </div>
            </div>
            <div className="row justify-content-center mb-20">
              <div className="col">
                <p className="mb-2">Expiry Month</p>
                <InputNumber className="w-100 custom-month-picker"
                  picker="month"
                  format="MM"
                  defaultValue={cardDetails?.card?.exp_month}
                  value={expiryMonth}
                  size="large"
                  min={1}
                  max={12}
                  onChange={(e) => setExpiryMonth(e)}
                />
              </div>
              <div className="col">
                <p className="mb-2">Expiry Year</p>
                <InputNumber className="w-100"
                  picker="year"
                  format="YYYY"
                  defaultValue={cardDetails?.card?.exp_year}
                  value={expiryYear}
                  size="large"
                  onChange={(e) => setExpiryYear(e)}
                />
              </div>
            </div>

            <div className="d-flex justify-content-end">
              <Button
                onClick={() => updateCard(cardDetails?.id)}
                loading={updateLoading}
                disabled={updateLoading}
                className={`default-btn padb-3 d-inline-flex align-items-center justify-content-center `}
              >
                <span className="nav-text">Update</span>
              </Button>
              {/* <Button
                className={`default-btn padb-3 d-inline-flex align-items-center justify-content-center `}
              >
                <span className="nav-text">Set as Default Card</span>
              </Button> */}
              <Button
                className="default-btn padb-3 outline d-inline-flex align-items-center justify-content-center mr-0"
                key="back"
                onClick={() => { setCardDetails(); setExpiryMonth(0); setExpiryYear(0); setModal(false); }}
              >
                Cancel
              </Button>
            </div>
          </div>}
      </Modald>
    </>
  );
};
