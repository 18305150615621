import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Routes,
  Route,
  useNavigate,
  unstable_HistoryRouter as HistoryRouter,
  useParams,
} from "react-router-dom";
import Login from "../pages/login/login";
import Menus from "./menus";
import { createBrowserHistory } from "history";
import ForgotPassword from "../pages/forgot-password/forgotPassword";
import ResetPassword from "../pages/reset-password/resetPassword";
import PublicHome from "../pages/public/public-home";
import PublicAbout from "../pages/public/public-about";
import PublicPackage from "../pages/public/public-package";
import PublicPrivacyPolicy from "../pages/public/public-privacy-policy";
import PublicTermsUse from "../pages/public/public-terms-use";
import PublicContactUs from "../pages/public/public-contact-us";
import Register from "../pages/register/register";
import ScrollToTop from "../components/scrollTop/scrollTop";
import { validateUser } from "../redux/actions/login.action";
import { useDispatch, useSelector } from "react-redux";
import { BASEURL } from "../config";
import axios from "axios";
import SkeletonComponent from "../components/SkeletenLoader";
import { getSocialFollowers } from "../redux/actions/socialFollowers.action ";
import Payment from "../pages/public/payment";
import { getProducts } from "../redux/actions/shopify.action";
import moment from "moment";
import { getShowRecordedEvents, showErrMessage } from "../redux/actions/event.action";
import { getInfluencerReview, showErrMessageReviews } from "../redux/actions/influencerReview";
import { getInstaReels } from "../redux/actions/instagramReels.action";
import { store } from "../redux/store";
import { GET_INSTA_DATA, RESET_INSTA_STATE, AUTHENTICATE } from "../redux/types/types";
import { checkInstaConn } from "../redux/actions/instagram.action";
import Agreement from "../pages/public/agreement";
import ConnectStripe from "../pages/account/stripe/connectStripe";
import DirectAuth from "../pages/public/direct-auth";

const history = createBrowserHistory();
const token = JSON.parse(localStorage.getItem("token"));
let publicIP;
const Router = () => {
  let query = new URL(window.location.href);
  const [nextParams, setNextParams] = useState(null);
  let searchParams = new URLSearchParams(query.search);
  const { validate, uploadDetail, uploadDetailReview, instaReelsData, hitInstaAPI } = useSelector((state) => { return state })
  const [load, setLoad] = useState(true)
  const [hit, setHit] = useState(false)
  const dispatch = useDispatch();
  // useEffect(() => {
  //   init();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const init = async () => {
  //   publicIP = await getPublicIP()
  // };
  

  useEffect(() => {
    if (window.location.href.includes("payment") && searchParams.get("status") === "success") {
      setLoad(false)
    }
    else if (token && !window.location.href.includes("auth")) {
      dispatch(validateUser(validate?.payload?.message?.token)).then((res) => {
        if (res.success) {
          setLoad(false)
        } else {
          setLoad(false)
        }
      })
    } else {
      setLoad(false)
    }
  }, [])
 
  // console.log(publicIP,"publicIP");
  const uploadLocal = JSON.parse(localStorage.getItem('uploads'))
  const uploadReviews = JSON.parse(localStorage.getItem('reviews'))

  useEffect(() => {
    if (uploadDetail?.length > 0 || uploadDetailReview?.length > 0) {
      window.addEventListener('beforeunload', alertUser)
    } else {
      // localStorage.removeItem('uploads')
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [uploadDetail, uploadDetailReview])

  const alertUser = useCallback((e) => {
    e.preventDefault()
    e.returnValue = true;
  }, [])

  useEffect(() => {
    if (uploadLocal) {
      if (uploadLocal?.length > 0) {
        let body = uploadLocal?.map(m => ({ show_s3_id: m.key, error_reason: "Upload Failed due to page refresh" }))
        dispatch(showErrMessage(body, validate?.payload?.message?.token)).then((res) => {
          if (res.success) {
            localStorage.removeItem('uploads')
            getShowRecordedEvents(
              8,
              1,
              '',
              'date',
              moment().startOf('month').format('YYYY-MM-DD'),
              moment().format('YYYY-MM-DD'),
              ''
            )
          }
        })
      }
    }
    if (uploadReviews) {
      if (uploadReviews?.length > 0) {
        let body = uploadReviews?.map(m => ({ review_s3_id: m.key, error_reason: "Upload Failed due to page refresh" }))
        dispatch(showErrMessageReviews(body, validate?.payload?.message?.token)).then((res) => {
          if (res.success) {
            localStorage.removeItem('reviews')
            getInfluencerReview(
              "active",
              1,
              8,
              moment().startOf('month').format('YYYY-MM-DD'),
              moment().format('YYYY-MM-DD'),
              '',
              'date'
            )
          }
        })
      }
    }
  }, [])
  // const memoizedResult = useMemo(() => {
  //   // if (validate?.payload?.success && validate?.payload?.message?.package) {
  //     //   dispatch(getSocialFollowers(validate?.payload?.message?._id))
  //     // }
  //     if (validate?.payload?.message?.token) {
  //       dispatch(getProducts(validate?.payload?.message?.token))
  //     }
  //   }, [validate]);
  useEffect(() => {
    if (validate?.payload?.message?.token) {
      if (hit) {
        instaApi()
      }
    }
  }, [validate, nextParams, hit])
  useEffect(() => {
    if (validate?.payload?.message?.token && validate?.payload?.message?.agreement === "accepted" && (validate?.payload?.message?.is_stripe_account_connected === true || !validate?.payload?.message?.contract?.is_stripe_connect_user)) {
      dispatch(checkInstaConn()).then((res) => {
        setHit(res?.data?.connection)
      })
      setNextParams()

    }
  }, [validate])

  useEffect(() => {
    setNextParams()
    store.dispatch({ type: RESET_INSTA_STATE });
  }, [hitInstaAPI])
  const instaApi = () => {
    // dispatch(getInstaReels(nextParams ? nextParams : '', '')).then(
    //   (res) => {
    //     if (res?.success) {
    //       if (res?.nextCursor) {
    //         setNextParams(res?.nextCursor);
    //       }
    //     }
    //   })
  }
  axios.defaults.baseURL = BASEURL;
  axios.defaults.headers.common['Authorization'] = `Bearer ${validate?.payload?.message?.token}`;
  // axios.defaults.headers.common['public-ip'] = publicIP ? publicIP : "";
  axios.interceptors.request.use(request => {
    return request;
  }, error => {
    return Promise.reject(error);
  });

  function getPublicIP(){
    return new Promise((resolve, reject) => {
        const pc = new RTCPeerConnection({
            iceServers: [{ urls: 'stun:stun.l.google.com:19302' }] // STUN server URL
        });
        pc.createDataChannel('');
        pc.createOffer()
            .then(sdp => {
                pc.setLocalDescription(sdp);
            })
            .catch(error => {
                reject(error);
            });
        pc.onicecandidate = event => {
            if (event.candidate) {
                const candidate = event.candidate.candidate;
                const regex = /([0-9]{1,3}(\.[0-9]{1,3}){3})/;
                const match = regex.exec(candidate);
                if (match && match.length >= 1) {
                    resolve(match[0]);
                    pc.onicecandidate = null;
                    pc.close();
                }
            }
        };
    });
  }


  function Redirect({ to }) {
    let navigate = useNavigate();
    useEffect(() => {
      navigate(to);
    });
    return null;
  }

  return (
    <>
      <HistoryRouter history={history}>
        {load ? <SkeletonComponent /> : (!validate?.payload?.success ? (
          <ScrollToTop>
            <Routes>
              <Route path="/" element={<Login setHit={setHit} />} />
              <Route path="/about" element={<PublicAbout />} />
              {/* <Route path="/public-package" element={<PublicPackage />} /> */}
              <Route path="/payment" element={<Payment />} />
              <Route path="/privacy-policy" element={<PublicPrivacyPolicy />} />
              <Route path="/terms-use" element={<PublicTermsUse />} />
              <Route path="/contact-us" element={<PublicContactUs />} />
              <Route path="/login" element={<Login />} />
              <Route path="/auth/:token" element={<DirectAuth />} />
              {/* <Route path="/register" element={<Register />} /> */}
              <Route
                path="/forgot-password"
                exact
                element={<ForgotPassword />}
              />
              <Route
                path="/password-reset/:id"
                exact
                element={<ResetPassword />}
              />
              <Route path="*" element={<Redirect to="/" />} />
            </Routes>
          </ScrollToTop>
        ) : (

          validate?.payload?.message?.agreement === "waiting" || validate?.payload?.message?.agreement === "rejected" ?
            <ScrollToTop>
              <Routes>
                <Route path="/agreement" element={<Agreement />} />
                <Route path="/" element={<Agreement />} />
                <Route path="/auth/:token" element={<DirectAuth />} />
                <Route path="*" element={<Redirect to="/" />} />
              </Routes>
            </ScrollToTop>
            :
            validate?.payload?.message?.is_stripe_account_connected === false && validate?.payload?.message?.contract?.is_stripe_connect_user ?
              <ScrollToTop>
                <Routes>
                  <Route path="/connect-stripe" element={<ConnectStripe />} />
                  <Route path="/" element={<Redirect to="/connect-stripe" />} />
                  <Route path="/auth/:token" element={<DirectAuth />} />
                  <Route path="*" element={<Redirect to="/connect-stripe" />} />
                </Routes>
              </ScrollToTop>
              :
              <Menus />
        ))
        }
      </HistoryRouter>
    </>
  );
};
export default Router;
