import { CUSTOMURL } from '../../config';
import {
  GET_SHOPIFY_DETAIL_REQUEST,
  GET_SHOPIFY_DETAIL_SUCCESS,
  GET_SHOPIFY_DETAIL_ERROR,
  GET_PRODUCT_REQUEST,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_ERROR,
  POST_SHOPIFY_REQUEST,
  POST_SHOPIFY_SUCCESS,
  POST_SHOPIFY_ERROR,
  POST_SALESFORCE_REQUEST,
  POST_SALESFORCE_SUCCESS,
  POST_SALESFORCE_ERROR,
  GET_SALESFORCE_DETAIL_REQUEST,
  GET_SALESFORCE_DETAIL_SUCCESS,
  GET_SALESFORCE_DETAIL_ERROR,
  POST_MAGENTO_REQUEST,
  POST_MAGENTO_SUCCESS,
  POST_MAGENTO_ERROR,
  GET_MAGENTO_DETAIL_REQUEST,
  GET_MAGENTO_DETAIL_SUCCESS,
  GET_MAGENTO_DETAIL_ERROR,
  GENERATE_KEY_REQUEST,
  GENERATE_KEY_SUCCESS,
  GENERATE_KEY_ERROR,
  POST_CUSTOM_SHOP_REQUEST,
  POST_CUSTOM_SHOP_SUCCESS,
  POST_CUSTOM_SHOP_ERROR,
  GET_CUSTOM_SHOP_REQUEST,
  GET_CUSTOM_SHOP_SUCCESS,
  GET_CUSTOM_SHOP_ERROR,
  POST_CUSTOM_PRODUCT_REQUEST,
  POST_CUSTOM_PRODUCT_SUCCESS,
  POST_CUSTOM_PRODUCT_ERROR,
  POST_SITECORE_REQUEST,
  POST_SITECORE_SUCCESS,
  POST_SITECORE_ERROR,
  GET_SITECORE_DETAIL_REQUEST,
  GET_SITECORE_DETAIL_SUCCESS,
  GET_SITECORE_DETAIL_ERROR,
} from '../types/types';
import axios from 'axios';
const token = JSON.parse(localStorage.getItem('token'));

export const getShopifyDetail = () => (dispatch) => {
  dispatch({ type: GET_SHOPIFY_DETAIL_REQUEST });

  return axios({
    method: 'GET',
    url: `users/receive/shopify`,
    // data: {
    // 	parent_id: id,
    // },
  })
    .then((response) => {
      return dispatch({
        type: GET_SHOPIFY_DETAIL_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_SHOPIFY_DETAIL_ERROR,
        payload: error.response,
      });
    });
};

export const getSalesForceDetail = () => (dispatch) => {
  dispatch({ type: GET_SALESFORCE_DETAIL_REQUEST });

  return axios({
    method: 'GET',
    url: `users/receive/sfcc`,
    // data: {
    // 	parent_id: id,
    // },
  })
    .then((response) => {
      return dispatch({
        type: GET_SALESFORCE_DETAIL_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_SALESFORCE_DETAIL_ERROR,
        payload: error.response,
      });
    });
};

export const getProducts = (TOKEN) => (dispatch) => {
  dispatch({ type: GET_PRODUCT_REQUEST });

  return axios({
    method: 'POST',
    url: `ecom/getproducts`,
    // data: {
    // 	parent_id: id,
    // },
    headers: { Authorization: `Bearer ${TOKEN ? TOKEN : token}` }
  })
    .then((response) => {
      return dispatch({
        type: GET_PRODUCT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_PRODUCT_ERROR,
        payload: error.response,
      });
    });
};

export const postShopify = (data) => (dispatch) => {
  dispatch({ type: POST_SHOPIFY_REQUEST });
  return axios({
    method: 'POST',
    url: `users/revise/shopify`,
    data: data,
  })
    .then((response) => {
      return dispatch({
        type: POST_SHOPIFY_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: POST_SHOPIFY_ERROR,
        payload: error.response,
      });
    });
};

export const postSalesForce = (data) => async (dispatch) => {
  let promise = new Promise((resolve, reject) => {
    axios
      .post(`users/revise/sfcc`, {
        host: data.host,
        site_id: data.site_id,
        api_version: "v22_6",
        client_id: data.client_id,
        client_secret: data.client_secret,
        am_username: data.am_username,
        am_password: data.am_password

      })
      .then((res) => {
        resolve(res.data);
        dispatch({
          type: POST_SALESFORCE_SUCCESS,
          payload: res.data,
        });
      })
      .catch((res) => {
        resolve(res?.response?.data)
        dispatch({
          type: POST_SALESFORCE_ERROR,
          payload: res?.response?.data,
        });

      });
  });
  return promise;
};

export const postMagento = (data) => (dispatch) => {
  dispatch({ type: POST_MAGENTO_REQUEST });
  return axios({
    method: 'POST',
    url: `users/revise/magento`,
    data: data,
  })
    .then((response) => {
      return dispatch({
        type: POST_MAGENTO_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: POST_MAGENTO_ERROR,
        payload: error.response,
      });
    });
};

export const getMagentoDetail = () => (dispatch) => {
  dispatch({ type: GET_MAGENTO_DETAIL_REQUEST });

  return axios({
    method: 'GET',
    url: `users/receive/magento`,
  })
    .then((response) => {
      return dispatch({
        type: GET_MAGENTO_DETAIL_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_MAGENTO_DETAIL_ERROR,
        payload: error.response,
      });
    });
};

export const postSiteCore = (data) => (dispatch) => {
  dispatch({ type: POST_SITECORE_REQUEST });
  return axios({
    method: 'POST',
    url: `users/revise/scoc`,
    data: data,
  })
    .then((response) => {
      return dispatch({
        type: POST_SITECORE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: POST_SITECORE_ERROR,
        payload: error.response,
      });
    });
};

export const getSiteCoreDetail = () => (dispatch) => {
  dispatch({ type: GET_SITECORE_DETAIL_REQUEST });

  return axios({
    method: 'GET',
    url: `users/receive/scoc`,
    // data: {
    // 	parent_id: id,
    // },
  })
    .then((response) => {
      return dispatch({
        type: GET_SITECORE_DETAIL_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_SITECORE_DETAIL_ERROR,
        payload: error.response,
      });
    });
};

export const generateKey = () => (dispatch) => {
  dispatch({ type: GENERATE_KEY_REQUEST });
  return axios({
    method: 'POST',
    url: `${CUSTOMURL}user/keygenerate`,
  })
    .then((response) => {
      return dispatch({
        type: GENERATE_KEY_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GENERATE_KEY_ERROR,
        payload: error.response,
      });
    });
};

export const postCustomShop = (data) => (dispatch) => {
  dispatch({ type: POST_CUSTOM_SHOP_REQUEST });
  return axios({
    method: 'POST',
    url: `${CUSTOMURL}user/products/connectshop`,
    data: data,
  })
    .then((response) => {
      return dispatch({
        type: POST_CUSTOM_SHOP_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: POST_CUSTOM_SHOP_ERROR,
        payload: error.response,
      });
    });
};


export const getCustomShopDetail = () => (dispatch) => {
  dispatch({ type: GET_CUSTOM_SHOP_REQUEST });
  return axios({
    method: 'POST',
    url: `${CUSTOMURL}user/products/getshop`,
  })
    .then((response) => {
      return dispatch({
        type: GET_CUSTOM_SHOP_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_CUSTOM_SHOP_ERROR,
        payload: error.response,
      });
    });
};

export const addProduct = (data) => (dispatch) => {
  dispatch({ type: POST_CUSTOM_PRODUCT_REQUEST });
  return axios({
    method: 'POST',
    url: `${CUSTOMURL}user/products/addproducts`,
    data: data,
  })
    .then((response) => {
      return dispatch({
        type: POST_CUSTOM_PRODUCT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: POST_CUSTOM_PRODUCT_ERROR,
        payload: error.response,
      });
    });
};