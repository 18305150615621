import {
  BAN_USER_LIST
} from '../types/types';
import axios from 'axios';

export const bannedUsers = (ban_type, user_id) => (dispatch) => {

  return axios({
    method: 'POST',
    url: `users/chat/getbanuserlist?page=1&limit=50`,
    // headers: { Authorization: `Bearer ${userInfo?.token}` },
    data: {
      ban_type,
      user_id
    },
  })
    .then((response) => {
      dispatch({
        type: BAN_USER_LIST,
        payload: response.data,
      });
      return response.data
    })
    .catch((error) => {
      return error
    });
};
