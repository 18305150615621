import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { authUser, validateUser } from "../../redux/actions/login.action";
import { Spin, notification } from 'antd';
import { store } from "../../redux/store";
import { AUTHENTICATE } from "../../redux/types/types";
import { getProducts } from "../../redux/actions/shopify.action";

export default function DirectAuth() {
  const [loader, setLoader] = useState(true);
  const history = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const token = params?.token;
  const { validate } = useSelector((state) => { return state })

  const notificationKey = `open${Date.now()}`;
  const load = <div className="d-flex"><span className="spin-color mx-2">
    <Spin size="small" />
  </span><p>Your products are syncing</p></div>;

  useEffect(() => {
    localStorage.removeItem('token');
    if (!validate?.payload?.message?.token) {
      dispatch(authUser(token)).then((res) => {
        if (res.success) {
          dispatch(validateUser(token)).then((resp) => {
            localStorage.setItem("token", JSON.stringify(resp?.message?.token));
            // notification.open({
            //   message: 'Product Syncing...',
            //   description: load,
            //   key: notificationKey,
            //   duration: 0,
            // })
            // dispatch(getProducts(res?.message?.token))
            //   .then((res) => {
            //     if (res?.type === "GET_PRODUCT_ERROR") {
            //       notification.destroy({ key: notificationKey })
            //       notification.error({
            //         message: res?.payload?.data?.message,
            //         className: "toast-error",
            //       });
            //     } else {
            //       notification.destroy({ key: notificationKey })
            //       notification.success({
            //         message: res?.payload?.message,
            //         className: "toast-success",
            //       });
            //     }
            //   })
            //   .catch((err) => {
            //     notification.destroy({ key: notificationKey })
            //     notification.error({
            //       message: "Sync Failed",
            //       className: "toast-error",
            //     });
            //     console.log(err.response, "err");
            //   })
          })
          setLoader(false)
          notification.success({
            message: res?.message,
            className: 'toast-success',
          });
          setTimeout(() => {
            history('/home');
          }, 500)
        } else {
          setLoader(false)

          notification.error({
            message: res?.data?.message,
            className: 'toast-error',
          });
          localStorage.removeItem("token");
          localStorage.removeItem("Role");
          store.dispatch({ type: AUTHENTICATE, payload: {} })
          history('/login');
        }
      })
    }


  }, [])

  return (
    <>
      {/* {!validate?.payload?.success && <PublicHeader />} */}

      <div className={`general-section`}>
        <div className="mid-width max-1140">
          {loader &&
            <span className="spinclass loader-center position-relative">
              <Spin size="large" />
            </span>
          }
        </div>
      </div>
    </>
  );
}
