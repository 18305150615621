import React, { useEffect, useMemo, useRef, useState } from "react";
import { CalendarFilled, ExclamationCircleOutlined } from "@ant-design/icons";
import {
  faAngleLeft,
  faCaretRight,
  faUser,
  faCloudArrowUp,
  faUserTie,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Affix,
  Button,
  Checkbox,
  DatePicker,
  Form,
  Image,
  Input,
  InputNumber,
  message,
  Modal,
  notification,
  Select,
  Space,
  Spin,
  Steps,
  Switch,
  Table,
  Tabs,
  Upload,
} from "antd";
import moment from "moment";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import ReactRouterPrompt from "react-router-prompt";
import { useStepsForm } from "sunflower-antd";
import step1 from "../../images/step1.svg";
import step2 from "../../images/step2.svg";
import step3 from "../../images/step3.svg";
import previewImg from "../../images/upload-banner.png";
import {
  generateVideoThumbnails,
  generateVideoThumbnailViaUrl,
  importFileandPreview,
} from "@rajesh896/video-thumbnails-generator";
import {
  getEventsBy_Id,
  getShowBy_Id,
  postEvent,
  UpdateEvents,
  postShow,
  UpdateShows,
} from "../../redux/actions/event.action";
import { getPromoRequest } from "../../redux/actions/promoRequest";
import SkeletonComponent from "../../components/SkeletenLoader";
import AsyncProduct from "../create-event/scheduleEvent/asyncProduct";
import ReactPlayer from "react-player";
// import { getParentCategories } from "../../redux/actions/category.action";
import {
  getSavedGeneralCategories,
  getSavedCategories,
  getSavedSubCategories,
} from "../../redux/actions/newCategory.action";
import S3FileUpload from "react-s3";
import { UploadOutlined } from "@ant-design/icons";
import { REACT_S3, UPLOAD_DONE, UPLOADS3 } from "../../redux/types/types";
import AWS from "aws-sdk";
import { Percent, SetPercent } from "../../context/PercentProvider";
import { useContext } from "react";
import { getFeeStructure } from "../../redux/actions/feestructure.action";
import { searchBySkuAndTitle } from "../../redux/actions/inventoryActive.action";
window.Buffer = window.Buffer || require("buffer").Buffer;

const { Step } = Steps;
const { Option } = Select;

export default function ScheduleShow() {
  const { confirm } = Modal;
  const s3 = new AWS.S3();
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const history = useNavigate();
  const {
    parentCategory,
    promoRequest,
    validate,
    savedGeneralCategories,
    getStructureFees,
    getInventoryProductsBySkuAndTitle
  } = useSelector((state) => {
    return state;
  });

  const params = useParams();
  const id = params.id;

  let query = new URL(window.location.href);
  let searchParams = new URLSearchParams(query.search);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const setPercent = useContext(SetPercent);
  const [streamModal, setIsModalOpenStream] = useState(false);
  const [isModalOpenDetail, setIsModalOpenDetail] = useState(false);
  const [isCheckedOpen, setIsCheckedOpen] = useState(false);
  const [dataDetails, setDataDetail] = useState([]);
  const [referralPercent, setReferralPercent] = useState("0");
  const [discount, setDiscount] = useState("");
  const [influencer_percent, setInfluencer_percent] = useState("0");
  const [promo, setPromo] = useState("");
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [flag, setFlag] = useState(false);
  const [defaultBanner, setDefaultBanner] = useState(false);
  const [videoRatio, setRatio] = useState("16:9");
  const [fileList, setFileList] = useState([]);
  const [contentType, setContentType] = useState("");
  const [dateTime, setDate] = useState("");
  const [formState, setFormState] = useState("add");
  const [published, setPublished] = useState(false);
  const [sku, setSku] = useState([]);
  const [host, setHost] = useState([]);
  const [host2, setHost2] = useState([]);
  const [host3, setHost3] = useState([]);
  const [host4, setHost4] = useState([]);
  const [skuError, setSkuError] = useState(false);
  const [parent, setParent] = useState("");
  const [promptModal, setPromptModal] = useState(true);
  const [vidMsg, setVidMsg] = useState(false);
  const [multipleHosts, setMultipleHosts] = useState(false);
  const [imageUpload, setImageUpload] = useState(false);
  const [sampleBanner, setSampleBanner] = useState(false);
  const [banner, setBanner] = useState("");
  const [recURL, setRecURL] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const [eventTitle, setEventTitle] = useState("");
  const [note, setEventNote] = useState("");
  const [thankyou, setEventThankyou] = useState("");
  const [infoTitle, setInfoTitle] = useState("");
  const [infoDesc, setInfoDesc] = useState("");
  const [platform, setPlatform] = useState(false);
  const [streamUrl, setStreamUrl] = useState("local");
  const [externalUrl, setExternalUrl] = useState("");
  const [productPromoCodeDscs, setProductPromoCodeDscs] = useState("0%");
  const [productPromoCodePromo, setproductPromoCodePromo] = useState("KB0");
  const [orientation, setOrientation] = useState("");
  const [flagg, setFlagg] = useState(false);
  const [fields, setFields] = useState({
    image: "",
  });
  const [imageThumb, setImageThumb] = useState("");
  const [ext, setExt] = useState({});

  const [gender, setGender] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [genLoad, setGenLoad] = useState(true);
  const [catLoad, setCatLoad] = useState(false);
  const [catSubLoad, setSubCatLoad] = useState(false);
  const [bannerExists, setBannerExists] = useState(false);

  const selectRef = useRef();

  const [video, setVideo] = useState();
  const [thumbNumber, setThumbNumber] = useState(0);
  const [picCount, setPicCount] = useState(0);
  const [videoUrl, setVideoUrl] = useState("");
  const [videoThumb, setVideoThumb] = useState("");
  const [thumbnails, setThumbnails] = useState([]);
  const [thumbnailLoader, setThumbnailLoader] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [msg, setMsg] = useState(false);
  const refs = useRef({
    video: null,
    loader: null,
    numberInput: null,
    thumbButton: null,
  });

  useEffect(() => {
    if (video) {
      importFileandPreview(video).then((res) => {
        setVideoUrl(res);
      });
      setVideoThumb("");
      setThumbnails([]);
      if (refs.current.video) {
        refs.current.video.style.transform = "scale(1)";
      }

      if (refs.current.numberInput) {
        refs.current.numberInput.style.display = "block";
      }
      if (refs.current.thumbButton) {
        refs.current.thumbButton.style.display = "block";
      }
    }
  }, [video]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleOkDetail = () => {
    setIsModalOpenDetail(false);
  };

  const handleOkStream = () => {
    setIsModalOpenStream(false);
  };

  const handleCancelStream = () => {
    setIsModalOpenStream(false);
  };

  const OpenSubmit = () => {
    setIsCheckedOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);

    history("/events");
  };

  const handleCancelDetail = () => {
    setIsModalOpenDetail(false);
  };

  const handleCancelInfo = () => {
    setIsCheckedOpen(false);
    setPlatform(false);
  };

  const handleOkInfo = () => {
    setIsCheckedOpen(false);
  };

  const onToggle = (record, recInd) => {
    if (record?.is_active == true) {
      record.is_active = false;
      let obj = record;
      const key = { ...obj };
      key.is_active = false;

      setSku((current) =>
        current?.map((obj, index) => {
          if (index == recInd) {
            return { ...obj, is_active: false };
          }
          return obj;
        })
      );
    } else {
      record.is_active = true;
      let obj = record;
      const key = { ...obj };
      key.is_active = true;
      setSku((current) =>
        current?.map((obj, index) => {
          if (index == recInd) {
            return { ...obj, is_active: true };
          }
          return obj;
        })
      );
    }
    // record.is_active = checked;
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      width: 60,
      render: (text, record, index) => {
        return <p>{index + 1}</p>;
      },
    },
    {
      title: "SKU",
      dataIndex: "ProductSku",
      key: "ProductSku",
      width: 60,
    },
    {
      title: "IMAGE",
      dataIndex: "mediaUrl",
      key: "mediaUrl",
      width: 70,
      render: (text, record) => {
        return (
          <div className="listing-image inventory-image">
            <Image src={`${record.mediaUrl}`} />
          </div>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "productDesc",
      key: "productDesc",
      width: 250,
      render: (text, record) => {
        return <div className="desc-limit">{record.productDesc}</div>;
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      width: 80,
      render: (text, record) => {
        return <div className="desc-limit">${record.price}</div>;
      },
    },

    {
      title: "Balance",
      dataIndex: "event",
      key: "event",
      width: 140,
      render: (text, record) => (
        <>
          <div className="balance-area">
            <div className="balance-count">
              {record?.variants?.reduce((acc, item) => {
                return (acc = acc + item.inventory_quantity);
              }, 0)}
            </div>
            <div className="balance-area">
              <a
                className="balance-btn default-btn outline small-btn"
                onClick={() => {
                  showModalDetail(record);
                }}
              >
                View
                {/* <FontAwesomeIcon icon={faEye} /> */}
              </a>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "event",
      key: "event",
      width: 100,
      render: (text, record) => {
        let status = record?.variants?.reduce((acc, item) => {
          return (acc = acc + item.inventory_quantity);
        }, 0);
        if (status >= 70) {
          return (
            <div className="status-info">
              <div className="status-flag green"></div>
              {/* <p>green</p> */}
            </div>
          );
        } else if (status >= 50 && status <= 70) {
          return (
            <div className="status-info">
              <div className="status-flag yellow"></div>
              {/* <p>yellow</p> */}
            </div>
          );
        } else if (status <= 50) {
          return (
            <div className="status-info">
              <div className="status-flag red"></div>
              {/* <p>red</p> */}
            </div>
          );
        } else {
          return null;
        }
      },
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   fixed: "right",
    //   width: 100,
    //   className: "text-left",
    //   // sorter: true,
    //   render: (text, record, index) => {
    //     return (
    //       <Space size="middle" className="actions-list">
    //         <Switch
    //           disabled={isModalOpen}
    //           size="small"
    //           checked={record?.is_active}
    //           onChange={() => onToggle(record, index)}
    //         />
    //       </Space>
    //     );
    //   },
    // },
  ];

  const columnDetails = [
    {
      title: "Stock",
      dataIndex: "inventory_quantity",
      key: "inventory_quantity",
      width: 60,
    },
  ];

  const getSku = (skuData) => {
    setSkuError(false);
    setSku(skuData);
  };

  const skuDataFunc = (value) => {
    setSku(value);
  };

  const handleCategory = (e) => {
    let parent = parentCategory?.payload?.message.filter(
      (item) => item.category_id == e
    );
    setParent(parent[0]?.category_name);
  };

  const onChangeTitle = (e) => {
    setInfoTitle(e.target.value);
  };

  const onChangeDescription = (e) => {
    setInfoDesc(e.target.value);
  };

  const changePromoCode = (e, options, name, index) => {
    if (e === undefined) {
    } else {
      var values = e.value.split(" ");
      var discount = values[0];
      setProductPromoCodeDscs(discount);
      setproductPromoCodePromo(e.children);
    }
  };

  const showModalDetail = (s) => {
    setDataDetail(s);
    setIsModalOpenDetail(true);
  };

  const normFile = (e) => {
    if (e.fileList?.length > 0) {
      if (previewImage !== "" || contentType !== "") {
        setVideo(e.file);
      }
    }
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const uploadProps = {
    accept: "image/jpeg, image/png, image/jpg, image/svg+xml, image/svg",
    name: "file",
    multiple: false,
    listType: "picture",
    maxCount: 1,
    action: "https://httpbin.org/post",

    beforeUpload: (file) => {
      const isPNG =
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/jpeg" ||
        file.type === "image/svg+xml" ||
        file.type === "image/svg";

      if (!isPNG) {
        message.error(`${file?.name} is not a valid format`);
      }

      return isPNG || Upload.LIST_IGNORE;
    },

    onChange(info) {
      setFlagg(true);
      setImageUpload(true);
      const { status } = info?.file;

      if (status !== "uploading") {
        setBanner(info?.file?.originFileObj);
      }

      if (status === "done") {
        message.success(`${info?.file.name} file uploaded successfully.`);
        setImageUpload(false);
        setPreviewImage(info?.file?.response?.files?.banner);
        setBannerExists(true);
        setMsg(false);
      } else if (status === "error") {
        notification.error({
          message: "Upload Failed Try Again",
          className: "toast-error",
        });
        // message.error(`${info.file.name} file upload failed.`);
        setMsg(true);
        setBannerExists(false);
      }
    },

    onDrop(e) {

    },

    onRemove(e) {

      // return Upload.LIST_IGNORE;
      setPreviewImage("");
    },
  };

  const {
    form,
    current,
    gotoStep,
    stepsProps,
    formProps,
    submit,
    formLoading,
  } = useStepsForm({
    async submit(values) {
      // setSpin(true);
      setLoading(true);

      const { title, gen_category_id, category_id, sub_category_id } = values;
      let data = {
        primary_host: host,
      };
      let data2 = {
        host1: host2,
      };
      let data3 = {
        host2: host3,
      };
      let data4 = {
        host3: host4,
      };
      let formData = new FormData();

      let newSku = sku?.map((skuItem) => {
        return {
          ...skuItem,
          // influencer_percent:
          //   !influencer_percent || influencer_percent == null
          //     ? "0"
          //     : influencer_percent,
          // referral_percent:
          //   !referralPercent || referralPercent == null ? "0" : referralPercent,
          influencer_percent:
            getStructureFees?.payload?.message?.fee_structure
              ?.content_creator_fee > 0
              ? getStructureFees?.payload?.message?.fee_structure
                ?.content_creator_fee
              : "0",
          referral_percent:
            getStructureFees?.payload?.message?.fee_structure?.referral_fee > 0
              ? getStructureFees?.payload?.message?.fee_structure?.referral_fee
              : "0",
          cashback_percent:
            getStructureFees?.payload?.message?.fee_structure
              ?.shopper_cashback > 0
              ? getStructureFees?.payload?.message?.fee_structure
                ?.shopper_cashback
              : "0",
        };
      });
      formData.append("banner", banner);
      formData.append("show", fields.image);
      formData.append("id", newSku[0]?.id);
      formData.append("products", JSON.stringify(newSku));
      formData.append("title", title);
      formData.append("notes", note);
      formData.append("video_ratio", videoRatio);
      // formData.append("promo", promo);
      // formData.append("discount", (discount += "%"));
      formData.append("discount", productPromoCodeDscs);
      formData.append("promo", productPromoCodePromo);
      // formData.append(
      //   "referral_percent",
      //   referralPercent > 0 ? referralPercent : 0
      // );
      // formData.append(
      //   "influencer_percent",
      //   influencer_percent > 0 ? influencer_percent : 0
      // );
      formData.append(
        "influencer_percent",
        getStructureFees?.payload?.message?.fee_structure?.content_creator_fee >
          0
          ? getStructureFees?.payload?.message?.fee_structure
            ?.content_creator_fee
          : 0
      );
      formData.append(
        "referral_percent",
        getStructureFees?.payload?.message?.fee_structure?.referral_fee > 0
          ? getStructureFees?.payload?.message?.fee_structure?.referral_fee
          : 0
      );
      formData.append(
        "cashback_percent",
        getStructureFees?.payload?.message?.fee_structure?.shopper_cashback > 0
          ? getStructureFees?.payload?.message?.fee_structure?.shopper_cashback
          : 0
      );
      formData.append("thankyou_message", thankyou);
      formData.append("gen_category_id", gen_category_id);
      formData.append("start_date", dateTime.toISOString());
      // if (category_id !== "all") {
      formData.append("category_id", category_id ? category_id : "all");
      // }
      // if (sub_category_id !== "all") {
      formData.append(
        "sub_category_id",
        sub_category_id ? sub_category_id : "all"
      );
      // }
      formData.append("default_banner", sampleBanner);
      formData.append("orientation", orientation);
      // if (platform) {
      //   formData.append(
      //     "facebook",
      //     JSON.stringify({ title: infoTitle, description: infoDesc })
      //   );
      // }
      // formData.append('social_platform', platform);
      if (formState === "edit") {

        if (newSku?.length) {
          dispatch(UpdateShows(formData, id)).then((res) => {

            if (res.type == "UPDATE_SHOW_SUCCESS") {
              setPromptModal(false);
              notification.success({
                message: "Event Updated Successfully",
                className: "toast-success",
              });

              showModal();
              // setSpin(false);
              setLoading(false);

              // handleClose();
              // history("/events");
            } else {
              // setSpin(false);
              setLoading(false);
              notification.error({
                message: "Show Failed",
                className: "toast-error",
              });
            }
          });
        } else {
          setLoading(false);
          notification.error({
            message: "Please add atleast 1 Product",
            className: "toast-error",
          });
        }
      } else {
        if (newSku?.length) {
          dispatch(postShow(formData)).then((res) => {

            if (res.type == "POST_SHOW_SUCCESS") {
              dispatch({
                type: REACT_S3,
                payload: [],
              });
              let key = res?.payload?.file_name;

              const newFile = new File(
                [fileList[0]],
                res?.payload?.file_name + "." + ext,
                {
                  type: contentType,
                }
              );
              dispatch({
                type: UPLOADS3,
                payload: { id: key, load: true },
              });
              // hack to update the selected file hehe
              const dT = new DataTransfer();
              dT.items.add(newFile);
              setFileList(dT.files);
              notification.open({
                key,
                message: `Uploading ${title}`,
                className: "toast-info",
                type: "info",
                duration: 0,
                placement: "bottomRight",
              });

              // S3FileUpload.uploadFile(dT.files[0], config)
              //   .then((data) => {
              //     dispatch({
              //       type: REACT_S3,
              //       payload: data,
              //     });
              //   })
              //   .catch((err) => console.error("err", err));

              const params = {
                Bucket: process.env.REACT_APP_SHOWS_BUCKET_NAME,
                Key: dT.files[0].name,
                Body: dT.files[0],
              };
              s3.upload(params, (err, data) => {
                if (err) {
                  return;
                }
              })
                .on("httpUploadProgress", (progress) => {
                  let done = Math.round((progress.loaded / progress.total) * 100);
                  setPercent({ [key]: done });
                })
                .send((err, data) => {
                  if (err) {
                    notification.close(key);
                    notification.error({
                      key,
                      message: "Show Upload Failed",
                      className: "toast-error",
                    });
                    dispatch({
                      type: UPLOAD_DONE,
                      payload: { id: key },
                    });
                    setTimeout(() => {
                      notification.close(key);
                      setPercent({ [key]: 100 });
                    }, 1000);
                    return;
                  }
                  dispatch({
                    type: REACT_S3,
                    payload: data,
                  });
                  notification.close(key);
                  notification.success({
                    key,
                    message: res?.payload?.message,
                    className: "toast-success",
                  });
                  dispatch({
                    type: UPLOAD_DONE,
                    payload: { id: key },
                  });
                  setTimeout(() => {
                    notification.close(key);
                    setPercent({ [key]: 100 });
                  }, 1000);
                });

              setPromptModal(false);
              // notification.success({
              //   message: res?.payload?.message,
              //   className: "toast-success",
              // });

              showModal();
              setLoading(false);
            } else {
              // setSpin(false);
              setLoading(false);
              notification.error({
                message: "Show Failed",
                className: "toast-error",
              });
            }
          });
        }
        else {
          setLoading(false);
          notification.error({
            message: "Please add atleast 1 Product",
            className: "toast-error",
          });
        }
      }
    },
    total: 3,
  });

  useEffect(() => {
    dispatch(getSavedGeneralCategories()).then((res) => {
      if (res.success) {
        setGenLoad(false);
      } else {
        setGenLoad(false);
      }
    });
    dispatch(getFeeStructure()).then((res) => {
      // 
    });
    dispatch(searchBySkuAndTitle({ sku: "", sort_on: "stock", sort: "asc", is_orme_product: true }, 1, 1)).then((res) => {
      if (res?.success) {
      } else {
        console.log(res, "error");
      }
    });
    // dispatch(getPromoRequest()).then((res) => {});
  }, []);

  useEffect(() => {
    setLoad(true);
    if (id) {
      dispatch(getShowBy_Id(id)).then(async (event) => {
        if (event.type === "GET_SHOW_ID_SUCCESS") {
          form.setFieldsValue({
            title: event.payload.message[0]?.title,
            notes: event.payload.message[0]?.notes,
            //stream: event.payload.message[0]?.event?.stream_type,
            //Externalstream: event.payload.message[0]?.event?.streaming_url,

            // referral_percent:
            //   event.payload.message[0]?.referral_percent === "0"
            //     ? null
            //     : event.payload.message[0]?.referral_percent,
            promo:
              event.payload.message[0]?.promo === ""
                ? "0"
                : event.payload.message[0]?.promo,
            discount:
              event.payload.message[0]?.discount === ""
                ? "0"
                : event.payload.message[0]?.discount,
            // influencer_percent:
            //   event.payload.message[0]?.influencer_percent === "0"
            //     ? null
            //     : event.payload.message[0]?.influencer_percent,

            thankyou_message: event.payload.message[0]?.thankyou_message,
            datetime: moment(
              moment(event.payload.message[0]?.start_date).format(
                "MM/DD/YYYY hh:mm a"
              )
            ),
            banner: event.payload?.message[0]?.banner,
            category_id: event.payload?.message[0]?.category_id,
            gen_category_id: event.payload?.message[0]?.gen_category_id,
            category_id: event.payload?.message[0]?.category_id
              ? event.payload?.message[0]?.category_id
              : "all",
            sub_category_id: event.payload?.message[0]?.sub_category_id
              ? event.payload?.message[0]?.sub_category_id
              : "all",
            default_banner: event.payload?.message[0]?.default_banner,
          });
          if (event.payload?.message[0]?.gen_category_id) {
            dispatch(
              getSavedCategories(event.payload?.message[0]?.gen_category_id)
            ).then((res) => {
              if (res.success) {
                setCategoryOptions(res?.message);
                // setCatLoad(false)
              } else {
                // setCatLoad(true)
              }
            });
            dispatch(
              getSavedSubCategories(
                event.payload?.message[0]?.gen_category_id,
                event.payload?.message[0]?.category_id
                  ? event.payload?.message[0]?.category_id
                  : ""
              )
            ).then((res) => {
              if (res.success) {
                setSubCategoryOptions(res?.message);
                // setCatLoad(false)
              } else {
                // setCatLoad(true)
              }
            });
          }
          setSampleBanner(
            event?.payload?.message[0]?.default_banner ? true : false
          );
          setDefaultBanner(
            event?.payload?.message[0]?.default_banner ? true : false
          );
          setRecURL(event?.payload?.message?.[0]?.recording_url);
          setPreviewImage(event?.payload?.message[0]?.banner);
          setEventThankyou(event.payload.message[0]?.thankyou_message);
          setEventTitle(event.payload.message[0]?.title);
          setEventNote(event.payload.message[0]?.notes);
          setproductPromoCodePromo(event.payload.message[0]?.promo);
          setProductPromoCodeDscs(event.payload.message[0]?.discount);
          setOrientation(event.payload.message[0]?.orientation);

          setRatio(event.payload.message[0]?.ratio);

          setPlatform(
            event.payload.message[0]?.is_facebook_live ? true : false
          );
          if (event.payload.message[0]?.is_facebook_live) {
            setInfoTitle(event.payload.message[0]?.facebook?.title);
            setInfoDesc(event.payload.message[0]?.facebook?.description);
          }

          setReferralPercent(
            event.payload.message[0]?.referral_percent === ""
              ? "0"
              : event.payload.message[0]?.referral_percent
          );
          setPromo(
            event.payload.message[0]?.promo === ""
              ? "0"
              : event.payload.message[0]?.promo
          );
          setInfluencer_percent(
            event.payload.message[0]?.influencer_percent === ""
              ? "0"
              : event.payload.message[0]?.influencer_percent
          );
          setDiscount(
            event.payload.message[0]?.discount === ""
              ? "0"
              : event.payload.message[0]?.discount
          );
          setMultipleHosts(
            event.payload.message[0]?.multiple_hosts
              ? event.payload.message[0]?.multiple_hosts
              : false
          );
          //setStreamUrl(event.payload.message[0]?.stream_type);
          setStreamUrl("local");

          setExternalUrl(event.payload.message[0]?.streaming_url);
          setDate(moment(moment(event.payload.message[0]?.start_date)));
          setBanner(event?.payload?.message[0]?.banner);
          setSku(event?.payload?.message[0]?.products);
          setPublished(event?.payload?.message[0]?.is_published);
          setBannerExists(event?.payload?.message[0]?.banner ? true : false);
          setFormState("edit");
          setLoad(false);
          let parent = await parentCategory?.payload?.message.filter(
            (item) =>
              item?.category_id == event?.payload?.message?.[0]?.category_id
          );
          setParent(parent?.[0]?.category_name);
        }
      });
    } else {
      form.setFieldsValue({
        title: null,
        datetime: null,
        category_id: undefined,
        banner: null,
      });
      setFormState("add");
      setLoad(false);
    }

    // dispatch(getSavedGeneralCategories()).then((res) => {
    //   if (res.success) {
    //     setGenLoad(false);
    //   } else {
    //     setGenLoad(false);
    //   }
    // });
  }, [id]);

  const myPlayer = useMemo(() => {
    return (
      // <ReactHlsPlayer
      //   src={recURL}
      //   // autoPlay={true}
      //   controls={true}
      //   light="http://placekitten.com/200/300"
      //   height="220px"
      //   width="350px"
      // // hlsConfig={{
      // //   autoStartLoad: true,
      // //   startPosition: -1,
      // //   debug: true,
      // // }}
      // />
      defaultBanner ? (
        <ReactPlayer
          className={`review_react_player ${recURL.split(".")[recURL.split(".")?.length - 1] === "m3u8" &&
            "objectFit"
            }`}
          width={"350px"}
          height={"220px"}
          url={recURL}
          controls
          playsinline
        />
      ) : (
        <ReactPlayer
          playing
          className={`review_react_player ${recURL.split(".")[recURL.split(".")?.length - 1] === "m3u8" &&
            "objectFit"
            }`}
          width={"350px"}
          height={"220px"}
          url={recURL}
          controls
          light={banner}
          playsinline
        />
      )
    );
  }, [recURL]);

  const changeSampleBanner = (e) => {
    if (e.target.checked) {
      setBannerExists(true);
      setMsg(false);
      setSampleBanner(true);
      setPreviewImage("https://cdn.fascomconnect.com/1-banner.png");
    } else {
      setSampleBanner(false);
      setMsg(true);
      setSampleBanner(false);
      setPreviewImage("");
      setBanner("");
      form.setFieldsValue({
        banner: null,
      });
    }
  };

  function dateToFromNowDaily(myDate) {
    // get from-now for this date
    var fromNow = moment(myDate).fromNow();

    // ensure the date is displayed with today and yesterday
    return moment(myDate).calendar(null, {
      // when the date is closer, specify custom values
      lastWeek: "[Last] dddd",
      lastDay: "[Yesterday]",
      sameDay: "[Today]",

      nextDay: "[Tomorrow]",
      nextWeek: "dddd",
      // when the date is further away, use from-now functionality
      sameElse: function () {
        return "[" + fromNow + "]";
      },
    });
  }

  function ErrorFallback({ error, resetErrorBoundary }) {
    return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre>{error.message}</pre>
        <button onClick={resetErrorBoundary}>Try again</button>
      </div>
    );
  }

  // const config = {
  //   bucketName: process.env.REACT_APP_SHOWS_BUCKET_NAME,
  //   region: process.env.REACT_APP_SHOWS_REGION,
  //   accessKeyId: process.env.REACT_APP_SHOWS_ACCESS_KEY_ID,
  //   secretAccessKey: process.env.REACT_APP_SHOWS_SECRET_ACCESS_KEY,
  // };

  AWS.config.update({
    region: process.env.REACT_APP_SHOWS_REGION,
    accessKeyId: process.env.REACT_APP_SHOWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SHOWS_SECRET_ACCESS_KEY,
  });
  const videoProps = {
    accept: "video/mp4",
    onRemove: (file) => {
      setVideoThumb(null);
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      refs.current.thumbButton.style.display = "none";
      refs.current.numberInput.style.display = "none";
      setThumbnails([]);
      setThumbNumber(0);
      setVideoUrl();
      setRefresh(true);
    },
    beforeUpload: (file) => {
      setRefresh(false);
      const url = URL.createObjectURL(file);

      const $video = document.createElement("video");
      $video.src = url;
      $video.addEventListener("loadedmetadata", function () {
        if (this.videoWidth > this.videoHeight) {
          setOrientation("landscape");
        } else {
          setOrientation("portrait");
        }
      });

      setContentType(file.type);
      setExt(file.name.split(".")[file.name.split(".")?.length - 1]);
      setFileList([file]);
      if (file.target.files?.length > 0) {
        setVideo(file.target.files[0]);
      }
      return false;
    },
    fileList,
  };
  const onDateTimeChange = (e) => {
    if (e !== null) {
      setDate(e._d);
    } else {
      setDate("");
    }
  };
  const handleButtonClick = () => {
    setPromptModal(false);
    confirm({
      wrapClassName: "modal-generic cancel-event-modal min-modal-600",
      title: "Warning",
      centered: true,
      closable: true,
      icon: <ExclamationCircleOutlined />,
      closeIcon: <FontAwesomeIcon icon={faXmark} />,
      content: "Are you sure you want to exit the show ?",
      okText: " Yes, Exit",
      okType: "danger",
      cancelText: " No, I don't want to exit ",

      onOk() {
        // history("/shows");
        history(published ? "/shows?type=Published" : "/shows");
      },
      onCancel() {
        setPromptModal(true);

      },
    });
  };
  function startDateLimit(current) {
    return current && current < moment().startOf("day");
  }
  const onGenderChange = (value) => {
    setGender(value);
    setSubCategoryOptions([]);
    setCategoryOptions([]);
    setCategory("");
    setSubCategory("");
    form.setFieldsValue({ category_id: undefined, sub_category_id: undefined });
    const data = value === "all" ? "" : value;
    setCatLoad(true);
    dispatch(getSavedCategories(data)).then((res) => {
      if (res.success) {
        setCategoryOptions(res?.message);
        setCatLoad(false);
      } else {
        setCatLoad(false);
      }
    });
  };
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const onCategoryChange = (value) => {
    setSubCategory([]);
    setSubCategoryOptions([]);
    setSubCategory("");
    form.setFieldsValue({ sub_category_id: undefined });
    const data = value === "all" ? "" : value;
    setSubCatLoad(true);
    dispatch(getSavedSubCategories(gender === "all" ? "" : gender, data)).then(
      (res) => {
        if (res.success) {
          setSubCategoryOptions(res?.message);
          setSubCatLoad(false);
        } else {
          setSubCatLoad(false);
        }
      }
    );
  };

  const onSubCategoryChange = (value) => {
    setSubCategory(value);
  };

  const formList = [
    load ? (
      <SkeletonComponent />
    ) : (
      <>
        <div className="row d-flex mb-20">
          <div className="col-lg-4 col-xl-3 pr-xl-4 guide-column  flex-stretch">
            <h5 className="rounded">Show Information</h5>
            <p>
              Make a major impact with your opening line! Give your event an
              engaging title.
            </p>
          </div>
          <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling ">
            <Form.Item
              labelCol={{ span: 24 }}
              label="Show Title"
              initialValue={
                formState === "add" ? null : form.getFieldValue().title
              }
              name="title"
              rules={[
                {
                  required: true,
                  message: "Please Enter Show Title",
                },
              ]}
            >
              <Input
                placeholder="e.g. MKG1402 -LIVE- Winter 2020 Clearance"
                size="large"
                showCount
                maxLength={80}
                onChange={(e) => {
                  setEventTitle(e.target.value);
                  setFlag(true);
                }}
              />
            </Form.Item>
          </div>
        </div>

        <div className="row d-flex mb-20">
          <div className="col-lg-4 col-xl-3  pr-xl-4 guide-column  flex-stretch">
            <h5 className="rounded">Categories</h5>
            <p>Select category from provided list.</p>
          </div>
          <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling ">
            <Form.Item
              labelCol={{ span: 24 }}
              initialValue={form.getFieldValue().gen_category_id}
              label="Department"
              name="gen_category_id"
              rules={[
                {
                  required: true,
                  message: "Please select a department",
                },
              ]}
            >
              <Select
                className="select-style dark-field"
                placeholder="Select Department"
                size="large"
                focus={false}
                onBlur={false}
                onChange={onGenderChange}
                value={gender}
                loading={genLoad}
                disabled={genLoad}
              >
                {savedGeneralCategories?.message?.map((option) => {
                  return (
                    <Option value={option?.category_id}>{option?.name}</Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              labelCol={{ span: 24 }}
              initialValue={form.getFieldValue().category_id}
              label="Category"
              name="category_id"
              rules={[
                {
                  // required: true,
                  message: "Please select a category",
                },
              ]}
            >
              <Select
                placeholder="Select Category"
                size="large"
                disabled={categoryOptions?.length ? false : true}
                className="select-style dark-field"
                onChange={onCategoryChange}
                loading={catLoad}
                value={category ? category : undefined}
                defaultValue={"all"}
              >
                <Option value="all">All</Option>
                {categoryOptions?.map((option) => {
                  return (
                    <Option value={option?.category_id}>
                      {option?.gen_category?.name} {" > "}
                      {option?.category_name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              labelCol={{ span: 24 }}
              initialValue={form.getFieldValue().sub_category_id}
              label="Sub Category"
              name="sub_category_id"
              rules={[
                {
                  // required: true,
                  message: "Please select a sub category",
                },
              ]}
            >
              <Select
                placeholder="Sub Category"
                size="large"
                disabled={subCategoryOptions?.length ? false : true}
                className="select-style dark-field"
                onChange={onSubCategoryChange}
                loading={catSubLoad}
                value={subCategory ? subCategory : undefined}
                defaultValue={"all"}
              >
                <Option value="all">All</Option>
                {subCategoryOptions?.map((option) => {
                  return (
                    <Option value={option?.sub_category_id}>
                      {option?.gen_category?.name} {" > "}
                      {option?.category?.category_name} {" > "}
                      {option?.sub_category_name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
        </div>

        <div className="row d-flex mb-20">
          <div className="col-lg-4 col-xl-3  pr-xl-4 guide-column flex-stretch">
            <h5 className="rounded">Details</h5>
            <p>
              This information will be visible to your audience in ‘Details’ tab
              when event is Live.
            </p>
          </div>
          <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling">
            <Form.Item
              labelCol={{ span: 24 }}
              label="Details"
              initialValue={
                formState === "add" ? null : form.getFieldValue().notes
              }
              name="notes"
            >
              <TextArea
                showCount
                maxLength={200}
                // onChange={onChange}
                className="dark-field"
                // placeholder="Get rid of 2021 inventory. Use Promo Code : WINTER2022 - Promo Code description : 20% OFF of the entire collection"
                placeholder="Enter Details"
                autoSize={{ minRows: 3, maxRows: 5 }}
                onChange={(e) => {
                  setEventNote(e.target.value);
                  setFlag(true);
                }}
              />
            </Form.Item>
          </div>
        </div>
        <div className="row d-flex mb-20">
          <div className="col-lg-4 col-xl-3  pr-xl-4 guide-column  flex-stretch">
            <h5 className="rounded">Date and Time</h5>
            <p>Set the date and time of the event.</p>
          </div>
          <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling date-time-mai">
            <Form.Item
              name="datetime"
              initialValue={
                formState === "add" ? null : form.getFieldValue().datetime
              }
              rules={[
                {
                  required: true,
                  message: "Please select date & time",
                },
                // {
                //   validator: async (_, datetime) => {
                //     var travelTime = moment().add(30, "minutes");

                //     var diffTime = travelTime.diff(moment(datetime));
                //     if (diffTime > 30) {
                //       return Promise.reject(
                //         new Error("Schedule the event after 30 minutes")
                //       );
                //     } else {
                //       return Promise.resolve();
                //     }
                //   },
                // },
              ]}
              labelCol={{ span: 24 }}
              label="Date and Time"
            >
              <DatePicker
                style={{
                  width: "100%",
                }}
                showTime={{ use12Hours: true, format: "HH:mm" }}
                onChange={(e) => {
                  onDateTimeChange(e);
                  setFlag(true);
                }}
                // disabledDate={startDateLimit}
                format="MM-DD-YYYY h:mm A"
              />
            </Form.Item>
          </div>
        </div>
        <div className="row d-flex mb-20">
          <div className="col-lg-4 col-xl-3  pr-xl-4 guide-column  flex-stretch">
            <h5 className="rounded">Pre LIVE</h5>
            <p>Upload show banner and let your audience identify your brand.</p>
          </div>
          <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling ">
            <h5>Banner</h5>
            <p className="color-light">1280px x 720px (jpg or png format)</p>
            {!sampleBanner && (
              <>
                {/* {banner && typeof banner !== "object" && !previewImage && (
                  <Form.Item className="preview-image-main" name="banner">
                    <Image
                      // width={100}
                      // src="https://static.konnect.bio/eventdefaultbanner/banner.jpg"
                      // src="https://static.konnect.bio/900000005/event/dddc020e-215a-410c-9fda-7d3ab8d61b54.png"
                      src={banner}
                    />
                  </Form.Item>
                )} */}
                {/* {typeof banner === "object" && previewImage && (
                  <Form.Item className="preview-image-main" name="banner">
                    <Image
                      // width={100}
                      // src="https://static.konnect.bio/eventdefaultbanner/banner.jpg"
                      // src="https://static.konnect.bio/900000005/event/dddc020e-215a-410c-9fda-7d3ab8d61b54.png"
                      src={previewImage}
                    />
                  </Form.Item>
                )} */}
                {/* {banner == previewImage && banner && previewImage && (
                  <Form.Item className="preview-image-main" name="banner">
                    <Image
                      // width={100}
                      // src="https://static.konnect.bio/eventdefaultbanner/banner.jpg"
                      // src="https://static.konnect.bio/900000005/event/dddc020e-215a-410c-9fda-7d3ab8d61b54.png"
                      src={previewImage}
                    />
                  </Form.Item>
                )} */}
                <Form.Item>
                  <Form.Item
                    name="banner"
                    valuePropName={formState !== "edit" && "fileList"}
                    //initialValue={formState === "add" ? null : form.getFieldValue().banner}
                    //isImageUrl={formState === "add" ? null : "https://cdn.pixabay.com/photo/2017/02/09/21/08/wings-2053515__340.png"}
                    getValueFromEvent={normFile}
                    noStyle
                    multiple={false}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please upload banner image",
                  //   },
                  // ]}
                  >
                    <Upload.Dragger
                      customRequest={dummyRequest}
                      className={`upload-image-main ${banner?.length !== 0 &&
                        banner !== undefined &&
                        previewImage &&
                        "d-none"
                        }`}
                      {...uploadProps}
                      name="banner"
                      // defaultFileList={
                      //   formState === "edit" &&
                      //   form.getFieldValue()?.banner === null
                      //     ? null
                      //     : [
                      //         {
                      //           url: form.getFieldValue()?.banner,
                      //         },
                      //       ]
                      // }
                      defaultFileList={
                        formState === "edit"
                          ? sampleBanner ||
                            form.getFieldValue()?.banner === null
                            ? form.getFieldValue()?.banner
                            : form.getFieldValue()?.banner?.length === 1
                              ? form.getFieldValue()?.banner
                              : [
                                {
                                  url: form.getFieldValue()?.banner,
                                },
                              ]
                          : null
                      }
                    // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    >
                      {/* <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p> */}
                      <p className="ant-upload-text">
                        drag files here to upload
                      </p>
                      {/* <p className="ant-upload-hint">
                      Support for a single or bulk upload.
                    </p> */}
                    </Upload.Dragger>
                  </Form.Item>
                </Form.Item>
              </>
            )}
            {sampleBanner && (
              <Form.Item className="preview-image-main" name="banner">
                <Image
                  // width={100}
                  // src="https://static.konnect.bio/eventdefaultbanner/banner.jpg"
                  // src="https://static.konnect.bio/900000005/event/dddc020e-215a-410c-9fda-7d3ab8d61b54.png"
                  src={previewImage}
                />
              </Form.Item>
            )}
            {msg && (
              <div role="alert" className="ant-form-item-explain-error">
                Please Select Banner
              </div>
            )}
            <Checkbox checked={sampleBanner} onChange={changeSampleBanner}>
              Use a sample image
            </Checkbox>
          </div>
        </div>

        <div className="row d-flex mb-30">
          <div className="col-lg-4 col-xl-3  pr-xl-4 guide-column  flex-stretch">
            <h5 className="rounded">Upload Video</h5>
            <p>
              MP4 Files Are Allowed
              <br />
              The maximum file size allowed is 2GB
            </p>
          </div>
          {formState === "add" ? (
            // <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling ">
            //   <input
            //     type="file"
            //     onClick={(e) => e.target.value = null}
            //     onChange={(e) => uploadFiless(e)}
            //   />
            //   {vidMsg && !videoFile.size &&
            //     <div role="alert" className="ant-form-item-explain-error">
            //       Please Select Video File
            //     </div>
            //   }
            // </div>

            <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling ">
              <Form.Item>
                <Form.Item
                  name="upload"
                  valuePropName={"fileList"}
                  getValueFromEvent={normFile}
                  rules={[
                    {
                      required: true,
                      message: "Please Upload Video File",
                    },
                  ]}
                >
                  <Upload {...videoProps} maxCount={1}>
                    <Button icon={<UploadOutlined />}>Select File</Button>
                  </Upload>
                </Form.Item>
              </Form.Item>

              <div>
                {refresh !== true && (
                  <video
                    poster={videoThumb}
                    style={{
                      maxWidth: 600,
                      maxHeight: 400,
                      transform: "scale(0)",
                      transition: "all 0.3s",
                    }}
                    controls
                    id="video"
                    ref={(el) => (refs.current.video = el)}
                    src={videoUrl}
                  >
                    <source src={videoUrl} type={video?.type} />
                    Your browser does not support the video tag.
                  </video>
                )}

                {videoUrl && refresh && (
                  <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling ">
                    <ReactPlayer
                      playing
                      className={`review_react_player ${recURL.split(".")[recURL.split(".")?.length - 1] ===
                        "m3u8" && "objectFit"
                        }`}
                      width={"350px"}
                      height={"220px"}
                      url={videoUrl}
                      controls
                      light={videoThumb}
                      playsinline
                    />
                  </div>
                )}

                <div className="mt-4 d-flex align-items-end gap-3">
                  <div
                    id="numberWrapper"
                    style={{ display: "none" }}

                    ref={(el) => (refs.current.numberInput = el)}
                  >
                    <h6 className="mt-2">Generate Thumbnails</h6>
                    <InputNumber
                      // placeholder="1"
                      size="large"
                      // type="number"
                      defaultValue={1}
                      value={thumbNumber + 1}
                      // showCount
                      className="w-100 mt-2"
                      min={1}
                      max={10}
                      onChange={(e) => {
                        setThumbNumber(parseInt(e - 1, 0));
                      }}
                    />
                  </div>
                  <div
                    style={{ marginTop: 25, display: "none" }}
                    id="buttonWrapper"
                    ref={(el) => (refs.current.thumbButton = el)}
                  >
                    <div className="d-flex justify-content-end ">
                      <Button
                        id="generatethumbnails"
                        className="default-btn ml-3 h-42"
                        loading={thumbnailLoader}
                        onClick={() => {
                          setThumbnailLoader(true);

                          if (video) {

                            if (refs.current.loader) {
                              refs.current.loader.style.display = "block";
                            }
                            generateVideoThumbnails(video, thumbNumber).then(
                              (thumbs) => {
                                setThumbnails(thumbs);
                                setPicCount(thumbNumber);
                                setThumbnailLoader(false);
                                if (refs.current.loader) {
                                  refs.current.loader.style.display = "none";
                                }
                              }
                            );
                          }
                        }}
                      >
                        Generate Thumbnails
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="thumbnails"
                className={
                  "scrollbar-style shows-thumbnails-main" +
                  (picCount > 6 ? "overflow-y-scroll" : "")
                }
              >
                {thumbnails.map((item) => {
                  return (
                    <img
                      src={item}
                      className="thumbnails-images"
                      alt=""
                      onClick={() => {
                        setPreviewImage(item);
                        setVideoThumb(item);
                        setBanner(item);
                        setMsg(false);
                        setBannerExists(true);
                        setSampleBanner(false);
                        form.setFieldsValue({
                          banner: null,
                        });

                        // window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                    />
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling ">
              {myPlayer}
            </div>
          )}
        </div>

        <Form.Item>
          <div className="d-flex justify-content-between align-items-center">
            <Link to="#" className="prev-next-link ff-nunito"></Link>

            <div className="d-flex">
              <Button
                // disabled
                className="default-btn outline d-flex align-items-center justify-content-center span-inner-initial"
                type="primary"
                size="large"
                onClick={handleButtonClick}
              >
                Exit
              </Button>
              <Button
                // disabled
                className="default-btn d-flex align-items-center justify-content-center"
                type="primary"
                size="large"
                icon={
                  <FontAwesomeIcon
                    size="sm"
                    className="event-icon"
                    icon={faCaretRight}
                  />
                }
                onClick={() => {
                  if (bannerExists) {
                    gotoStep(current + 1);
                  } else {
                    setMsg(true);
                  }
                }}
                disabled={imageUpload && !sampleBanner ? true : false}
              >
                Next
              </Button>
            </div>
          </div>
        </Form.Item>
      </>
    ),

    <>
      {/* <div className="row">
        <div className="col-md-6">
          <label className="pb-0">Promo Code</label>
          <Select
            size="small"
            filterOption={(input, options) =>
              options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={
              productPromoCodePromo ? productPromoCodePromo : "Select PromoCode"
            }
            //disabled={!(formState === "add" || formState === "edit")}
            placeholder="Select PromoCode"
            //loading={this.state.promoCond}
            optionFilterProp="children"
            className="w-100 select-modal mb-20"
            // onSearch={onSearch}
            onChange={(options, e) => {
              changePromoCode(e, options);
              setFlag(true);
            }}
            showSearch
            allowClear={false}
            loading={promoRequest?.loading ? true : false}
            disabled={promoRequest?.loading ? true : false}
          >
            {promoRequest?.message?.map((customer, key) => {
              return (
                <Option key={customer.promo_percent + " " + key}>
                  {customer.promo}
                </Option>
              );
            })}
          </Select>
        </div>
        <div className="col-md-6">
          <label>Discount</label>
          <div className="promo_discount form-control mb-20">
            {productPromoCodeDscs}
          </div>
        </div> */}

      {/* <div className="col-md-6 col-lg-3">
          <Form.Item
              labelCol={{ span: 24 }}
              label="Event Promo Code"
              initialValue={formState === "add" ? null : form.getFieldValue().promo}
              name="promocode"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please Enter Event Promo Code",
              //   },
              // ]}
            >
              <Input placeholder="Enter Event Promo Code" size="large"  onChange={(e) => setPromo(e.target.value)} />
            </Form.Item>

          </div>
          <div className="col-md-6 col-lg-3">
            <Form.Item labelCol={{ span: 24 }} 
            label="Discount" 
            className="mb-40" 
            initialValue={formState === "add" ? null : form.getFieldValue().discount} name="discount">
            
              <Input
                // onChange={onChange}
                type="number"
                size="large"
                className="dark-field"
                placeholder="0%"
                onChange={(e) => {
                  setDiscount(e.target.value);
                }}
              />
            </Form.Item>
          </div> */}
      {/* <div className="col-md-3">
          <label>Cashback</label>
          <Form.Item
            labelCol={{ span: 24 }}
            // label="Influencer Fee"
            className="mb-20 label-padb-0"
            // initialValue={
            //   formState === "add"
            //     ? null
            //     : form.getFieldValue().influencer_percent
            // }
            disabled={true}
            initialValue={
              getStructureFees?.payload?.message?.fee_structure?.shopper_cashback ? getStructureFees?.payload?.message?.fee_structure?.shopper_cashback : 0
            }
            name="influencer_percent"
          >
            <InputNumber
              disabled={true}
              className="w-100 select-modal"
              defaultValue={0}
              size="large"
              min={0}
              max={100}
              formatter={(value) => `${value}%`}
              parser={(value) => value.replace("%", "")}
              onChange={(e) => {
                {
                  setInfluencer_percent(e);
                  setFlag(true);
                }
              }}
            />
          </Form.Item>
        </div>
        <div className="col-md-3">
          <label>Referral Fee</label>
          <Form.Item
            labelCol={{ span: 24 }}
            // label="Referral Fee"
            className="mb-20 label-padb-0 w-100"
            disabled={true}
            // initialValue={
            //   formState === "add" ? null : form.getFieldValue().referral_percent
            // }
            initialValue={
              getStructureFees?.payload?.message?.fee_structure?.referral_fee ? getStructureFees?.payload?.message?.fee_structure?.referral_fee : 0
            }
            name="referral_percent"
          >
            <InputNumber
              disabled={true}
              className="w-100 select-modal"
              defaultValue={0}
              size="large"
              min={0}
              max={100}
              formatter={(value) => `${value}%`}
              parser={(value) => value.replace("%", "")}
              onChange={(e) => {
                {
                  setReferralPercent(e);
                  setFlag(true);
                }
              }}
            />
          </Form.Item>
        </div> */}
      {/* </div> */}
      <div className="row d-flex mb-30">
        {/* <div className="col-12 col-xl-3  pr-xl-4 guide-column  flex-stretch">
            <h5 className="rounded">Add product SKU</h5>
            <p>
              Create product list to live event by using SKU numbers as added on
              the ecommerce platform.
            </p>
          </div> */}
        <div className="col-12 col-xl-12 flex-stretch form-styling">
          <Form.Item>
            <Form.Item
              name="productsku"
              label="Product SKU"
              labelCol={{ span: 24 }}
              className="d-flex flex-column sku-field pos-index mb-0"
            >
              <AsyncProduct
                getSku={getSku}
                sku={sku}
                referralPercent={referralPercent}
                influencer_percent={influencer_percent}
              />
              {skuError && (
                <div role="alert" className="ant-form-item-explain-error">
                  Please Select Product SKU
                </div>
              )}
            </Form.Item>
          </Form.Item>

          <div className="row d-flex mb-20 product-listing-table-main">
            <div className="col">
              {sku?.length > 0 && (
                <Table
                  pagination={false}
                  columns={columns}
                  size="small"
                  className="product-listing-table"
                  dataSource={[...sku]}
                  scroll={sku.length > 4 ? { y: 350, x: 1000 } : { x: 1000 }}
                // scroll={
                //   sku.length > 4
                //     ? { y: 270, x: window.innerWidth <= 1440 ? 900 : 0 }
                //     : false
                // }
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <Form.Item>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <button
              onClick={() => {
                setRefresh(true);
                gotoStep(current - 1);
              }}
              className="border-0 bg-transparent prev-next-link ff-nunito"
            >
              <FontAwesomeIcon icon={faAngleLeft} />
              <span> Previous</span>
            </button>
          </div>

          <Modal
            maskClosable={false}
            className="modal-generic modal-500"
            centered
            visible={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            closeIcon={<FontAwesomeIcon icon={faXmark} />}
            footer={[
              // <Button key="back" onClick={handleCancel}>
              //   Cancel
              // </Button>,
              <Button
                key="submit"
                type="primary"
                className="d-table m-auto"
                // onClick={handleOk}
                onClick={() =>
                  history(published ? "/shows?type=Published" : "/shows")
                }
              >
                Go to Shows
              </Button>,
            ]}
          >
            <h2 className="modal-hd1">Your event is ready!</h2>
            <div className="preview-content-main-modal">
              <h3 className="preview-title">
                {eventTitle === "" ? "[Preview Event Title]" : eventTitle}
              </h3>
              <div className="preview-date">
                <span className="text-uppercase">
                  {" "}
                  {dateTime === "" ? "Today" : dateToFromNowDaily(dateTime)}
                </span>
                <br></br>
                {dateTime === ""
                  ? "11:40 AM"
                  : moment(dateTime).format("hh:mm A")}
              </div>
              <div className="preview-txt p-2">
                The live event should start soon !
              </div>
            </div>
          </Modal>

          <div className="d-flex ">
            <div className="d-flex">
              <Button
                className="default-btn outline d-flex align-items-center justify-content-center span-inner-initial"
                type="primary"
                size="large"
                onClick={handleButtonClick}
              >
                Exit
              </Button>

              <Button
                className="default-btn d-flex align-items-center justify-content-center  res-btn2"
                type="primary"
                size="large"
                loading={loading}
                icon={
                  <FontAwesomeIcon
                    size="sm"
                    className="event-icon"
                    icon={faCaretRight}
                  />
                }
                //disabled={true}
                onClick={() => {
                  if (sku.length === 0) {
                    setSkuError(true);
                  } else {
                    submit();

                  }
                }}
              >
                {formState == "add" ? <>Create Show</> : <>Update Show</>}
              </Button>
            </div>
            {/* </Spin> */}
          </div>
        </div>
      </Form.Item>
    </>,
  ];

  const formListEdit = [
    load ? (
      <SkeletonComponent />
    ) : (
      <>
        <div className="row d-flex mb-20">
          <div className="col-lg-4 col-xl-3 pr-xl-4 guide-column  flex-stretch">
            <h5 className="rounded">Show Information</h5>
            <p>
              Make a major impact with your opening line! Give your event an
              engaging title.
            </p>
          </div>
          <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling ">
            <Form.Item
              labelCol={{ span: 24 }}
              label="Show Title"
              initialValue={
                formState === "add" ? null : form.getFieldValue().title
              }
              name="title"
              rules={[
                {
                  required: true,
                  message: "Please Enter Show Title",
                },
              ]}
            >
              <Input
                placeholder="e.g. MKG1402 -LIVE- Winter 2020 Clearance"
                size="large"
                showCount
                maxLength={80}
                onChange={(e) => {
                  setEventTitle(e.target.value);
                  setFlag(true);
                }}
              />
            </Form.Item>
          </div>
        </div>

        <div className="row d-flex mb-20">
          <div className="col-lg-4 col-xl-3  pr-xl-4 guide-column  flex-stretch">
            <h5 className="rounded">Categories</h5>
            <p>Select category from provided list.</p>
          </div>
          <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling ">
            <Form.Item
              labelCol={{ span: 24 }}
              initialValue={form.getFieldValue().gen_category_id}
              label="Department"
              name="gen_category_id"
              rules={[
                {
                  required: true,
                  message: "Please select a department",
                },
              ]}
            >
              <Select
                className="select-style dark-field"
                placeholder="Select Department"
                size="large"
                focus={false}
                onBlur={false}
                onChange={onGenderChange}
                value={gender}
                loading={genLoad}
                disabled={genLoad}
              >
                {savedGeneralCategories?.message?.map((option) => {
                  return (
                    <Option value={option?.category_id}>{option?.name}</Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              labelCol={{ span: 24 }}
              initialValue={form.getFieldValue().category_id}
              label="Category"
              name="category_id"
              rules={[
                {
                  // required: true,
                  message: "Please select a category",
                },
              ]}
            >
              <Select
                placeholder="Select Category"
                size="large"
                disabled={categoryOptions?.length ? false : true}
                className="select-style dark-field"
                onChange={onCategoryChange}
                loading={catLoad}
                value={category ? category : undefined}
                defaultValue={"all"}
              >
                <Option value="all">All</Option>
                {categoryOptions?.map((option) => {
                  return (
                    <Option value={option?.category_id}>
                      {option?.gen_category?.name} {" > "}
                      {option?.category_name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              labelCol={{ span: 24 }}
              initialValue={form.getFieldValue().sub_category_id}
              label="Sub Category"
              name="sub_category_id"
              rules={[
                {
                  // required: true,
                  message: "Please select a sub category",
                },
              ]}
            >
              <Select
                placeholder="Sub Category"
                size="large"
                disabled={subCategoryOptions?.length ? false : true}
                className="select-style dark-field"
                onChange={onSubCategoryChange}
                loading={catSubLoad}
                value={subCategory ? subCategory : undefined}
                defaultValue={"all"}
              >
                <Option value="all">All</Option>
                {subCategoryOptions?.map((option) => {
                  return (
                    <Option value={option?.sub_category_id}>
                      {option?.gen_category?.name} {" > "}
                      {option?.category?.category_name} {" > "}
                      {option?.sub_category_name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
        </div>

        <div className="row d-flex mb-20">
          <div className="col-lg-4 col-xl-3  pr-xl-4 guide-column flex-stretch">
            <h5 className="rounded">Details</h5>
            <p>
              This information will be visible to your audience in ‘Details’ tab
              when event is Live.
            </p>
          </div>
          <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling">
            <Form.Item
              labelCol={{ span: 24 }}
              label="Details"
              initialValue={
                formState === "add" ? null : form.getFieldValue().notes
              }
              name="notes"
            >
              <TextArea
                showCount
                maxLength={200}
                // onChange={onChange}
                className="dark-field"
                // placeholder="Get rid of 2021 inventory. Use Promo Code : WINTER2022 - Promo Code description : 20% OFF of the entire collection"
                placeholder="Enter your details"
                autoSize={{ minRows: 3, maxRows: 5 }}
                onChange={(e) => {
                  setEventNote(e.target.value);
                  setFlag(true);
                }}
              />
            </Form.Item>
          </div>
        </div>
        <div className="row d-flex mb-20">
          <div className="col-lg-4 col-xl-3  pr-xl-4 guide-column  flex-stretch">
            <h5 className="rounded">Date and Time</h5>
            <p>Set the date and time of the event.</p>
          </div>
          <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling date-time-mai">
            <Form.Item
              name="datetime"
              initialValue={
                formState === "add" ? null : form.getFieldValue().datetime
              }
              rules={[
                {
                  required: true,
                  message: "Please select date & time",
                },
                // {
                //   validator: async (_, datetime) => {
                //     var travelTime = moment().add(30, "minutes");

                //     var diffTime = travelTime.diff(moment(datetime));
                //     if (diffTime > 30) {
                //       return Promise.reject(
                //         new Error("Schedule the event after 30 minutes")
                //       );
                //     } else {
                //       return Promise.resolve();
                //     }
                //   },
                // },
              ]}
              labelCol={{ span: 24 }}
              label="Date and Time"
            >
              <DatePicker
                style={{
                  width: "100%",
                }}
                showTime={{ use12Hours: true, format: "HH:mm" }}
                onChange={(e) => {
                  onDateTimeChange(e);
                  setFlag(true);
                }}
                // disabledDate={startDateLimit}
                format="MM-DD-YYYY h:mm A"
              />
            </Form.Item>
          </div>
        </div>
        <div className="row d-flex mb-20">
          <div className="col-lg-4 col-xl-3  pr-xl-4 guide-column  flex-stretch">
            <h5 className="rounded">Pre LIVE</h5>
            <p>Upload show banner and let your audience identify your brand.</p>
          </div>
          <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling ">
            <h5>Banner</h5>
            <p className="color-light">1280px x 720px (jpg or png format)</p>
            {!sampleBanner && (
              <>
                {banner && typeof banner !== "object" && !previewImage && (
                  <Form.Item className="preview-image-main" name="banner">
                    <Image
                      // width={100}
                      // src="https://static.konnect.bio/eventdefaultbanner/banner.jpg"
                      // src="https://static.konnect.bio/900000005/event/dddc020e-215a-410c-9fda-7d3ab8d61b54.png"
                      src={banner}
                    />
                  </Form.Item>
                )}
                {typeof banner === "object" && previewImage && (
                  <Form.Item className="preview-image-main" name="banner">
                    <Image
                      // width={100}
                      // src="https://static.konnect.bio/eventdefaultbanner/banner.jpg"
                      // src="https://static.konnect.bio/900000005/event/dddc020e-215a-410c-9fda-7d3ab8d61b54.png"
                      src={previewImage}
                    />
                  </Form.Item>
                )}
                {banner == previewImage && banner && previewImage && (
                  <Form.Item className="preview-image-main" name="banner">
                    <Image
                      // width={100}
                      // src="https://static.konnect.bio/eventdefaultbanner/banner.jpg"
                      // src="https://static.konnect.bio/900000005/event/dddc020e-215a-410c-9fda-7d3ab8d61b54.png"
                      src={previewImage}
                    />
                  </Form.Item>
                )}
                <Form.Item>
                  <Form.Item
                    name="banner"
                    valuePropName={formState !== "edit" && "fileList"}
                    //initialValue={formState === "add" ? null : form.getFieldValue().banner}
                    //isImageUrl={formState === "add" ? null : "https://cdn.pixabay.com/photo/2017/02/09/21/08/wings-2053515__340.png"}
                    getValueFromEvent={normFile}
                    noStyle
                    multiple={false}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please upload banner image",
                  //   },
                  // ]}
                  >
                    <Upload.Dragger
                      customRequest={dummyRequest}
                      className={`upload-image-main ${banner?.length !== 0 &&
                        banner !== undefined &&
                        previewImage &&
                        "d-none"
                        }`}
                      {...uploadProps}
                      name="banner"
                      // defaultFileList={
                      //   formState === "edit" &&
                      //   form.getFieldValue()?.banner === null
                      //     ? null
                      //     : [
                      //         {
                      //           url: form.getFieldValue()?.banner,
                      //         },
                      //       ]
                      // }
                      defaultFileList={
                        formState === "edit"
                          ? sampleBanner ||
                            form.getFieldValue()?.banner === null
                            ? form.getFieldValue()?.banner
                            : form.getFieldValue()?.banner?.length === 1
                              ? form.getFieldValue()?.banner
                              : [
                                {
                                  url: form.getFieldValue()?.banner,
                                },
                              ]
                          : null
                      }
                    // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    >
                      {/* <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p> */}
                      <p className="ant-upload-text">
                        drag files here to upload
                      </p>
                      {/* <p className="ant-upload-hint">
                      Support for a single or bulk upload.
                    </p> */}
                    </Upload.Dragger>
                  </Form.Item>
                </Form.Item>
              </>
            )}
            {sampleBanner && (
              <Form.Item className="preview-image-main" name="banner">
                <Image
                  // width={100}
                  // src="https://static.konnect.bio/eventdefaultbanner/banner.jpg"
                  // src="https://static.konnect.bio/900000005/event/dddc020e-215a-410c-9fda-7d3ab8d61b54.png"
                  src={previewImage}
                />
              </Form.Item>
            )}
            <Checkbox checked={sampleBanner} onChange={changeSampleBanner}>
              Use a sample image
            </Checkbox>
          </div>
        </div>

        <div className="row d-flex mb-30">
          <div className="col-lg-4 col-xl-3  pr-xl-4 guide-column  flex-stretch">
            <h5 className="rounded">Upload Video</h5>
            {/* <p>
              MP4 Files Are Allowed
              <br />
              The maximum file size allowed is 500MB
            </p> */}
          </div>
          {formState === "add" ? (
            <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling ">
              <Form.Item>
                <Form.Item
                  name="upload"
                  valuePropName={"fileList"}
                  getValueFromEvent={normFile}
                  rules={[
                    {
                      required: true,
                      message: "Please Upload Video File",
                    },
                  ]}
                >
                  <Upload {...videoProps} maxCount={1}>
                    <Button icon={<UploadOutlined />}>Select File</Button>
                  </Upload>
                </Form.Item>
              </Form.Item>
            </div>
          ) : (
            <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling ">
              {myPlayer}
            </div>
          )}
        </div>

        {/* <Form.Item>
        <div className="d-flex justify-content-between align-items-center">
          <Link to="#" className="prev-next-link ff-nunito"></Link>

          <div className="d-flex">
            <Button
              // disabled
              className="default-btn outline d-flex align-items-center justify-content-center span-inner-initial"
              type="primary"
              size="large"
              onClick={handleButtonClick}
            >
              Exit
            </Button>
            <Button
              // disabled
              className="default-btn d-flex align-items-center justify-content-center"
              type="primary"
              size="large"
              icon={
                <FontAwesomeIcon
                  size="sm"
                  className="event-icon"
                  icon={faCaretRight}
                />
              }
              onClick={() => {
                gotoStep(current + 1);
              }}
            >
              Next
            </Button>
          </div>
        </div>
      </Form.Item> */}
        <Form.Item>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              {/* <button
              onClick={() => gotoStep(current - 1)}
              className="border-0 bg-transparent prev-next-link ff-nunito"
            >
              <FontAwesomeIcon icon={faAngleLeft} />
              <span> Previous</span>
            </button> */}
            </div>

            <Modal
              maskClosable={false}
              className="modal-generic modal-500"
              centered
              visible={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              closeIcon={<FontAwesomeIcon icon={faXmark} />}
              footer={[
                // <Button key="back" onClick={handleCancel}>
                //   Cancel
                // </Button>,
                <Button
                  key="submit"
                  type="primary"
                  className="d-table m-auto"
                  // onClick={handleOk}
                  onClick={() =>
                    history(published ? "/shows?type=Published" : "/shows")
                  }
                >
                  Go to Shows
                </Button>,
              ]}
            >
              <h2 className="modal-hd1">Your event is ready!</h2>
              <div className="preview-content-main-modal">
                <h3 className="preview-title">
                  {eventTitle === "" ? "[Preview Event Title]" : eventTitle}
                </h3>
                <div className="preview-date">
                  <span className="text-uppercase">
                    {" "}
                    {dateTime === "" ? "Today" : dateToFromNowDaily(dateTime)}
                  </span>
                  <br></br>
                  {dateTime === ""
                    ? "11:40 AM"
                    : moment(dateTime).format("hh:mm A")}
                </div>
                <div className="preview-txt p-2">
                  The live event should start soon !
                </div>
              </div>
            </Modal>

            <div className="d-flex ">
              <div className="d-flex">
                <Button
                  className="default-btn outline d-flex align-items-center justify-content-center span-inner-initial"
                  type="primary"
                  size="large"
                  onClick={handleButtonClick}
                >
                  Exit
                </Button>

                <Button
                  className="default-btn d-flex align-items-center justify-content-center  res-btn2"
                  type="primary"
                  size="large"
                  loading={loading}
                  icon={
                    <FontAwesomeIcon
                      size="sm"
                      className="event-icon"
                      icon={faCaretRight}
                    />
                  }
                  //disabled={true}
                  onClick={() => {
                    // if (sku.length === 0) {
                    //   setSkuError(true);
                    // } else {
                    submit();

                    // }
                  }}
                >
                  {formState == "add" ? <>Create Show</> : <>Update Show</>}
                </Button>
              </div>
              {/* </Spin> */}
            </div>
          </div>
        </Form.Item>
      </>
    ),
  ];

  const formListProduct = [
    load ? (
      <SkeletonComponent />
    ) : (
      <>
        {/* <div className="row">
          <div className="col-md-6">
            <label className="pb-0">Promo Code</label>
            <Select
              size="small"
              filterOption={(input, options) =>
                options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              value={
                productPromoCodePromo
                  ? productPromoCodePromo
                  : "Select PromoCode"
              }
              //disabled={!(formState === "add" || formState === "edit")}
              placeholder="Select PromoCode"
              //loading={this.state.promoCond}
              optionFilterProp="children"
              className="w-100 select-modal mb-20"
              // onSearch={onSearch}
              onChange={(options, e) => {
                changePromoCode(e, options);
                setFlag(true);
              }}
              showSearch
              allowClear={false}
              loading={promoRequest?.loading ? true : false}
              disabled={promoRequest?.loading ? true : false}
            >
              {promoRequest?.message?.map((customer, key) => {
                return (
                  <Option key={customer.promo_percent + " " + key}>
                    {customer.promo}
                  </Option>
                );
              })}
            </Select>
          </div>
          <div className="col-md-6">
            <label>Discount</label>
            <div className="promo_discount form-control mb-20">
              {productPromoCodeDscs}
            </div>
          </div> */}

        {/* <div className="col-md-6 col-lg-3">
          <Form.Item
              labelCol={{ span: 24 }}
              label="Event Promo Code"
              initialValue={formState === "add" ? null : form.getFieldValue().promo}
              name="promocode"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please Enter Event Promo Code",
              //   },
              // ]}
            >
              <Input placeholder="Enter Event Promo Code" size="large"  onChange={(e) => setPromo(e.target.value)} />
            </Form.Item>

          </div>
          <div className="col-md-6 col-lg-3">
            <Form.Item labelCol={{ span: 24 }} 
            label="Discount" 
            className="mb-40" 
            initialValue={formState === "add" ? null : form.getFieldValue().discount} name="discount">
            
              <Input
                // onChange={onChange}
                type="number"
                size="large"
                className="dark-field"
                placeholder="0%"
                onChange={(e) => {
                  setDiscount(e.target.value);
                }}
              />
            </Form.Item>
          </div> */}
        {/* <div className="col-md-3">
            <label>Cashback</label>
            <Form.Item
              labelCol={{ span: 24 }}
              // label="Influencer Fee"
              className="mb-20 label-padb-0"
              // initialValue={
              //   formState === "add"
              //     ? null
              //     : form.getFieldValue().influencer_percent
              // }
              disabled={true}
              initialValue={
                getStructureFees?.payload?.message?.fee_structure?.shopper_cashback ? getStructureFees?.payload?.message?.fee_structure?.shopper_cashback : 0
              }
              name="influencer_percent"
            >
              <InputNumber
                disabled={true}
                className="w-100 select-modal"
                defaultValue={0}
                size="large"
                min={0}
                max={100}
                formatter={(value) => `${value}%`}
                parser={(value) => value.replace("%", "")}
                onChange={(e) => {
                  {
                    setInfluencer_percent(e);
                    setFlag(true);
                  }
                }}
              />
            </Form.Item>
          </div>
          <div className="col-md-3">
            <label>Referral Fee</label>
            <Form.Item
              labelCol={{ span: 24 }}
              // label="Referral Fee"
              className="mb-20 label-padb-0 w-100"
              disabled={true}
              // initialValue={
              //   formState === "add" ? null : form.getFieldValue().referral_percent
              // }
              initialValue={
                getStructureFees?.payload?.message?.fee_structure?.referral_fee ? getStructureFees?.payload?.message?.fee_structure?.referral_fee : 0
              }
              name="referral_percent"
            >
              <InputNumber
                disabled={true}
                className="w-100 select-modal"
                defaultValue={0}
                size="large"
                min={0}
                max={100}
                formatter={(value) => `${value}%`}
                parser={(value) => value.replace("%", "")}
                onChange={(e) => {
                  {
                    setReferralPercent(e);
                    setFlag(true);
                  }
                }}
              />
            </Form.Item>
          </div> */}
        {/* </div> */}
        <div className="row d-flex mb-30">
          {/* <div className="col-12 col-xl-3  pr-xl-4 guide-column  flex-stretch">
            <h5 className="rounded">Add product SKU</h5>
            <p>
              Create product list to live event by using SKU numbers as added on
              the ecommerce platform.
            </p>
          </div> */}
          <div className="col-12 col-xl-12 flex-stretch form-styling">
            <Form.Item>
              <Form.Item
                name="productsku"
                label="Product SKU"
                labelCol={{ span: 24 }}
                className="d-flex flex-column sku-field pos-index mb-0"
              >
                <AsyncProduct
                  getSku={getSku}
                  sku={sku}
                  referralPercent={referralPercent}
                  influencer_percent={influencer_percent}
                />
                {skuError && (
                  <div role="alert" className="ant-form-item-explain-error">
                    Please Select Product SKU
                  </div>
                )}
              </Form.Item>
            </Form.Item>

            <div className="row d-flex mb-20 product-listing-table-main">
              <div className="col">
                {sku?.length > 0 && (
                  <Table
                    pagination={false}
                    columns={columns}
                    size="small"
                    className="product-listing-table"
                    dataSource={[...sku]}
                    scroll={sku.length > 4 ? { y: 350, x: 1000 } : { x: 1000 }}
                  // scroll={
                  //   sku.length > 4
                  //     ? { y: 270, x: window.innerWidth <= 1440 ? 900 : 0 }
                  //     : false
                  // }
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <Form.Item>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              {/* <button
                onClick={() => gotoStep(current - 1)}
                className="border-0 bg-transparent prev-next-link ff-nunito"
              >
                <FontAwesomeIcon icon={faAngleLeft} />
                <span> Previous</span>
              </button> */}
            </div>

            <Modal
              maskClosable={false}
              className="modal-generic modal-500"
              centered
              visible={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              closeIcon={<FontAwesomeIcon icon={faXmark} />}
              footer={[
                // <Button key="back" onClick={handleCancel}>
                //   Cancel
                // </Button>,
                <Button
                  key="submit"
                  type="primary"
                  className="d-table m-auto"
                  // onClick={handleOk}
                  onClick={() =>
                    history(published ? "/shows?type=Published" : "/shows")
                  }
                >
                  Go to Shows
                </Button>,
              ]}
            >
              <h2 className="modal-hd1">Your event is ready!</h2>
              <div className="preview-content-main-modal">
                <h3 className="preview-title">
                  {eventTitle === "" ? "[Preview Event Title]" : eventTitle}
                </h3>
                <div className="preview-date">
                  <span className="text-uppercase">
                    {" "}
                    {dateTime === "" ? "Today" : dateToFromNowDaily(dateTime)}
                  </span>
                  <br></br>
                  {dateTime === ""
                    ? "11:40 AM"
                    : moment(dateTime).format("hh:mm A")}
                </div>
                <div className="preview-txt p-2">
                  The live event should start soon !
                </div>
              </div>
            </Modal>

            <div className="d-flex ">
              <div className="d-flex">
                <Button
                  className="default-btn outline d-flex align-items-center justify-content-center span-inner-initial"
                  type="primary"
                  size="large"
                  onClick={handleButtonClick}
                >
                  Exit
                </Button>

                <Button
                  className="default-btn d-flex align-items-center justify-content-center  res-btn2"
                  type="primary"
                  size="large"
                  loading={loading}
                  icon={
                    <FontAwesomeIcon
                      size="sm"
                      className="event-icon"
                      icon={faCaretRight}
                    />
                  }
                  //disabled={true}
                  onClick={() => {
                    if (sku.length === 0) {
                      setSkuError(true);
                    } else {
                      submit();
                    }
                  }}
                >
                  {formState == "add" ? <>Create Show</> : <>Update Show</>}
                </Button>
              </div>
              {/* </Spin> */}
            </div>
          </div>
        </Form.Item>
      </>
    ),
  ];

  if (genLoad) {
    return (
      <>
        <span className="spinclass loader-center position-relative">
          <Spin size="large" />
        </span>
      </>
    );
  }
  const handlePage = (link, menu) => {
    history(link, {
      state: { key: link.split("/").length > 2 ? link.split("/")[2] : link.split("/")[1], openKey: menu }
    }
    );
  }
  return (
    <>
      <div className="create-event-top-area mb-4 d-flex justify-content-center">
        <div className="top-area-box ">
          <div className="title-area mb-0 pb-0">
            <h1 className="mb-0 pb-0">
              {formState == "add" ? (
                <>Create Show</>
              ) : (
                <>
                  Update{" "}
                  {searchParams.get("page") === "product" ? "Products" : "Show"}
                </>
              )}
            </h1>
          </div>
        </div>
        <div className="top-area-box">&nbsp;</div>
      </div>

      {!savedGeneralCategories?.message?.length ? (
        <div className="container-fluid">
          <div class="coming_iner">
            <h4 className="mb-4">Setup Category To Create Show</h4>
            <div className="buttons-area">
              <Button
                onClick={() => handlePage("/account/categories", "settings")}
                className="default-btn medium-btn fltr-hpr fw-normal"
              >
                Setup Category
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <>
          {
            getInventoryProductsBySkuAndTitle?.message?.length === 0 ?
              <div className="container-fluid">
                <div class="coming_iner">
                  <h4 className="mb-4">Activate Inventory To Create Show</h4>
                  <div className="buttons-area">
                    <Button
                      className="default-btn medium-btn fltr-hpr fw-normal"
                      onClick={() => handlePage("/inventoryy/active", "inventory")}
                    >
                      Manage Inventory
                    </Button>
                  </div>
                </div>
              </div>
              :
              !validate?.payload?.message?.salesforce && !validate?.payload?.message?.shopify && !validate?.payload?.message?.magento && !validate?.payload?.message?.scoc ?
                <div className="container-fluid">
                  <div class="coming_iner">
                    <h4 className="mb-4">Setup Ecommerce To Create Show</h4>
                    <div className="buttons-area">
                      <Button
                        className="default-btn medium-btn fltr-hpr fw-normal"
                        onClick={() => handlePage("/account/ecommerce", "settings")}
                      >
                        Ecommerce Setup
                      </Button>

                    </div>
                  </div>
                </div>
                :
                <div className="create-event-wrapper  mb-30">
                  <div
                    className={
                      current != 2
                        ? "create-event-box-left"
                        : "create-event-box-left full-width"
                    }
                  >
                    {searchParams.get("page") === null && (
                      <Steps
                        {...stepsProps}
                        className="event-stepss"
                        responsive={false}
                      >
                        <Step
                          title={
                            <>
                              <img className="step-img" src={step1} alt="step1" />
                              <div className="setup-hd">Setup</div>
                            </>
                          }
                        />
                        {/* <Step
              title={
                <>
                  <img className="step-img" src={step2} alt="step2" />
                  <div className="setup-hd">Design</div>
                </>
              }
            /> */}
                        <Step
                          title={
                            <>
                              <img className="step-img" src={step3} alt="step3" />
                              <div className="setup-hd">Products</div>
                            </>
                          }
                        />
                      </Steps>
                    )}

                    {(searchParams.get("page") === null ||
                      searchParams.get("page") === "null") && (
                        <Form {...formProps}>{formList[current]}</Form>
                      )}

                    {searchParams.get("page") === "edit" && (
                      <Form {...formProps}>{formListEdit[current]}</Form>
                    )}

                    {searchParams.get("page") === "product" && (
                      <Form {...formProps}>{formListProduct[current]}</Form>
                    )}
                  </div>
                </div>
          }
        </>
      )}

      <Modal
        className="modal-generic modal-500 form-styling"
        centered
        visible={isCheckedOpen}
        onOk={OpenSubmit}
        onCancel={handleCancelInfo}
        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        maskClosable={false}
        keyboard={false}
        footer={[
          // <Button key="back" onClick={handleCancel}>
          //   Cancel
          // </Button>,
          <Button
            key="submit"
            type="primary"
            // className="d-table m-auto"
            onClick={handleOkInfo}
          >
            Save
          </Button>,
          // <Button key="back" onClick={handleCancelInfo}>
          //   Exit
          // </Button>,
        ]}
      >
        <h2 className="modal-hd1">Add Info</h2>
        {/* <p>Please note these email addresses must be registered on ormelive.com as a Shopper.
            By submitting this, the role will have an access to control room chat.
          </p> */}
        <h5 className="rounded">Title</h5>
        <Input
          placeholder="Enter Title"
          className="mb-20"
          value={infoTitle}
          label="Title"
          size="large"
          onChange={onChangeTitle}
        />
        <h5 className="rounded">Description</h5>
        <TextArea
          value={infoDesc}
          label="Description"
          size="large"
          placeholder="Description....."
          onChange={onChangeDescription}
        />
      </Modal>

      <Modal
        maskClosable={false}
        className="modal-generic form-new modal-800 form-styling"
        centered
        // visible={false}
        // visible={true}
        visible={isModalOpen}
        onOk={handleOk}
        //onCancel={handleCancel}

        closeIcon={<FontAwesomeIcon icon={""} />}
        footer={[
          // <Button key="back" onClick={handleCancel}>
          //   Cancel
          // </Button>,
          <Button
            key="submit"
            type="primary"
            className="d-table m-auto"
            // onClick={handleOk}
            onClick={() =>
              history(published ? "/shows?type=Published" : "/shows")
            }
          >
            Go to Shows
          </Button>,
        ]}
      >
        {formState == "edit" ? (
          <h2 className="modal-hd1">Show has been updated</h2>
        ) : (
          <h2 className="modal-hd1">Show has been created</h2>
        )}
        <div className="row">
          <div className="col-lg-4">
            <h5 className="border-d">Banner</h5>
            {previewImage && (
              <div
                className={`preview-img ${sampleBanner ? "sample-image" : ""}`}
              >
                <img src={previewImage} alt="preview Image" />
              </div>
            )}
            {!previewImage && (
              <div
                className={`preview-img ${sampleBanner ? "sample-image" : ""}`}
              >
                <img
                  src={
                    banner?.size ? URL.createObjectURL(banner) : previewImage
                  }
                  alt="preview Image"
                />
              </div>
            )}
          </div>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-md-6 mb-20">
                <div className="preview-date-main">
                  <h4 className="date-txt">Event</h4>
                  <h5 className="preview-date">{eventTitle}</h5>
                  <div className="event-detail-txt">
                    <CalendarFilled /> LIVE-SHOPPING-EVENT
                  </div>
                </div>
              </div>
              <div className="col-md-6  mb-20">
                <div class="preview-date-main">
                  <h4 class="date-txt">Date</h4>
                  <h5 class="preview-date">
                    {" "}
                    {moment(dateTime).utc().format("YYYY-MM-DD")}
                  </h5>
                  <h6 class="preview-time">
                    At {moment(dateTime).format("hh:mm:ss A")}
                  </h6>
                </div>
              </div>
            </div>

            <div className="row flex-stretch">
              {/* <div className="col-md-6 col-xl-6  mb-20">
                <div className="preview-date-main">
                  <h4 className="date-txt">Category</h4>
                  <h5 className="preview-date">{parent}</h5>
                </div>
              </div> */}
              <div className="col-md-6 col-xl-6  mb-20">
                <div className="preview-date-main producer-style">
                  <h4 className="date-txt">Attendee</h4>

                  <h5 className="preview-date">
                    <FontAwesomeIcon className="" icon={faUserTie} /> Producer:{" "}
                    <span className="fw-normal">
                      {validate?.payload?.message?.name}
                    </span>
                  </h5>
                  {host?.map((item, i) => {
                    return (
                      <>
                        <h5 className="preview-date">
                          <FontAwesomeIcon className="" icon={faUser} />{" "}
                          Primary:{" "}
                          <span className="fw-normal">{item?.label}</span>
                        </h5>
                      </>
                    );
                  })}
                  {host2?.map((item, i) => {
                    return (
                      <>
                        <h5 className="preview-date">
                          <FontAwesomeIcon className="" icon={faUser} /> Host1:{" "}
                          <span className="fw-normal">{item?.label}</span>
                        </h5>
                      </>
                    );
                  })}
                  {host3?.map((item, i) => {
                    return (
                      <>
                        <h5 className="preview-date">
                          <FontAwesomeIcon className="" icon={faUser} /> Host2:{" "}
                          <span className="fw-normal">{item?.label}</span>
                        </h5>
                      </>
                    );
                  })}
                  {host4?.map((item, i) => {
                    return (
                      <>
                        <h5 className="preview-date">
                          <FontAwesomeIcon className="" icon={faUser} /> Host3:{" "}
                          <span className="fw-normal">{item?.label}</span>
                        </h5>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>


          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="row d-flex mb-20">
              <div className="col-12">
                <h5 className="border-hd">Product List</h5>
              </div>
              <div className="col">
                <Table
                  pagination={false}
                  columns={columns}
                  size="small"
                  className="product-listing-table"
                  //  rowSelection={{ ...rowSelection }}
                  dataSource={sku}
                  scroll={sku?.length > 2 ? { y: 250, x: 1000 } : { x: 1000 }}
                />
              </div>
            </div>
          </div>
        </div>

        {/* <div className="preview-content-main-modal">
          <h3 className="preview-title">

            {eventTitle === ""
              ? "[Preview Event Title]"
              : eventTitle}
          </h3>
          <div className="preview-date">
            <span className="text-uppercase">
              {" "}
              {dateTime === ""
                ? "Today"
                : dateToFromNowDaily(dateTime)}
            </span>
            <br></br>
            {dateTime === ""
              ? "11:40 AM"
              : moment(dateTime).format("hh:mm A")}
          </div>
          <div className="preview-txt p-2">
            The live event should start soon !
          </div>


        </div> */}
      </Modal>

      <Modal
        zIndex={999999}
        maskClosable={false}
        className="modal-generic modal-600"
        centered
        visible={isModalOpenDetail}
        onOk={handleOkDetail}
        onCancel={handleCancelDetail}
        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        // footer={false}
        footer={[
          <Button
            key="back"
            onClick={handleCancelDetail}
            className="d-table m-auto"
          >
            Exit
          </Button>,
          // <Button key="submit" type="primary"
          //   className="d-table m-auto"
          //   // onClick={handleOk}
          //   // onClick={handleCancel}
          // >
          //   Submit
          // </Button>,
        ]}
      >
        <h2 className="modal-hd1">Details</h2>
        <div className="co">
          {/* <Table
            pagination={false}
            columns={columnDetails}
            size="small"
            className="product-listing-tabl inventory-box-main details-table"
            //  rowSelection={{ ...rowSelection }}
            dataSource={dataDetails}
            // scroll={{
            //     x: 500,
            //     y: 500,
            // }}
            scroll={dataDetails?.length > 10 ? { y: 400, x: 500 } : { x: 500 }}
          /> */}
          <div className="table-container inventory-table">
            <table
              class={`table m-0 ${dataDetails?.productOpt?.length &&
                dataDetails?.productOpt?.length + 2 == 5
                ? "width-item-5"
                : ""
                } ${dataDetails?.productOpt?.length &&
                  dataDetails?.productOpt?.length + 2 == 6
                  ? "width-item-6"
                  : ""
                }`}
            >
              <thead>
                <tr>
                  {dataDetails?.productOpt?.map((item, i) => {
                    return (
                      <th className="width-25 border-bottom-0">{item.name}</th>
                    );
                  })}
                  <th className="width-25 border-bottom-0">Stock</th>
                  <th className="width-25 border-bottom-0">Price</th>
                </tr>
              </thead>
            </table>
            <div className="table-body scrollbar-style">
              <table
                class={`table ${dataDetails?.productOpt?.length &&
                  dataDetails?.productOpt?.length + 2 == 5
                  ? "width-item-5"
                  : ""
                  } ${dataDetails?.productOpt?.length &&
                    dataDetails?.productOpt?.length + 2 == 6
                    ? "width-item-6"
                    : ""
                  }`}
              >
                <tbody>
                  {dataDetails?.variants?.map((item, i) => {
                    return (
                      <tr>
                        <td className="width-25">{item.option1}</td>
                        {item.option2 && (
                          <td className="width-25">{item.option2}</td>
                        )}
                        {item.option3 && (
                          <td className="width-25">{item.option3}</td>
                        )}
                        <td className="width-25">{item.inventory_quantity}</td>
                        <td className="width-25">${item.price}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        maskClosable={false}
        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        className="modal-generic modal-600"
        centered
        visible={streamModal}
        onOk={() => {
          setIsModalOpenStream(false);
        }}
        onCancel={() => {
          setIsModalOpenStream(false);
        }}
        footer={[
          // <Button key="back" onClick={handleCancel}>
          //   Cancel
          // </Button>,
          <Button
            key="submit"
            type="primary"
            className="d-table m-auto"
            // onClick={handleOk}
            onClick={() => setIsModalOpenStream(false)}
          >
            Save
          </Button>,
        ]}
      >
        <h2 className="modal-hd1">Stream Source</h2>
        <div className="co">
          <h5 className="rounded">Select External Stream</h5>
          <Input
            placeholder="Enter"
            className="mb-20"
            value={infoTitle}
            label="Title"
            size="large"
            onChange={onChangeTitle}
          />
        </div>
      </Modal>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ReactRouterPrompt
          // BlockerFunction={(currentLocation, nextLocation, historyAction)}
          when={
            ((host.length > 0 ||
              host2.length > 0 ||
              host3.length > 0 ||
              host4.length > 0 ||
              form.getFieldValue().title ||
              form.getFieldValue().notes ||
              form.getFieldValue().datetime ||
              imageThumb) &&
              promptModal &&
              formState === "add") ||
            (formState === "edit" && flag && promptModal && true)
          }
        >
          {({ isActive, onConfirm, onCancel }) =>
            isActive && (
              <Modal
                maskClosable={false}
                className="modal-generic modal-500"
                centered
                visible={isActive}
                onOk={() => {
                  onConfirm();
                }}
                onCancel={onCancel}
                closeIcon={<FontAwesomeIcon icon={faXmark} />}
                footer={[
                  <Button type="primary" onClick={onCancel}>
                    Cancel
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    // onClick={handleOk}
                    onClick={() => {
                      onConfirm();
                    }}
                  >
                    Ok
                  </Button>,
                ]}
              >
                <h2 className="modal-hd1">Warning</h2>
                <div className="my-4">
                  <h5>By leaving this page you will lose all your changes.</h5>
                  <h5>Would you like to continue?</h5>
                </div>
              </Modal>
              // <div className="lightbox">
              //   <div className="container">
              //     <p>Do you really want to leave?</p>
              //     <button onClick={onCancel}>Cancel</button>
              //     <button onClick={onConfirm}>Ok</button>
              //   </div>
              // </div>
            )
          }
        </ReactRouterPrompt>
      </ErrorBoundary>
    </>
  );
}
